import React from 'react';
import PropTypes from 'prop-types'
import Languages from '../../library/dynamics/languages'
import { Select } from 'antd'
const { Option } = Select

function LanguageSelect({value = null, onChange }) {
    return (
        <Select
            optionFilterProp="label"
            onChange={onChange}
            value={value}
            autoComplete="none"
            showSearch>
            {Languages.map((language) => (
                <Option key={language.id} value={language.id} label={language.name}>
                    {language.name}
                </Option>
            ))}
        </Select>
    );
}

LanguageSelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func
}

export default LanguageSelect;