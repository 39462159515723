import { customAlphabet } from 'nanoid/non-secure';
import { useReducer } from "react";
const nanoid = customAlphabet('1234567890abcdef', 6)

const ACTIONS = {
    SET: 'set',
    ADD: 'add',
    REMOVE: 'remove',
    UPDATE: 'update',
}

const reducer = (state, action) => {
    try {
        switch (action.type) {
            case ACTIONS.SET:
                if (typeof action.value === 'undefined') {
                    throw new Error('value require for Set')
                }
                return formatList(action.value)
            case ACTIONS.ADD:
                if (typeof action.value === 'undefined') {
                    throw new Error('value require for add')
                }
                return { ...state, [action.value.id ?? nanoid()]: { ...action.value } }

            case ACTIONS.REMOVE: {
                if (!action.id) {
                    throw new Error('Id is required for remove')
                }
                if (typeof state[action.id] === 'undefined') {
                    throw new Error(`Invalid Id:${action.id} for remove`)
                }
                const tempState = { ...state }
                delete tempState[action.id]
                return { ...tempState }
            }

            case ACTIONS.UPDATE:
                if (!action.id) {
                    throw new Error('Id is required for update')
                }
                if (typeof state[action.id] === 'undefined') {
                    throw new Error(`Invalid Id:${action.id} for update`)
                }
                if (typeof action.value === 'undefined') {
                    throw new Error('value require for update')
                }

                return { ...state, [action.id]: action.value }

            default:
                throw new Error('Invalid action')
        }
    }
    catch (e) {
        console.log({ e, action, state })
        return state
    }
}

const formatList = (rawList) => {
    if (Array.isArray(rawList)) {
        return rawList.reduce((prev, curr) => ({
            ...prev,
            [curr.id ?? nanoid()]: { ...curr }
        }), {})
    }
    else {
        return { ...rawList }
    }
}

function useListReducer(initial) {
    const [list, dispatch] = useReducer(reducer, formatList(initial))

    return [
        list,
        {
            add: (value) => {
                dispatch({ type: ACTIONS.ADD, value })
            },
            remove: (id) => {
                dispatch({ type: ACTIONS.REMOVE, id })
            },
            update: (id, value) => {
                dispatch({ type: ACTIONS.UPDATE, id, value })
            },
            set: (value) => {
                dispatch({ type: ACTIONS.SET, value })
            },
        }
    ]
}

export default useListReducer;
