import React from 'react'
import PropTypes from 'prop-types'
import { Button, Collapse } from 'antd';
import { Page } from '../../components/page';
import { useNavigate, useLocation } from 'react-router-dom';
import { generateRouteUrl } from '../../library/constants/routes.js';

const { Panel } = Collapse

import OrderManagerStudio from '../../components/data/studio/orderMangerStudio';

const SendDirectArtwork = ({ title }) => {

    let imageData = []
    const { state } = useLocation();

    console.log(state);


    const navigate = useNavigate()
    return (

        <Page title={title} header={<Button type="primary" onClick={() => { navigate(generateRouteUrl('/order-manager-list')) }}>Back</Button>} >
            <Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header='Customer Artwork' key="1">

                    <OrderManagerStudio data={state} heading={'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam vitae odio mollitia reiciendis, quibusdam vel incidunt aut ut consequatur velit deserunt dicta non consequuntur possimus sequi aliquid. Ad, nesciunt expedita!'} imageIds={imageData} />

                </Panel>
            </Collapse>




        </Page>
    )

}


SendDirectArtwork.propTypes = {
    title: PropTypes.string,
}
export default SendDirectArtwork