/* eslint-disable no-unused-vars */
import useUrlState from '@ahooksjs/use-url-state';
import { Table as AntdTable } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { useLocation, useSearchParams } from "react-router-dom";

// let lastScrollY = 0
// let ticking = false
let documentHeight = 0
let currentScroll = 0
const modifier = 50;

const sortOrders = {
    ascend: 'ASC',
    descend: 'DESC'
}

const Table = ({ columns, data, hasMore, loadMore, isLoading, onChange, onRowClick, ...otherProps }) => {
    const location = useLocation()
    const [searchParams] = useSearchParams()
    const tableRef = useRef()
    const reachedBottom = useRef(false)
    // const [, setUrl] = useUrlState({
    //     page: undefined,
    //     pageSize: undefined,
    //     sortBy: undefined,
    //     sortOrder: undefined,
    // });
    // const handleTableChange = (pagination, filters, sorter) => {
    //     setUrl((s) => {
    //         s.page = pagination.current > 1 ? pagination.current : undefined
    //         s.pageSize = pagination.pageSize != 20 ? pagination.pageSize : undefined
    //         s.sortBy = sorter.field ?? s.sortBy
    //         s.sortOrder = sorter.order ? (sortOrders[sorter.order] ?? undefined) : undefined
    //         if (!s.sortBy) {
    //             s.sortOrder = undefined
    //         }
    //         if (!s.sortOrder) {
    //             s.sortBy = undefined
    //         }
    //         return s
    //     })
    // }

    const handleScroll = () => {
        if (loadMore) {
            //lastScrollY = window.scrollY;
            currentScroll = window.scrollY + window.innerHeight;
            documentHeight = document.body.scrollHeight

            if (!reachedBottom.current && currentScroll + modifier > documentHeight) {
                //Prevent multiple page loadings at once
                reachedBottom.current = true
                loadMore()
                console.log('---reached-bottom-------');
                setTimeout(() => {
                    console.log('---reached-bottom-timeout200-------');
                    reachedBottom.current = false
                }, 100)
            }
        }

        // if (!ticking) {
        //     window.requestAnimationFrame(() => {
        //         // When the user is [modifier]px from the bottom, fire the event.
        //         tableRef.current.style.top = `${lastScrollY}px`;
        //         ticking = false;
        //         // console.log(lastScrollY)
        //     });

        //     ticking = true;
        // }
    };

    // useEffect(() => {
    //     const { page = 1, pageSize = 20, sortBy = null, sortOrder = null } = Object.fromEntries(searchParams.entries())
    //     onChange({ page: Number(page), pageSize: Number(pageSize), sortBy, sortOrder })
    // }, [location])

    useEffect(() => {
        if (hasMore) {
            window.addEventListener('scroll', handleScroll)
            return () => {
                window.removeEventListener('scroll', handleScroll)
            }
        }
        else {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [hasMore])

    return (
        <AntdTable
            columns={columns}
            dataSource={data}
            ref={tableRef}
            rowKey="id"
            pagination={false}
            onChange={(...[, , { field, order }]) => {
                onChange({
                    sortBy: field,
                    sortType: sortOrders[order] ?? undefined,
                })
            }}
            loading={isLoading}
            onRow={(record, rowIndex) => ({
                onClick: event => {
                    event.preventDefault()
                    onRowClick(rowIndex, record)
                }
            })}
            sticky
            {...otherProps}
        />
    )
}

Table.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    loadMore: PropTypes.func.isRequired,
    hasMore: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isLoading: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    onRowClick: PropTypes.func,
    scroll: PropTypes.object
}

export default Table