import React from 'react';
import PropTypes from 'prop-types'
import Countries from '../../library/dynamics/countries'
import { Server } from '../../library/constants/server.js'
import { Select } from 'antd'
const { Option } = Select
import styles from './CountrySelect.module.scss'

function CountrySelect({ value = null, onChange }) {
    return (
        <Select
            optionFilterProp="name"
            autoComplete="none"
            onChange={(val) => onChange(val, Countries.find(({ id }) => id === val))}
            value={value}
            showSearch>
            {Countries.map((country) => (
                <Option key={country.id} name={country.name} value={country.id} label={country.name}>
                    <div className={styles.option}>
                        <span role="img" aria-label={country.name}>
                            <img src={`${Server.BaseUrl}/${country.flag_url}`} alt={country.name} title={country.name} />
                        </span>
                        {country.name}
                    </div>
                </Option>
            ))}
        </Select>
    );
}

CountrySelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func
}

export default CountrySelect;