// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { pick } from 'lodash-es';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/contact';


// Define all api functions here
export const getAlContacts = (payload) => {
    return axiosInstance.get(ENDPOINT, { params: payload })
}

export const createContact = (payload) => {
    payload = pick(payload, ["first_name", "last_name", "email", "customer_id", "language_id", "phone_country_code", "mobile_country_code", "phone_number", "mobile_number", "gender_id", "country_id", "role_id"])
    return axiosInstance.post(ENDPOINT, payload)
}