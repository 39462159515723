import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Select, Form, Input } from 'antd'
import { Countries } from '../../library/constants'
import styles from './PhoneInput.module.scss'
import './PhoneInput.scss'

function PhoneInput({ valuePropName = { code: 'code', number: 'number' }, required, /* onChange */ }) {
    const form = Form.useFormInstance();
    const [number, setNumber] = useState(form.getFieldValue(valuePropName.number));
    const country_code = Form.useWatch(valuePropName.code, form);

    const onNumberChange = (event) => {
        setNumber(event.target.value)
    };

    // useEffect(() => {
    //     // const phone = {}
    //     // phone[valuePropName.code] = code
    //     // phone[valuePropName.number] = number
    //     // onChange?.(phone)
    // }, [code, number])

    return (
        <span className={`${styles.select} phone-input`}>
            <Input.Group compact>
                <Form.Item className={styles.countryCode} name={valuePropName.code} rules={required ? [{ required: true }] : false} shouldUpdate>
                    <Select
                        value={country_code}
                        optionLabelProp="title"
                        dropdownMatchSelectWidth={false}
                        autoComplete="none"
                        filterOption={(input, option) =>
                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                        }
                        options={Countries.map(({ dial_code, code, name, emoji }) => ({
                            label: [emoji, name, '+' + dial_code].join(' '),
                            value: dial_code,
                            key: dial_code + code,
                            title: emoji,
                        }))}
                        showSearch
                        clearIcon={false}
                        showArrow={false}
                    />
                </Form.Item>
                <Form.Item name={valuePropName.number} rules={required ? [{ required: true }] : false} className={styles.number} shouldUpdate>
                    <Input value={number} prefix={country_code==null ? '' : "+ "+ (country_code ?? '') ?? ''} onChange={onNumberChange} />
                </Form.Item>
            </Input.Group>
        </span>
    );
}

PhoneInput.propTypes = {
    valuePropName: PropTypes.exact({
        code: PropTypes.string,
        number: PropTypes.string,
    }),
    required: PropTypes.bool,
    //onChange: PropTypes.func
}

export default PhoneInput;