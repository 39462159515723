import { Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ValidationForm as Form } from '../';
import styles from './custom-specification.module.scss';

const CustomSpecificationForm = ({ form, children, ...otherProps }) => {
    const validationRules = {
        name_default: [{
            required: true
        }],
        name: [{
            required: true
        }],
        value_default: [{
            required: true
        }],
        value: [{
            required: true
        }]
    }

    return (
        <Form name='add-custom-specification' layout="vertical" form={form} className={styles.Form} validationRules={validationRules} {...otherProps} preserve={false}>
            <Form.Item name="name_default" label="Specification">
                <Input />
            </Form.Item>
            <Form.Item name="name" label="Specification Name Translation">
                <Input />
            </Form.Item>
            <Form.Item name="value_default" label="Specification Value">
                <Input />
            </Form.Item>
            <Form.Item name="value" label="Specification Value Translation">
                <Input />
            </Form.Item>
            {children}
        </Form>
    )
}

CustomSpecificationForm.propTypes = {
    form: PropTypes.object.isRequired,
    children: PropTypes.node,
}

export default CustomSpecificationForm