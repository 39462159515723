import PropTypes from 'prop-types'
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import React from "react";
import { CommentForClient, CommentForVendor } from '../index';



const ArtWorkRejected = ({ data }) => {
    return <div>
    <div className={styles.stepBox}>
        <h3 style={{ 'text-align': 'start' }}>Task For You</h3>
        <div className={styles.message}>
            <ul>
                <li> Artwork has been rejected by Leopriting..
                    {/* <span style={{ "float": 'right' }}><Button onClick={handleRequestTemplate}>Upload Artwork</Button></span> */}
                </li>

            </ul>
        </div>
    </div>
    <br />
    <br />

    <CommentForClient comment={data.client_comment} />
    <CommentForVendor comment={data.vendor_comment} />


</div>
}

ArtWorkRejected.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ArtWorkRejected ;