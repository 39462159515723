import React from 'react';
import styles from '../../../components/data/OrdeManager/order-item.module.scss';
import { Collapse } from 'antd'
const { Panel } = Collapse;
import PropTypes from 'prop-types';

const ActivityLogs = ({logs})=>{
    return <Collapse collapsible="icon"  expandIconPosition="end">
    <Panel header='Activity Logs' key="1">
        <div className={styles.activityLogs}>
            <ul>
                {logs.map(({ message, createdAt }) => (
                    <div key={createdAt}>
                        <li className={styles.logsList}>
                            <span style={{ 'font-weight': '600' }}>{message} <span style={{ 'float': 'right', 'font-weight': '400' }}>{createdAt}</span></span>
                        </li>
                        <br />
                    </div>

                ))}

            </ul>

        </div>


    </Panel>
</Collapse>
}

ActivityLogs.propTypes = {
    logs: PropTypes.arrayOf(PropTypes.object).isRequired

}
export default ActivityLogs