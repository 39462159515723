import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from "react";
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { ActivityLogs, CommentForClient, CommentForVendor } from '../index';
// import { PopUpMessage } from '../../../components/message';
import { generatePath, useNavigate } from 'react-router-dom';

const TemplateRecievedFromVendor = ({ data, logs }) => {
    const navigate = useNavigate()


    const handleRequestTemplate = async () => {
        navigate(
            generatePath('/upload-sample-template/:id', { id: data.id }),

            { state: data }

        )

    }


    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'textAlign': 'start' }}>Task For You</h3>

            {data.vendor_status == 3 ?
                <div className={styles.message}>
                    <ul>
                        <li>The customer has requested for product template. Please share it
                            <span style={{ "float": 'right' }}><Button onClick={handleRequestTemplate}>Send Template to Customer</Button></span>
                        </li>

                    </ul>
                </div> : <div></div>
            }
            <br />
            <br />





        </div>

        {/* <PopUpMessage isOpen={open} heading= {'Order Sent to Vendor'} onContinue={handleOk} data= {message}/> */}
        <br />
        <br />

        <CommentForClient comment={'testing'} />
        <CommentForVendor comment={'testing'} />
        <ActivityLogs logs={logs} />


    </div>
}

TemplateRecievedFromVendor.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    logs: PropTypes.arrayOf(PropTypes.object).isRequired
    //  changeState: PropTypes.func
}
export default TemplateRecievedFromVendor;