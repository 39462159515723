import { InputNumber } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import styles from './percent.module.scss';

const Percent = ({ value = null, onChange, ...otherProps }) => {
    return (
        <InputNumber
            value={value}
            controls={false}
            onChange={onChange}
            step="0.01"
            precision="2"
            id="percent-field"
            className={styles.wrapper}
            {...otherProps}
        />
    )
}

Percent.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
}

export default Percent;