import PropTypes from 'prop-types';
import React from 'react';
import { useFetcher } from 'react-router-dom';
import { DropdownSelect } from '.';

export const ContactRoleSelect = ({ contact, primaryContactId }) => {
	const fetcher = useFetcher();

	const getRole = () => {
		if (primaryContactId === contact.id) {
			return "primary";
		}
		if (contact.is_authority) {
			return "authority";
		}
		return null;
	};

	const getActions = () => {
		switch (getRole()) {
			case 'primary':
				return [];

			case 'authority':
				return [
					"Primary",
					"Remove Authority"
				];

			default:
				return [
					"Primary",
					"Authority"
				];
		}
	};

	const getValue = () => {
		switch (getRole()) {
			case 'primary':
				return 'Primary';

			case 'authority':
				return 'Authority';

			default:
				return 'Set role';
		}
	};

	const onDropdownChange = (val) => {
		switch (val) {
			case 'Primary':
				fetcher.submit({
					action: 'primaryContact',
					contactId: contact.id
				}, {
					method: 'post',
				});
				break;

			case 'Authority':
				fetcher.submit({
					action: 'authorityContact',
					contactId: contact.id
				}, {
					method: 'post',
				});
				break;

			case "Remove Authority":
				fetcher.submit({
					action: 'authorityContact',
					contactId: contact.id
				}, {
					method: 'delete',
				});
				break;

			default:
				break;
		}
	};

	return (
		<DropdownSelect
			options={getActions()}
			value={getValue()}
			onChange={onDropdownChange} />
	);
};
ContactRoleSelect.propTypes = {
	contact: PropTypes.object.isRequired,
	primaryContactId: PropTypes.number.isRequired,
};

export default ContactRoleSelect