import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Input, Row, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchProofDetails, sendProoftoClient } from '../../api/order';
import style from '../../components/general.module.scss';
import { Page } from '../../components/page';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { Server } from "../../library/constants/server";
import { Image } from '../../ui';
const { Panel } = Collapse



const { TextArea } = Input
const SendReadyProof = ({ title }) => {
    const { proofDetails } = useLoaderData({});
    let imageData = []

    const [selectedTab] = useState(1)

    let { id } = useParams();
    const { state } = useLocation();

    console.log(state);


    const SendtoCustomer = async () => {
        let finalData = []
        imageData.map((values) => {
            if (values?.url) {
                finalData.push(values.url)
            }

        })



        try {
            const response = await sendProoftoClient(id, { images: finalData, comment: 'Daily soap', image_ids: [] })
            const { message: msg } = response
            console.log(response);
            message.success(msg)

        }
        catch (e) {
            message.error(e.message)
            throw e
        }


    }

    const navigate = useNavigate()
    return (

        <Page title={title} header={<Button type="primary" onClick={() => { navigate(generateRouteUrl('order-manager-list')) }}>Back</Button>} >
            <Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header='Sample Proof shared by Studio' key="1">
                    {imageData = []}


                    {proofDetails.studio_proof.map((sample, index) => {

                        return (<div className={style.itemDetail} key={index}>



                            <Checkbox onChange={(e) => {


                                if (e.target.checked == true) {
                                    imageData[index] = { url: sample.url, comment: '' }
                                }
                                else {
                                    delete imageData[index]
                                }









                            }} disabled={selectedTab == 2 ? true : false} />

                            <Image src={Server.BaseUrl + '/api/v1/' + sample.url} height={100} width={90} className={style.imageBox} />
                            <h4>{sample.name}</h4>
                            <div style={{ 'width': '25%' }}>
                                <p><b>Commnet from vendor : TODO TODOTODOTODOTODOTODOTODO</b></p>
                            </div>
                            <Row>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} download="sample.png"> <Button icon={<DownloadOutlined />} >Download</Button> </a>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} target="blank" ><Button icon={<EyeOutlined />}>View</Button> </a>
                            </Row>


                        </div>)
                    })


                    }
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <hr />

                    <br />
                    <br />
                    <br />

                    <div className={style.tabBody}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam vitae odio mollitia reiciendis, quibusdam vel incidunt aut ut consequatur velit deserunt dicta non consequuntur possimus sequi aliquid. Ad, nesciunt expedita!
                        <br />

                        <div>
                            <h3 style={{ 'text-align': 'start' }}>Add a comment</h3>



                            <TextArea placeholder="Your message Here..." allowClear />


                        </div>
                        <br />

                        <Button onClick={SendtoCustomer}>Send to Customer</Button>
                    </div>






                </Panel>
            </Collapse>




        </Page>
    )

}

SendReadyProof.Loader = async ({ params }) => {


    if (isNaN(params.id)) {
        throw new Error('Invalid Orrder ID')
    }
    const proofDetails = await fetchProofDetails(params.id)
    if (!proofDetails) {
        throw new Error('Invalid Quoation')
    }

    console.log(proofDetails);

    // setCurrent(checkStatus(quotationDetails))






    // const basicquotationDetails = (({
    // 	vendorData, item, specifications
    // }) => ({
    // 	vendorData, item, specifications
    // }))(quotationDetails)



    return { proofDetails }
}


SendReadyProof.propTypes = {
    title: PropTypes.string,
}
export default SendReadyProof