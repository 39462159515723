import React from 'react'
import { Page } from '../../components/page';
function Dashboard(props) {
  const { title } = props;
  return (
    <Page className='dashboard' title={title}>
      hi, hello there
    </Page>
  )
}

Dashboard.propTypes = {...Page.propTypes}

export default Dashboard
