
import { createContext, useContext } from 'react';


export const AuthContext = createContext({});

export const useAuth = () => {
  if (!AuthContext) {
    console.log('wrap you component inside ProviderAuth');
  }
  return useContext(AuthContext);
};

