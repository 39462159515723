import { Button, Card, Col, Collapse, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { Page } from '../../components/page';

import { CalendarTwoTone, ContainerTwoTone, FileTextOutlined, FileTextTwoTone } from '@ant-design/icons';
import { Avatar, Steps } from 'antd';
import { getActivityLogs, getOrderDetails } from '../../api/order';
import { ItemDetailsSection } from '../../components/data';
import styles from '../../components/data/quotation/quotation-item.module.scss';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { StepBody } from './index';
const { Panel } = Collapse;



const { Meta } = Card


function checkStatus(orderDetails) {
	return orderDetails.order_status ?? 0;


}

const OrderDetails = ({ title }) => {

	const { orderDetails, activityLogs } = useLoaderData({});

	const [current, setCurrent] = useState(checkStatus(orderDetails));
	// let current = checkStatus(quotationDetails)

	//setCurrent(checkStatus(quotationDetails));



	const navigate = useNavigate()

	const onChange = (value) => {
		console.log('onChange:', value);
		setCurrent(value + 1);
	};


	function convertDate(timestamp) {
		return new Date(timestamp).toLocaleDateString('de-DE')
	}



	return (
		<Page title={title} header={<Button type="primary" onClick={() => { navigate(generateRouteUrl('/order-manager-list')) }}>Back</Button>} >
			<Row justify='space-between'>

				<Col>
					<Collapse collapsible="icon"
						defaultActiveKey={['1']}
						expandIconPosition="end">
						<Panel header={orderDetails.product.name} key="1">
							<ItemDetailsSection detail={orderDetails} />


						</Panel>
					</Collapse></Col>

				<Col>
					<Collapse collapsible="icon" defaultActiveKey={['2']} expandIconPosition="end">
						<Panel header='Item Information' key="2">

							<Card bordered={false} size='small'>
								<Meta
									className={styles.item}
									style={{ 'font-size': '20px', 'padding': '10px' }}


									avatar={<ContainerTwoTone />}
									title="Quantity"
									description={<span className={styles.cardValue}>{orderDetails.quantity} , </span>

									}
								/>

							</Card>

							<Card bordered={false} size='small'>
								<Meta
									className={styles.item}
									style={{ 'font-size': '20px', 'padding': '10px' }}


									avatar={<FileTextTwoTone />}
									title="Order Date  : TODO"
									description={<span className={styles.cardValue}>{convertDate(orderDetails.client_expected_delivery_date)} </span>}

								/>

							</Card>

							{// TODO============================================= 
							}
							<Card bordered={false} size='small'>
								<Meta
									className={styles.item}
									style={{ 'font-size': '20px', 'padding': '10px' }}


									avatar={<FileTextTwoTone />}
									title="Submit Order By : TODO"
									description={<span className={styles.cardValue}>{convertDate(orderDetails.delivery_date)} </span>}

								/>

							</Card>

							<Card bordered={false} size='small'>
								<Meta
									className={styles.item}
									style={{ 'font-size': '20px', 'padding': '10px' }}


									avatar={<CalendarTwoTone />}
									title="Delievery Deadline Date"
									description={<span className={styles.cardValue}>{orderDetails.delivery_date} </span>}

								/>

							</Card>

							<Card bordered={false} size='small'>
								<Meta
									className={styles.item}
									style={{ 'font-size': '20px', 'padding': '10px' }}


									avatar={<CalendarTwoTone />}
									title="Delivery Location"
									description={<span className={styles.cardValue}>{

										orderDetails.delivery_type == 2 ? 'Multiple' : orderDetails.delivery_type == 3 ? 'Bulk' : 'Single'

									} </span>}

								/>

							</Card>





						</Panel>
					</Collapse>
				</Col>
			</Row>

			<Row>
				<Collapse collapsible="icon" defaultActiveKey={['3']} expandIconPosition="end">
					<Panel header='Comment' key="3">
						<h4>{orderDetails.client_comment}</h4>


					</Panel>
				</Collapse>


			</Row>

			<Row>
				<div className={styles.stepBody}>

					<div style={{ "overflow-x": "scroll" }}>

						<Steps

							current={current - 1}
							labelPlacement="vertical"
							onChange={onChange}
							items={[



								{
									icon: <Avatar style={{ backgroundColor: current == 1 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />,

									title: 'Order Confirmation'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 2 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />,

									title: 'Order Sent'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 3 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Waiting for Files'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 4 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Request Template from Vendor'
								},

								{
									icon: <Avatar style={{ backgroundColor: current == 5 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Request Recieved from Vendor'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 6 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Template Sent to Studio'
								},





								{
									icon: <Avatar style={{ backgroundColor: current == 7 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Template Ready'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 8 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Send Template'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 9 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Make ArtWork'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 10 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Artwork Sent to Vendor'
								},

								{
									icon: <Avatar style={{ backgroundColor: current == 11 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'ArtWork Received'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 12 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'ArtWork Rejected'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 13 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'ArtWork Sent to Studio'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 14 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'ArtWork Ready'
								},

								{
									icon: <Avatar style={{ backgroundColor: current == 15 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Proof Requested'
								},

								{
									icon: <Avatar style={{ backgroundColor: current == 16 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Proof Received'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 17 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Proof Rejected'
								},

								{
									icon: <Avatar style={{ backgroundColor: current == 18 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Proof Sent to Studio'
								},

								{
									icon: <Avatar style={{ backgroundColor: current == 19 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Proof Ready'
								},

								{
									icon: <Avatar style={{ backgroundColor: current == 20 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Proof Sent to Client'
								},

								{
									icon: <Avatar style={{ backgroundColor: current == 21 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Proof Rejected by Client'
								}, {
									icon: <Avatar style={{ backgroundColor: current == 22 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Ready for Production'
								}, {
									icon: <Avatar style={{ backgroundColor: current == 23 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'In Production'
								},

								{
									icon: <Avatar style={{ backgroundColor: current == 24 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Expeted Dispatch Date'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 25 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Product Dispatched'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 26 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Delivered'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 27 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Delay in Delivery'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 28 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'On Hold'
								},
								{
									icon: <Avatar style={{ backgroundColor: current == 29 ? '#23B17C' : '#f2f2f2' }} icon={<FileTextOutlined />} size={32} />
									,
									title: 'Cancel Request'
								},


							]}
						/>
					</div>



					<StepBody i={current} data={orderDetails} activitylogs={activityLogs} stateChanger={setCurrent} />




				</div>



			</Row>



		</Page>
	)




}

OrderDetails.Loader = async ({ params }) => {

	console.log('loader called');
	if (isNaN(params.id)) {
		throw new Error('Invalid Order ID')
	}
	const orderDetails = await getOrderDetails(params.id)
	if (!orderDetails) {
		throw new Error('Invalid Order')
	}

	const activityLogs = await getActivityLogs(params.id)
	if (!activityLogs) {
		throw new Error('invalid id')
	}

	// setCurrent(checkStatus(quotationDetails))






	// const basicquotationDetails = (({
	// 	vendorData, item, specifications
	// }) => ({
	// 	vendorData, item, specifications
	// }))(quotationDetails)



	return { orderDetails, activityLogs }
}

OrderDetails.propTypes = {
	title: PropTypes.string
}

export default OrderDetails;