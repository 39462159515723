import { SendOutlined } from '@ant-design/icons';
import { Button, Input, message } from "antd";
import PropTypes from 'prop-types';
import React, { useRef } from "react";
import { useActionData, useParams } from 'react-router-dom';
import { uploadFileFromObject } from "../../../api/common";
import { sendArtworktoVendor } from "../../../api/order";
import styles from '../../../components/data/production/item.module.scss';
import { AddFileUpload, Message, MessageTypes } from "../../../ui";
import { ActionError } from '../../../library/classes/exceptions';

const OrderManagerStudio = ({ data, heading, imageIds }) => {
    const actionData = useActionData()
    const fileUploaderRef = useRef([])
    let commentData = []
    const { quotationToken } = useParams()

    const handleFilesUpload = async () => {

        let finalData = []
        const promises = fileUploaderRef.current.map(file => uploadFileFromObject(quotationToken, file))
        try {

            imageIds.map((values) => {
                if (values?.url) {
                    finalData.push(values.url)
                }

            })
            const fileIds = await Promise.all(promises)
            const imageData = []



            fileIds.map((value, key) => {
                console.log(commentData[key], value);
                imageData.push({ id: value, comment: commentData[key] })

            })
         const response = await sendArtworktoVendor(data.id, { images: finalData, image_ids: imageData })
            // sendClientArtworktoStudio(data.id, {images:finalData ,image_ids:imageData} )
            const { message: msg } = response

            message.success(msg);

        }
        catch (error) {
            console.log(error);
            throw new Response('Unable to upload files', { status: 500 })
        }

        // try {
        //     const fileIds = await Promise.all(promises)
        //     const jsonData = { file: fileIds[0], comment: '321421412345' }




        // }
        // catch (error) {
        //     throw new Response('Unable to upload files', { status: 500 })
        // }


    }

    return (

        <div>

            <div className={styles.tabContainer} >

                <h3>{heading}</h3>
                <p>Product Link from vendor</p>
                <Input value={data.product_link ?? ''} disabled />

                <p style={{ 'margin-top': '16px', 'margin-bottom': '16px' }}>Specifications : </p>


                <div className={styles.wrapper}>

                    <div className={styles.right}>

                        <div className={styles.specifications}>
                            {data.specification.map(({ name, value }) => (
                                <React.Fragment key={name}>
                                    <div className={styles.name}>{name}</div>
                                    <div className={styles.value}>{value}</div>
                                </React.Fragment>
                            ))}
                        </div>

                        {/* <QuotationItemQuantities prices={item.prices} selectedId={selectedId} onSelectionChange={(selected) => {
                    onSelectionChange({ itemId: item.id, priceId: selected })
                }} /> */}
                    </div>

                </div>
            </div>

            <br />

            <div>
                {!!actionData && actionData instanceof ActionError && <Message type={MessageTypes.ERROR} message={actionData.message} timeOut={3} />}
                {actionData === true && <Message type={MessageTypes.SUCCESS} message="File Uploaded Successfully" timeOut={3} />}
                <AddFileUpload ref={{ fileUploaderRef, commentData }} />
                <i>Supported formats : PDF ,ESP,PSD,AI,TIFF,SVG</i>
            </div>
            <div className={styles.sendButton}>
                <Button icon={<SendOutlined />} onClick={handleFilesUpload}>Send to Vendor</Button>
            </div>

        </div>
    )



}

OrderManagerStudio.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    heading: PropTypes.string,
    imageIds: PropTypes.arrayOf(PropTypes.object)

}

export default OrderManagerStudio