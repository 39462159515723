// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_SINGLE = '/item/:itemId/vendor/:vendorId/:action';
const ENDPOINT_SINGLE_QUANTITY = '/item/:itemId/vendor/:vendorId/quantity/:quantity';

export const assignProjectItemVendor = (itemId, vendorId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE, {
        itemId,
        vendorId,
        action: 'assign'
    }))
}

export const removeProjectItemVendor = (itemId, vendorId) => {
    return axiosInstance.delete(generatePath(ENDPOINT_SINGLE, {
        itemId,
        vendorId,
        action: 'assign'
    }))
}

export const updateProjectItemVendorPrice = (itemId, vendorId, quantity, prices) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_QUANTITY, {
        itemId,
        vendorId,
        quantity
    }), prices)
}


export const getVendorProjectItemQuotationRequest = (itemId, vendorId) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        itemId,
        vendorId,
        action:'quotation-request'
    })).then(({ data }) => data)
}

export const projectItemVendorQuotationComment = (itemId, vendorId, comment) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE, {
        itemId,
        vendorId,
        action: 'quotation-comment'
    }), {
        comment
    })
}

export const selectVendor = (itemId, vendorId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE, {
        itemId,
        vendorId,
        action: 'select'
    }), { })
}


export const sendVendorProjectItemQuotationRequest = (itemId, vendorId) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE, {
        itemId,
        vendorId,
        action: 'quotation-request'
    }))
}