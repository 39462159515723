import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from "react";
import { generatePath, useNavigate } from 'react-router-dom';
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { CommentForClient, CommentForVendor } from '../index';

const ProofRejected = ({ data }) => {
    const navigate = useNavigate()
    return <div>
    <div className={styles.stepBox}>
        <h3 style={{ 'text-align': 'start' }}>Task For You</h3>
        <div className={styles.message}>
            <ul>
                <li> Proof has been rejected.
                    <span style={{ "float": 'right' }}><Button onClick={()=>{
                         navigate(
                            generatePath('/customer-artwork/:id', { id: data.id }),
                            
                            { state: data }
                        
                        )

                    }}>Re - Request Artwork</Button></span>
                </li>

            </ul>
        </div>
    </div>
    <br />
    <br />

    <CommentForClient comment={data.client_comment} />
    <CommentForVendor comment={data.vendor_comment} />


</div>
}

ProofRejected.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    // logs: PropTypes.arrayOf(PropTypes.object).isRequired,
    // changeState: PropTypes.func
}
export default ProofRejected ;