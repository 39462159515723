
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import PropTypes from 'prop-types'
import { Button, message } from 'antd';
import React from "react";
import { waitngForFiles } from '../../../api/order';
import { ActivityLogs, CommentForClient, CommentForVendor } from '../index';



const OrderSent = ({ data, logs }) => {

    const  handleContinue = async ()=>{

       

		try {
			const { message: msg} = await waitngForFiles(data.id , [])
			message.success(msg)
			// navigate(generatePath('/customers/:id', {
			// 	id: data.id
			// }))
		}
		catch (e) {
            message.error(e.message)
            throw e
        }

    }

    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'text-align': 'start' }}>Task To do for Customer</h3>
            <div className={styles.message}>
              
                
                    {data.reminder_artwork==0 ? 
                    <ul>
                       
                <li>Reminder sent to customer to share the artwork
                <span style={{ "float": 'right' ,'font-size':'16px' }}> X </span>
            </li>
            </ul>
                 :<div></div>    
                }
                
                {data.reminder_delivery_address==0  ? 
                <ul>
                <li>Reminder sent to customer to share the delivery address
                <span style={{ "float": 'right' ,'font-size':'16px' }}> X </span>
            </li> 
            </ul>
             : <div></div>}
                

           
                
            </div>
            <br />
            <br />
            <Button onClick={handleContinue}>Continue</Button>
        </div>
        <br />
        <br />

        <CommentForClient comment={'testing'} />
        <CommentForVendor comment={'testing'} />
        <ActivityLogs logs = {logs}/>
        


    </div>
}

OrderSent.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    logs: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default OrderSent;