import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from "react";
import { generatePath, useNavigate } from 'react-router-dom';
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { CommentForClient, CommentForVendor } from '../index';




const ArtWorkReceived = ({ data }) => {

    const navigate = useNavigate()

    const handleRequestTemplate = async () => {
        navigate(
            generatePath('/customer-artwork/:id', { id: data.id }),

            { state: data }

        )





    }
    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'text-align': 'start' }}>Task For You</h3>
            <div className={styles.message}>
                <ul>
                    <li>The customer has shared the artwork. Please upload the file and send to vendor for comfirmation
                        <span style={{ "float": 'right' }}><Button onClick={handleRequestTemplate}>View Artwork</Button></span>
                    </li>

                </ul>
            </div>
        </div>


        <br />
        <br />

        <CommentForClient comment={'testing'} />
        <CommentForVendor comment={'testing'} />


    </div>
}

ArtWorkReceived.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ArtWorkReceived;