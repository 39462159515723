import PropTypes from 'prop-types';
import React from 'react';
import { getAllProjects } from '../../api/project';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { FinanceProjectColumns } from '../../library/constants';

function Finance({ title }) {
  const [loading, /* error, */ list, hasNoMore, /* page */, /* sort */, /* filters */, {
    nextPage,
    setSort,
    /* setFilters */
  }] = useFetch({
    functionToFetchData: getAllProjects
  })

  const loadData = (sort) => {
    setSort(sort)
  }

  // const handleRowClick = (_, record) => {
  //   navigate(generatePath('/projects/:id', {
  //     id: record.id
  //   }))
  // }

  return (
    <Page className='Finance' title={title}>
      <Table
        columns={FinanceProjectColumns}
        data={list}
        isLoading={loading}
        onChange={loadData}
        //onRowClick={handleRowClick}
        hasMore={!hasNoMore}
        loadMore={nextPage}

      />
    </Page>
  )
}

Finance.propTypes = {
  title: PropTypes.string,
}

export default Finance
