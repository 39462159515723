import { Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ValidationForm as Form } from '../';

import { CountrySelect } from '../../inputs';
import styles from './vendor-address.module.scss';


const validationRules = {
	street: [
		{
			required: true
		}
	],
	street_number: [
		{
			required: true
		}
	],
	city: [
		{
			required: true
		}
	],
	zipcode: [
		{
			required: true
		}
	],
	country_id: [
		{
			required: true
		}
	],
}




const VendorAdress = ({ children, getValidationStatus, index,

	...otherProps }) => {
	const [form] = Form.useForm()

	// const handleValuesChange = debounce(onValuesChange, 500)

	// 	const handleCompanyChange = (company_id) => {
	// console.log(company_id);

	// 		if (!company_id) {
	// 			return
	// 		}
	// 		getCustomerDetails(company_id)
	// 			.then((customer) => {
	// 				const { entity_id, payment_term_id } = customer
	// 				form.setFieldsValue({
	// 					entity_id,
	// 					payment_term_id,
	// 				})


	// 				form.validateFields()
	// 					.then(() => {
	// 						handleValidationChange(true)
	// 					})
	// 					.catch(() => {
	// 						handleValidationChange(false)
	// 					})
	// 					.finally(() => {
	// 						if (onCustomerChange)
	// 							onCustomerChange(customer)

	// 						//manually trigger onChange Event of form
	// 						handleValuesChange({
	// 							entity_id,
	// 							payment_term_id
	// 						}, form.getFieldsValue())
	// 					})
	// 			})
	// 	}

	// const handleValidationChange = (isValid) => {
	// 	if (getValidationStatus) {
	// 		getValidationStatus(isValid)
	// 	}
	// }

	return (

		<Form form={form} name={`address-${index}`} onValidationStatusChange={getValidationStatus} layout="vertical" className={styles.FormWrapper} validationRules={validationRules} {...otherProps}>

			<Form.Item name="street" label="Street">
				<Input />
			</Form.Item>
			<Form.Item name="street_number" label="Street Number" className={styles['FormField--TwoCol']}>
				<Input />
			</Form.Item>
			<Form.Item name="city" label="City">
				<Input type="text" />
			</Form.Item>
			<Form.Item name='county' label="County">
				<Input type="text" />
			</Form.Item>
			<Form.Item name='country_id' label="Country">
				<CountrySelect />
			</Form.Item>
			<Form.Item name="zipcode" label="Zipcode" >
				<Input type="text" />
			</Form.Item>
			{children}


		</Form >


	)
}

VendorAdress.propTypes = {
	children: PropTypes.node,
	index: PropTypes.string.isRequired,
	getValidationStatus: PropTypes.func,
}

export default VendorAdress