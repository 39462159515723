import { Input, Select } from 'antd';
import { debounce } from 'lodash-es';
import PropTypes from 'prop-types';
import React from 'react';
import { ValidationForm as Form } from '../';
import { getCustomerDetails } from '../../../api/customer/customer-details.js';
import PaymentTerms from '../../../library/dynamics/payment-terms';
import { CompanySelect, ExecutiveEntitySelect } from '../../inputs';
import styles from './project-details.module.scss';

const validationRules = {
	source_id: [
		{
			required: true
		},
	],
	customer_id: [
		{
			required: true
		},
	],
	account_manager_id: [
		{
			required: true
		}
	],
	payment_condition_id: [
		{
			required: true
		}
	],
	payment_term_id: [
		{
			required: true
		}
	]
}

const ProjectDetails = ({ children, onValuesChange, getValidationStatus, onCustomerChange, ...otherProps }) => {
	const [form] = Form.useForm()

	const handleValuesChange = debounce(onValuesChange, 500)

	const handleCompanyChange = (company_id) => {
		if (!company_id) {
			return
		}
		getCustomerDetails(company_id)
			.then((customer) => { 
				const { entity_id, payment_term_id } = customer
				form.setFieldsValue({
					entity_id,
					payment_term_id,
				})


				form.validateFields()
					.then(() => {
						handleValidationChange(true)
					})
					.catch(() => {
						handleValidationChange(false)
					})
					.finally(() => {
						if (onCustomerChange)
							onCustomerChange(customer)

						//manually trigger onChange Event of form
						handleValuesChange({
							entity_id,
							payment_term_id
						}, form.getFieldsValue())
					})
			})
	}

	const handleValidationChange = (isValid) => {
		if (getValidationStatus) {
			getValidationStatus(isValid)
		}
	}

	return (
		<Form
			form={form}
			name='projectDetails'
			onValidationStatusChange={handleValidationChange}
			layout="vertical"
			className={styles.FormWrapper}
			validationRules={validationRules}
			onValuesChange={(changedValues, values) => {
				if (!changedValues['customer_id'])
					handleValuesChange(changedValues, values)
			}}
			validateTrigger={['onChange']}
			{...otherProps}>
			<Form.Item name="source_id" label="Source" >
				<Select optionType="button" buttonStyle="solid"
					options={[
						{
							label: 'Email',
							value: 'email'
						},
						{
							label: 'Phone',
							value: 'phone'
						},
						{
							label: 'Website',
							value: 'website'
						},
					]}
				>
				</Select>
			</Form.Item>
			<Form.Item name="customer_id" label="Company" >
				<CompanySelect onChange={handleCompanyChange} />
			</Form.Item>
			<Form.Item name="entity_id" label="Entity" >
				<ExecutiveEntitySelect />
			</Form.Item>
			<Form.Item name="account_manager_id" label="Account Manager" >
				<Select>
					<Select.Option value={1}>Dummy User</Select.Option>
				</Select>
			</Form.Item>
			<Form.Item name="client_order_number" label="Client Order Number" >
				<Input />
			</Form.Item>
			<Form.Item name="payment_condition_id" label="Payment condition" >
				<Select options={['50%', '100%'].map((i) => ({
					label: i,
					value: i
				}))} />
			</Form.Item>
			<Form.Item name='payment_term_id' label="Payment Term">
				<Select options={PaymentTerms} fieldNames={{ value: 'id', label: 'name' }} />
			</Form.Item>
			{children}
		</Form >
	)
}

ProjectDetails.propTypes = {
	children: PropTypes.node,
	onSubmit: PropTypes.func,
	getValidationStatus: PropTypes.func,
	onValuesChange: PropTypes.func.isRequired,
	onCustomerChange: PropTypes.func,
}

export default ProjectDetails