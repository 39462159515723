import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Input, Row, Tabs, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import StickyBox from 'react-sticky-box';
import { changeStatus, fetchArtworkDetails, proofRequested, sendClientArtworktoStudio } from '../../api/order';
import OrderManagerStudio from '../../components/data/studio/orderMangerStudio';
import SendToStudio from '../../components/data/studio/sendTOstudio';
import style from '../../components/general.module.scss';
import { Page } from '../../components/page';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { Server } from "../../library/constants/server";
import { Image } from '../../ui';
const { Panel } = Collapse


const { TextArea } = Input
const CustomerArtwork = ({ title }) => {
    const { artworkDetails } = useLoaderData({});
    const [rejectComment, setrejectComment] = useState('')
    let imageData = []

    let { id } = useParams();
    const { state } = useLocation();

    const selectedTab = 1 ;
    console.log(state);

    const renderTabBar = (props, DefaultTabBar) => (
        <StickyBox
            offsetTop={0}
            offsetBottom={20}
            style={{
                zIndex: 1,
            }}
        >
            <DefaultTabBar
                {...props}
                style={{
                    background: 'transparent',
                }}
            />
        </StickyBox>
    );

    const handleSubmit = async () => {
        let finalData = []
        imageData.map((values) => {
            if (values?.url) {
                finalData.push(values.url)
            }

        })



        try {
            const response = await sendClientArtworktoStudio(id, { images: finalData, comment: 'Daily soap', image_ids: [] })
            const { message: msg } = response
            console.log(response);
            message.success(msg)

        }
        catch (e) {
            message.error(e.message)
            throw e
        }


    }

    const sendToVendor = async () => {
        let finalData = []
        imageData.map((values) => {
            if (values?.url) {
                finalData.push(values.url)
            }

        })



        try {
            const response = await proofRequested(id, { images: finalData, comment: 'Daily soap', image_ids: [] })
            const { message: msg } = response
            console.log(response);
            message.success(msg)

        }
        catch (e) {
            message.error(e.message)
            throw e
        }



    }


    const handleArtworkReject = async () => {
        try {
            const { message: msg } = await changeStatus(id, 12, { comment: rejectComment })
            message.success(msg)
        }
        catch (e) {
            message.error(e.message)
            // throw e
        }


    }

    const tabList =
        [{

            label: 'Send to Studio',
            key: 1,
            children:

                <SendToStudio data={state} heading={'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam vitae odio mollitia reiciendis, quibusdam vel incidunt aut ut consequatur velit deserunt dicta non consequuntur possimus sequi aliquid. Ad, nesciunt expedita!'} onSubmit={handleSubmit} />



        },
        {

            label: 'Order Manager (Studio)',
            key: 2,
            children: <OrderManagerStudio data={state} heading={'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam vitae odio mollitia reiciendis, quibusdam vel incidunt aut ut consequatur velit deserunt dicta non consequuntur possimus sequi aliquid. Ad, nesciunt expedita!'} imageIds={imageData} />
        },
        {

            label: 'Send to Vendor',
            key: 3,
            children: <div className={style.tabBody}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam vitae odio mollitia reiciendis, quibusdam vel incidunt aut ut consequatur velit deserunt dicta non consequuntur possimus sequi aliquid. Ad, nesciunt expedita!
                <br />

                <div>
                    <h3 style={{ 'text-align': 'start' }}>Add a comment</h3>

                    <TextArea placeholder="Your message Here..." allowClear />
                </div>

                <Button onClick={sendToVendor}>Send to Vendor</Button>
            </div>
        },

        {


            label: 'Reject',
            key: 4,
            children: <div className={style.tabBody}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam vitae odio mollitia reiciendis, quibusdam vel incidunt aut ut consequatur velit deserunt dicta non consequuntur possimus sequi aliquid.
                <br />

                <div>
                    <h3 style={{ textAlign: 'start' }}>Add a comment</h3>

                    <TextArea placeholder="Your message Here..." value={rejectComment} onChange={(e) => {
                        setrejectComment(e.target.value)
                    }} allowClear />
                </div>

                <Button onClick={() => {
                    handleArtworkReject(rejectComment)
                }}>Send to Vendor</Button>
            </div>
        },


        ]


    const navigate = useNavigate()
    return (

        <Page title={title} header={<Button type="primary" onClick={() => { navigate(generateRouteUrl('/order-manager-list')) }}>Back</Button>} >
            <Collapse collapsible="icon" defaultActiveKey={['222']} expandIconPosition="end">
                <Panel header='Customer Artwork' key="222">
                    {imageData = []}


                    {artworkDetails.client_artwork.map((sample, index) => {

                        console.log(sample);
                        return (<div className={style.itemDetail} key={index}>

                            <Checkbox onChange={(e) => {

                                if (e.target.checked == true) {
                                    imageData[index] = { url: sample.url, comment: '' }
                                }
                                else {
                                    delete imageData[index]
                                }

                            }} disabled={selectedTab == 2 ? true : false} />

                            <Image src={Server.BaseUrl + '/api/v1/' + sample.url} height={100} width={90} className={style.imageBox} />
                            <h4>{sample.name}</h4>
                            <div style={{ 'width': '25%' }}>

                                <p><b>Commnet from vendor : TODO TODOTODOTODOTODOTODOTODO</b></p>
                            </div>
                            <Row>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} download="sample.png"> <Button icon={<DownloadOutlined />} >Download</Button> </a>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} target="blank" ><Button icon={<EyeOutlined />}>View</Button> </a>
                            </Row>


                        </div>)
                    })


                    }
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <hr />

                    <br />
                    <br />
                    <br />

                    <Tabs defaultActiveKey="1" renderTabBar={renderTabBar} items={tabList} />
                </Panel>
            </Collapse>




        </Page>
    )

}

CustomerArtwork.Loader = async ({ params }) => {


    if (isNaN(params.id)) {
        throw new Error('Invalid Orrder ID')
    }
    const artworkDetails = await fetchArtworkDetails(params.id)
    if (!artworkDetails) {
        throw new Error('Invalid Quoation')
    }

    console.log(artworkDetails);

    // setCurrent(checkStatus(quotationDetails))






    // const basicquotationDetails = (({
    // 	vendorData, item, specifications
    // }) => ({
    // 	vendorData, item, specifications
    // }))(quotationDetails)



    return { artworkDetails }
}


CustomerArtwork.propTypes = {
    title: PropTypes.string,
    // stateChanger :PropTypes.func
}
export default CustomerArtwork