import React from "react";
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { CommentForClient, CommentForVendor } from '../index';
import PropTypes from 'prop-types'


const ProofSenttoCustomer = ({ data }) => {
    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'textAlign': 'start' }}>Task For You</h3>
            <div className={styles.message}>
                <ul>
                    <li> Proof has been Sent to Customer.
                        {/* <span style={{ "float": 'right' }}><Button onClick={handleRequestTemplate}>Upload Artwork</Button></span> */}
                    </li>

                </ul>
            </div>
        </div>
        <br />
        <br />

        <CommentForClient comment={data.client_comment} />
        <CommentForVendor comment={data.vendor_comment} />


    </div>
}

ProofSenttoCustomer.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    // logs: PropTypes.arrayOf(PropTypes.object).isRequired,
    // changeState: PropTypes.func
}
export default ProofSenttoCustomer;