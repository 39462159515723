import React, { useState } from 'react'
import { createContact } from '../../api/contact/contact'
import { Page } from '../../components/page'
import PropTypes from 'prop-types'
import { Collapse, Form, Button, message } from 'antd';
import { Contact } from '../../components/form'
const { Panel } = Collapse

//import Customer from 'components/form/customer/customer.jsx'

const ContactCreate = ({ title }) => {
	
	const [customerContactDetails, setCustomerContactDetails] = useState({})
	// const [isLoading, setIsLoading] = useState(true)
	const [isSaving, setIsSaving] = useState(false)

	// useEffect(() => {
	// 	setIsLoading(true)
	// 	getContact(contactId)
	// 		.then(({ data }) => {
	// 			setCustomerContactDetails(data)
	// 			setIsLoading(false)
	// 		})
	// 		.finally(() => {
	// 			setIsLoading(false)
	// 		})
	// }, [])


	const handleCustomerDetailContactsSubmit = (data) => {
		setIsSaving(true)
		createContact(data)
			.then(({ data, message: msg }) => {
				message.success(msg)
				setCustomerContactDetails(data)
			})
			.catch(e => {
				message.error(e.message)
				throw e
			})
			.finally(() => {
				setIsSaving(false)
			})
	}

	return (
		<Page className='customers-detail' title={title}>
			{customerContactDetails &&
				<Collapse collapsible="icon" defaultActiveKey={['1']}>
					<Panel header='Contact Details' key="1" >
						<Contact onFinish={handleCustomerDetailContactsSubmit}>
							<Form.Item>
								<Button type="primary" htmlType="submit" loading={isSaving}>Save</Button>
							</Form.Item>
						</Contact>
					</Panel>
				</Collapse>	
			}
		</Page>
	)
}

ContactCreate.propTypes = {
	title: PropTypes.string
}

export default ContactCreate