import React from 'react'
import PropTypes from 'prop-types';
import { Login } from './routes/login';
import { useAuth } from './providers/auth';
import { isEmpty } from 'lodash-es';

export default function AuthManager(props) {
  const { children } = props;
  const { isPageLoading, user, actions } = useAuth();

  let authenticated = !isEmpty(user);
  // // todo: need to remove the line below
  // // marked statically for now, use the presence of user to find authenticated status
  // authenticated = true;

  if (isPageLoading) {
    return (
      <div className="loading-screen">
        Loading...
      </div>
    );
  }


  if (!authenticated) return (
    <Login onLogin={actions.onLogin} />
  )
  return children
}

AuthManager.propTypes = {
  children: PropTypes.element,
}
