import { Button, message } from 'antd';
import PropTypes from 'prop-types';
import React from "react";
import { changeStatus } from '../../../api/order';
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { CommentForClient, CommentForVendor } from '../index';

const SendTemplate = ({ data }) => {


    const  handleRequestTemplate = async ()=>{
      
       
        try {
            const { message: msg, } = await changeStatus(data.id,8, [])
            message.success(msg)
            
        }
        catch (e) {
            message.error(e.message)
            throw e
        }
	

    }
    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'textAlign': 'start' }}>Task For You</h3>
            <div className={styles.message}>
                <ul>
                    <li>Template file has been sent ot customer for review
                        <span style={{ "float": 'right' }}><Button onClick={handleRequestTemplate}>Continue</Button></span>
                    </li>

                </ul>
            </div>
        </div>
        <br />
        <br />

        <CommentForClient comment={'testing'} />
        <CommentForVendor comment={'testing'} />


    </div>
}

SendTemplate.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
  //  logs: PropTypes.arrayOf(PropTypes.object).isRequired
    //  changeState: PropTypes.func
}
export default SendTemplate;