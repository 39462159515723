import React from 'react';
import moment from 'moment';
import { DatePicker as AntdDatePicker } from 'antd';
import PropTypes from 'prop-types'

const DatePicker = ({ value = null, onChange, ...otherProps }) => {
    const formattedDate = () => (value ? moment(value) : value)
    onChange
    return (
        <AntdDatePicker
            defaultValue={formattedDate}
            onChange={(_, date) => onChange(date)}
            format='YYYY-MM-DD'
            {...otherProps}
        />
    )
}

DatePicker.propTypes = {
    value: function (props, propName, componentName) {
        if (props[propName] && !/[0-9]{4}-[0-9]{2}-[0-9]{2}/.test(props[propName])) {
            return new Error(
                `Invalid value ${props[propName]}  for prop ${propName} supplied to ${componentName}. Validation failed.`
            );
        }
    },
    onChange: PropTypes.func,
}

export default DatePicker;