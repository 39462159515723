import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, message } from 'antd';
import { customAlphabet } from 'nanoid/non-secure';
import PropTypes from 'prop-types';
import React, { useEffect, useReducer, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { createCustomer } from '../../api/customer/create-customer.js';
import SmallSection from '../../components/design/small-section.jsx';
import { Customer, CustomerAddress, CustomerContact } from '../../components/form';
import { Page } from '../../components/page';
import { getCountryById } from '../../library/helpers';
const { Panel } = Collapse
const nanoid = customAlphabet('1234567890abcdef', 6)

const ACTIONS = {
	ADD: 'add',
	REMOVE: 'remove',
	UPDATE: 'update',
}

const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.ADD:
			return { ...state, [nanoid()]: false }

		case ACTIONS.REMOVE: {
			const tempState = { ...state }
			delete tempState[action.id]
			return { ...tempState }
		}

		case ACTIONS.UPDATE:
			return { ...state, [action.id]: action.value }

		default:
			alert('Invalid operation')
			return state
	}
}

const CustomerCreate = ({ title }) => {
	const [basicDetailsForm, SetBasicDetailsForm] = useState(false)
	const [addressForms, dispatchAddressForm] = useReducer(reducer, {})
	const [contactForms, dispatchContactForm] = useReducer(reducer, {})
	const navigate = useNavigate()

	const formData = useRef({
		customerDetails: {},
		addresses: {},
		contacts: {}
	})

	const isFormValid = () => {
		return basicDetailsForm &&
			Object.values(addressForms).every(Boolean) &&
			Object.values(contactForms).every(Boolean)
	}

	useEffect(() => {
		// const customerDetails = JSON.parse(localStorage.getItem('customerDetails'))
	}, [])

	const handleFormsChange = async (name, { forms }) => {
		if (name === 'customerDetails') {
			formData.current[name] = forms[name].getFieldsValue(true)
			return
		}

		const [type, id] = name.split('-')
		if (!id) {
			return
		}
		switch (type) {
			case 'address':
				//formData.current.addresses = mutateArrayItem(formData.current.addresses, id, forms[name].getFieldsValue(true))
				formData.current.addresses[id] = forms[name].getFieldsValue(true)
				break
			case 'contact':
				//formData.current.contacts = mutateArrayItem(formData.current.contacts, id, forms[name].getFieldsValue(true))
				formData.current.contacts[id] = forms[name].getFieldsValue(true)
				break
		}
	}

	const handleFormSubmit = async () => {
		//Check if form validated
		if (!isFormValid())
			return message.error('please fill all details')


		const details = {
			...formData.current.customerDetails,
			addresses: Object.values(formData.current.addresses),
			contacts: Object.values(formData.current.contacts)
		}

		try {
			const { message: msg, data } = await createCustomer(details)
			message.success(msg)
			navigate(generatePath('/customers/:id', {
				id: data.id
			}))
		}
		catch (e) {
			message.error(e.errors[0].message)
			throw e
		}
	}

	return (
		<Page className='customers-create' title={title} header={<Button type="primary" onClick={() => { navigate(-1) }}>Back</Button>}>
			<Form.Provider onFormChange={handleFormsChange}>
				<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header='Company Create' key="1">
						<Customer initialValues={{
							invoice_type_id: "email"
						}} getValidationStatus={(isValid) => { SetBasicDetailsForm(isValid) }} />
					</Panel>
				</Collapse>
				<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header='Addresses' key="1">
						{Object.keys(addressForms).map((id, index) => (
							<SmallSection key={id} primary={index == 0}>
								<CustomerAddress index={id} initialValues={{
									country_id: formData.current['customerDetails']?.country_id,
								}} getValidationStatus={(isValid) => { dispatchAddressForm({ type: ACTIONS.UPDATE, id, value:  isValid }) }}>
									<Form.Item>
										<Button className='action action--delete' onClick={() => dispatchAddressForm({ type: ACTIONS.REMOVE, id })}><DeleteOutlined /></Button>
									</Form.Item>
								</CustomerAddress>
							</SmallSection>
						))}
						<Button onClick={() => dispatchAddressForm({ type: ACTIONS.ADD })}>Add New Address</Button>
					</Panel>
				</Collapse>
				<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header='Contacts' key="1">
						{Object.keys(contactForms).map((id, index) => (
							<SmallSection key={id} primary={index == 0}>
								<CustomerContact index={id} initialValues={{
									language_id: formData.current['customerDetails']?.language_id,
									phone_country_code: getCountryById(formData.current['customerDetails']?.country_id)?.phone_number_code,
									mobile_country_code: getCountryById(formData.current['customerDetails']?.country_id)?.phone_number_code,
								}} getValidationStatus={(isValid) => { dispatchContactForm({ type: ACTIONS.UPDATE, id, value: isValid }) }}>
									<Form.Item>
										<Button className='action action--delete' onClick={() => dispatchContactForm({ type: ACTIONS.REMOVE, id })}><DeleteOutlined /></Button>
									</Form.Item>
								</CustomerContact>
							</SmallSection>
						))}
						<Button onClick={() => { dispatchContactForm({ type: ACTIONS.ADD }) }}>Add New Contact</Button>
					</Panel>
				</Collapse>
				<div className="actions" style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
					<Button onClick={  handleFormSubmit} 
					// disabled={!isFormValid()} 
					type="primary" icon={<CheckOutlined />}>Create</Button>
					<Button onClick={() => {
						if (confirm("Unsaved changes will be lost") == true) {
							navigate(-1)
						}
					}} type="danger" icon={<CloseOutlined />} >Cancel</Button>
				</div>
			</Form.Provider>
		</Page>
	)
}

CustomerCreate.propTypes = {
	title: PropTypes.string
}

export default CustomerCreate