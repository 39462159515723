import { FilterOutlined } from '@ant-design/icons';
import {
    Button, Form, Input,
    Select
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import Industries from '../../../library/dynamics/industries';
import { CountrySelect, ExecutiveEntitySelect, LanguageSelect } from '../../inputs';
import styles from './customer-filter.module.scss';

const CustomerFilterForm = ({ children, data, ...otherProps }) => {
    const [form] = Form.useForm()

    const clearForm = () => {
        form.setFieldsValue(Object.fromEntries(
            Object.keys(form.getFieldsValue()).map(key => ([key, null]))
        ))
        form.submit()
    }

    return (
        <Form form={form} initialValues={data} layout='vertical' {...otherProps} className={styles.FormWrapper} >
            <Form.Item name="company" label="Company">
                <Input />
            </Form.Item>
            <Form.Item name="email" label="Email">
                <Input type="text" />
            </Form.Item>
            {/* <Form.Item label="Contact Number" required>
                <input valuePropName={{ code: 'contact_country_code', number: 'contact_number' }} required />
            </Form.Item> */}
            <Form.Item name='industry_id' label="Industry">
                <Select options={Industries} fieldNames={{ value: 'id', label: 'name' }} />
            </Form.Item>
            <Form.Item name='country_id' label="Country">
                <CountrySelect />
            </Form.Item>
            <Form.Item name='language_id' label="Language">
                <LanguageSelect />
            </Form.Item>
            <Form.Item name='entity_id' label="Executive Entity">
                <ExecutiveEntitySelect />
            </Form.Item>
            <Form.Item name="website" label="website">
                <Input type="text" />
            </Form.Item>
            {children}
            <Form.Item label=" ">
                <Button htmlType="submit" type="primary" icon={<FilterOutlined />}>Search</Button>
                <Button onClick={clearForm} icon={<FilterOutlined />}>Reset</Button>
            </Form.Item>
        </Form>
    )
}

CustomerFilterForm.propTypes = {
    children: PropTypes.node,
    data: PropTypes.object,
}

export default CustomerFilterForm