
import { createContext, useContext } from 'react';


export const FormContext = createContext({});

export const useValidationForm = () => {
  return useContext(FormContext);
};

