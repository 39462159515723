import { Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { getAllOrders } from '../../api/order/order-manager';
import { OrderKanabanView } from '../../components/data';
import { Page } from '../../components/page';



const OrderItemKanabanView = () => {
  const navigate = useNavigate()
  const [list, setList] = useState([])

  useEffect(() => {
    getAllOrders().then(({ data }) => {
      setList(data)
    })
  }, [])

  const handleRowClick = (_, record) => {
    console.log(record);
    navigate(generatePath('/order-manager/:id', {
      id: record.id
    }))
  }

  if (!list) {
    return (
      <>
        <Spin />
      </>
    )
  }

  return (
    <OrderKanabanView data={[...list]}

      columns={[



        {
          key: 1,
          title: 'Order Confirmation'
        },
        {
          key: 2,
          title: 'Order Sent'
        },
        {
          key: 3,
          title: 'Waiting for Files'
        },
        {
          key: 4,
          title: 'Request Template from Vendor'
        },

        {
          key: 5,
          title: 'Request Recieved from Vendor'
        },
        {
          key: 6,

          title: 'Template Sent to Studio'
        },
        {
          key: 7,

          title: 'Template Ready'
        },
        {
          key: 8,

          title: 'Send Template'
        },
        {
          key: 9,

          title: 'Make ArtWork'
        },
        {
          key: 10,
          title: 'Artwork Sent to Vendor'
        },

        {
          key: 11,

          title: 'ArtWork Received'
        },
        {
          key: 12,

          title: 'ArtWork Rejected'
        },
        {
          key: 13,

          title: 'ArtWork Sent to Studio'
        },
        {
          key: 14,

          title: 'ArtWork Ready'
        },

        {
          key: 15,

          title: 'Reuquest Vendor for Proof'
        },

        {
          key: 16,

          title: 'Proof Received'
        },
        {
          key: 17,

          title: 'Proof Rejected'
        },

        {
          key: 18,

          title: 'Proof Sent to Studio'
        },

        {
          key: 19,

          title: 'Proof Ready'
        },

        {
          key: 20,

          title: 'Proof Sent to Client'
        },

        {
          key: 21,

          title: 'Proof Rejected by Client'
        }, {

          key: 22,

          title: 'Ready for Production'
        },
        {
          key: 23,


          title: 'In Production'
        },

        {
          key: 24,

          title: 'Expeted Dispatch Date'
        },
        {
          key: 25,

          title: 'Product Dispatched'
        },
        {
          key: 26,

          title: 'Delivered'
        },
        {
          key: 27,

          title: 'Delay in Delivery'
        },
        {
          key: 28,

          title: 'On Hold'
        },
        {
          key: 29,

          title: 'Cancel Request'
        },


      ]}

      onItemClick={(order) => {
        handleRowClick(null, order)
      }} />
  )
}


function Order(props) {
  return (
    <Page className='order-list' title={props.title} header={
      <>
        {/* <Button onClick={() => setFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button> */}
        {/* <Form.Item name="client_order_number" label="Client Order Number" > */}





        {/* <Segmented
          value={viewMode}
          options={[
            {
              value: VIEW_MODES.LIST,
              icon: <BarsOutlined />,
            },
            {
              value: VIEW_MODES.KANABAN,
              icon: <AppstoreOutlined />,
            },
          ]}
          onChange={setViewMode}
        /> */}
      </>
    }>
      {/*  <Drawer open={filtersVisible} onClose={() => setFiltersVisible(false)} >
        <CustomerFilterForm data={filters} onFinish={(data) => setFilters(data)} />
      </Drawer> */}
      {/* {viewMode === VIEW_MODES.LIST
        ? <ProjectsListView />
        : <ProjectsKanabanView />
      } */}
      <OrderItemKanabanView />



    </Page>
  )
}

Order.propTypes = {
  title: PropTypes.string
}

export default Order
