import { Button, Collapse, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { createProject } from '../../api/project';
import { ContactDescription } from '../../components/data';
import { SmallSection } from '../../components/design';
import { ProjectDetails } from '../../components/form';
import { Page } from '../../components/page';
const { Panel } = Collapse

const getProjectDefaults = () => ({
	account_manager_id: 1,
	payment_condition_id: '100%'
})

const ProjectCreate = ({ title }) => {
	const projectDetailsForm = useRef({
		isValid: false,
		data: {}
	})
	const navigate = useNavigate()
	const [customerContact, setCustomerContact] = useState(null)

	const handleCustomerChange = async (customer) => {
		if (!customer?.primary_contact_id || !customer?.contacts) {
			setCustomerContact(null)
			return
		}

		const primaryContact = customer?.contacts.find(({ id }) => id === customer.primary_contact_id)
		if (!primaryContact) {
			setCustomerContact(null)
			return
		}

		setCustomerContact(primaryContact)
		projectDetailsForm.current.data.primary_contact_id = primaryContact.id
	}

	const handleProjectDetailsChange = async (_, values) => {
		projectDetailsForm.current.data = { ...values, ['primary_contact_id']: projectDetailsForm.current.data.primary_contact_id }

		if (!projectDetailsForm.current.isValid) {
			return
		}

		try {
			const { message: msg, data } = await createProject({ ...projectDetailsForm.current.data })
			message.success(msg)
			navigate(generatePath('/projects/:id', {
				id: data.id
			}))
		}
		catch (e) {
			message.error(e.message)
			throw e
		}

	}

	return (
		<Page className='projects-create' title={title} header={<Button type="primary" onClick={() => { navigate(-1) }}>Back</Button>}>
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				alignTtems: 'stretch',
			}} >
				<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header='Project Information' key="1">
						<ProjectDetails
							initialValues={getProjectDefaults()}
							getValidationStatus={(isValid) => { projectDetailsForm.current.isValid = isValid }}
							onCustomerChange={handleCustomerChange}
							onValuesChange={handleProjectDetailsChange}
						/>
					</Panel>
				</Collapse>
				<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header='Contact Information' key="1">
						{customerContact &&
							<SmallSection>
								<ContactDescription contact={customerContact} />
							</SmallSection>
						}
					</Panel>
				</Collapse>
			</div>
		</Page>
	)
}

ProjectCreate.propTypes = {
	title: PropTypes.string
}

export default ProjectCreate