import React from "react";
// import {Row,Col} from 'antd'
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import PropTypes from 'prop-types'
import { Button } from 'antd';







const DelayInDelivery = ({ data }) => {


    return (
        <div>

            <div className={styles.stepBox}>
                <h3 style={{ 'textAlign': 'start' }}>Task For You</h3>
                <div className={styles.message}>
                    <ul>
                        <li>The vendor has shared the order is delayed by 1-2 days.
                            <span style={{ "float": 'right' }}><Button onClick={() => { }}>Notify Customer</Button></span>
                        </li>
                    </ul>
                </div>
            </div>
            <div className={styles.stepBox}>
                <div>
                    <table style={{ 'width': '100%' }}>
                        <tbody>
                            <tr>
                                <td style={{ 'padding': '16px' }}>Quantity</td>
                                <td>Delivery Address</td>
                                <td>Ship Quantity</td>
                                <td>Expected Dispatch Date</td>
                                <td>Tracking URL</td>
                                <td>Expected Delivery Date</td>
                            </tr>
                            {data.item_delivery_address && data.item_delivery_address.map((value, index) => {
                                return (
                                    <tr className={styles.ship} key={index}>
                                        <td style={{ 'padding': '16px' }}>{value.quantity}</td>
                                        <td>{value.street_number},{value.street},{value.city}</td>
                                        <td>{value.ship_quantity}</td>
                                        <td>{value.dispatch_date}</td>
                                        <td>{value.tracking_url}</td>
                                        <td>{value.expected_date}</td>
                                    </tr>
                                )
                            }
                            )
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            <br />
        </div>




    )
}


DelayInDelivery.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
}
export default DelayInDelivery;


