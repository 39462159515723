import React from 'react';
import PropTypes from 'prop-types'
import Currencies from '../../library/dynamics/currencies'
import { Radio } from 'antd'

function CurrencySelect({value = null, onChange }) {
    return (
        <Radio.Group buttonStyle="solid"
            onChange={onChange}
            value={value}
        >
            {Currencies.map((currency) => (
                <Radio.Button key={currency.id} value={currency.id} label={currency.name}>
                    {currency.code} ({currency.symbol})
                </Radio.Button>
            ))}
        </Radio.Group>
    );
}

CurrencySelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func
}

export default CurrencySelect;