import { SendOutlined } from '@ant-design/icons';
import { Button, Input, message } from "antd";
import React, { useRef } from "react";
import { useActionData, useParams } from 'react-router-dom';
import { uploadFileFromObject } from "../../api/common";
import { sendVendorTemplate } from "../../api/order";
import styles from '../../components/data/production/item.module.scss';
import { AddFileUpload, Message, MessageTypes } from "../../ui";
import { ActionError } from '../../library/classes/exceptions';
import PropTypes from 'prop-types';

const OrderManagerStudio = ({ data, heading, imageIds }) => {
    console.log(data);
    const actionData = useActionData()
    const fileUploaderRef = useRef([])
    let commentData=[]
    const { quotationToken } = useParams()



    const handleFilesUpload = async () => {

        let finalData = []
     const promises = fileUploaderRef.current.map(file => uploadFileFromObject(quotationToken, file))
        try {

            imageIds.map((values) => {
                if (values?.url) {
                    finalData.push(values.url)
                }

            })
       const fileIds = await Promise.all(promises) 
            const imageData=[]

           

            fileIds.map((value,key) => {
                console.log(commentData[key],value);
                imageData.push({id : value,comment:commentData[key]})

            })

            console.log(imageData);
        
            const response = await sendVendorTemplate(data.id, {images:finalData ,image_ids:imageData} )
                const { message: msg} = response
           
                message.success(msg);
              
        }
        catch (error) {
            console.log(error);
            throw new Response('Unable to upload files', { status: 500 })
        }


    }

    return (

        <div>

            <div className={styles.tabContainer} >

                <h3>{heading}</h3>
                <p>Product Link from vendor</p>
                <Input value={data.product.product_link ?? ''} disabled />
            </div>

            <br />

            <div>
                {!!actionData && actionData instanceof ActionError && <Message type={MessageTypes.ERROR} message={actionData.message} timeOut={3} />}
                {actionData === true && <Message type={MessageTypes.SUCCESS} message="File Uploaded Successfully" timeOut={3} />}
                <AddFileUpload ref={{fileUploaderRef ,commentData}}  />
                <i>Supported formats : PDF ,ESP,PSD,AI,TIFF,SVG</i>




            </div>


            <div className={styles.sendButton}>
                <Button icon={<SendOutlined />} onClick={handleFilesUpload}>Send to Customer</Button>
            </div>

        </div>
    )



}

OrderManagerStudio.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    imageIds: PropTypes.arrayOf(PropTypes.object).isRequired,
    heading: PropTypes.string
}

export default OrderManagerStudio