import React from 'react'
import PropTypes from 'prop-types';
import { FormContext } from './form-context.js';

function FormProvider(props) {
  const { children, validationRules } = props;

  return <FormContext.Provider value={validationRules}>{children}</FormContext.Provider>;
}

FormProvider.propTypes = {
  children: PropTypes.node,
	validationRules: PropTypes.object.isRequired,
}

export default FormProvider
