import React from 'react'
import PropTypes from 'prop-types'
// import { Link } from "react-router-dom";
import Sider from 'antd/lib/layout/Sider';
import { Menu, Avatar } from 'antd';
import { Link } from 'react-router-dom';
import './sidebar.scss'
import { UserOutlined, LeftCircleFilled, RightCircleFilled } from "@ant-design/icons";
import { useApp } from '../../providers/app/app-context.js';
import { useAuth } from '../../providers/auth';
import { useLocation } from 'react-router-dom'


const getMenuItems = (items, parent = '') => {
  if (!items || items.filter((item) => item.showOnMenu).length == 0) return undefined

  return items.filter((item) => item.showOnMenu).map((item) => {
    const { url, nolink = false, label, icon = undefined, children = null } = item
    return {
      label: nolink ? label : <Link to={url ?? parent} className='nav-text'>{label}</Link>,
      key: url,
      icon: icon,
      children: getMenuItems(children, url)
    }
  })
}

export default function Sidebar(props) {
  const { routes } = props;
  const { theme, sidebarCollapsed, actions } = useApp()
  const location = useLocation();
  const { user } = useAuth()

  // const handleLogout = () => {
  //   alert('you are logged out');
  // }

  return (
    <Sider theme={theme} trigger={null} style={{ 'height': '700px' }} collapsible collapsed={sidebarCollapsed} >
      <div className="logo-wraper"></div>
      <div className="sidebar-toggle" onClick={actions.toggleSidebarCollapsed}>
        {sidebarCollapsed ? <RightCircleFilled style={{ fontSize: '25px', cursor: 'pointer', marginLeft: '2.7em', 'color': "#23B27C" }} /> : <LeftCircleFilled style={{ fontSize: '25px', cursor: 'pointer', marginLeft: '7.5em', 'color': "#23B27C" }} />}
      </div>
      <></>
      <div className="user-info">
        <div className="text">
          <Avatar size={80} icon={<UserOutlined />} />
          <h4><b>{user.name}</b></h4>
          <p>{user.role}</p>
        </div>

      </div>
      <Menu theme={theme} mode="inline" items={getMenuItems(routes)} defaultSelectedKeys={[location]} />
      {/* <div className="toggle-theme">
        <Switch checkedChildren="☀️" unCheckedChildren="🌕" checked={theme !== 'dark'} onChange={actions.toggleTheme} size="large" />
      </div> */}
      {/* <ul>
        {routes.filter((item) => item.showOnMenu).map((item) => (
          <li key={item.url} className="nav-item">
            <Link to={item.url}>
              <span>🎁</span>
              <span>{item.label}</span>
            </Link>
          </li>
        ))}
        <li className="nav-item" onClick={handleLogout}>
          <span>🚪</span>
          <span>Logout</span>
        </li>
      </ul> */}


      <a href="https://3.basecamp.com/3991271/buckets/28242405/messages/6365066698" target='blank'> <p style={{
        fontSize: '11px',
        fontWeight: '400',
        lineHeight: "11px",
        letterSpacing: '0.03em',
        textAlign: 'center',
        color: '#8F8F8F',
        marginTop: '20px'
      }
      }>Version {process.env.APP_VERSION} 1.1.0</p></a>
    </Sider>
  )
}


Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
}
