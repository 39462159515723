import styles from '../../../components/data/quotation/quotation-item.module.scss';
import React from "react"
import { Button, message } from 'antd'
import { CommentForClient, CommentForVendor } from '../index'
import { changeStatus } from '../../../api/order';
import PropTypes from 'prop-types'


const ReadyForProduction = ({ data }) => {
    const handleOnHold = async () => {



        try {
            const { message: msg} = await changeStatus(data.id, 28, [])
            message.success(msg)

        }
        catch (e) {
            message.error(e.message)
            throw e
        }


    }

    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'textAlign': 'start' }}>Task For You</h3>
            <div className={styles.message}>
                <ul>
                    <li> Customer has approved the printable final proof, vendor has been notified to start the production
                        <span style={{ "float": 'right' }}><Button onClick={handleOnHold}>On Hold</Button></span>
                    </li>

                </ul>
            </div>
        </div>
        <br />
        <br />

        <CommentForClient comment={data.client_comment} />
        <CommentForVendor comment={data.vendor_comment} />


    </div>
}

ReadyForProduction.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    // logs: PropTypes.arrayOf(PropTypes.object).isRequired,
    // changeState: PropTypes.func
}


export default ReadyForProduction;