import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { Button } from 'antd';
import React from "react";
import { generatePath, useNavigate } from 'react-router-dom';
import { CommentForClient, CommentForVendor } from '../index';
import PropTypes from 'prop-types'



const ArtWorkReady = ({ data }) => {

    const navigate = useNavigate()
    const  handleRequestTemplate = async ()=>{
        navigate(
            generatePath('/send-ready-artwork/:id', { id: data.id }),
            
            { state: data }
        
        )

       

	

    }
    return <div>
    <div className={styles.stepBox}>
        <h3 style={{ 'text-align': 'start' }}>Task For You</h3>
        <div className={styles.message}>
            <ul>
                <li> Artwork is ready, please send it to vendor
                    <span style={{ "float": 'right' }}><Button onClick={handleRequestTemplate}>View & Send Artwork</Button></span>
                </li>

            </ul>
        </div>
    </div>
    <br />
    <br />

    <CommentForClient comment={data.client_comment} />
    <CommentForVendor comment={data.vendor_comment} />


</div>
}
ArtWorkReady.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default ArtWorkReady ;