import React from 'react';
import PropTypes from 'prop-types'
import { ValidationForm as Form } from '../';
import {
	Input,
	Select,
} from 'antd';
import { LanguageSelect, PhoneInput } from '../../inputs'
import styles from './customer-contact.module.scss'
import Roles from '../../../library/dynamics/roles'
import Genders from '../../../library/dynamics/genders'

const CustomerContact = ({ children, index, getValidationStatus, ...otherProps }) => {
	const [form] = Form.useForm()
	const validationRules = {
		first_name: [
			{
				required: true
			}
		],
		last_name: [
			{
				required: true
			}
		],
		email: [
			{
				required: true
			},
			{
				type: 'email'
			}
		],
		gender_id: [
			{
				required: true
			}
		],
		role_id: [
			{
				required: true
			}
		],
		language_id: [
			{
				required: true
			}
		],
	}
	
	return (
		<Form  form={form} name={`contact-${index}`} layout="vertical" className={styles.FormWrapper} onValidationStatusChange={getValidationStatus} validationRules={validationRules} {...otherProps}>
			<Form.Item name="first_name" label="First Name" >
				<Input placeholder = "First Name"/>
			</Form.Item>
			<Form.Item name="last_name" label="Last Name" >
				<Input placeholder = "Last Name"  />
			</Form.Item>
			<br />
			<Form.Item name="email" label="Email Address" >
				<Input type="email"  placeholder = "Email"/>
			</Form.Item>
			<Form.Item label="Phone Number">
				<PhoneInput valuePropName={{ code: 'phone_country_code', number: 'phone_number' }} placeholder = "Telephone No." />
			</Form.Item>
			<Form.Item label="Mobile Number">
				<PhoneInput valuePropName={{ code: 'mobile_country_code', number: 'mobile_number' }} placeholder = "Mobile No."/>
			</Form.Item>
			
			<Form.Item name='gender_id' label="Gender" >
				<Select options={Genders} fieldNames={{ value: 'id', label: 'name' }} placeholder = "Gender"/>
			</Form.Item>
			<Form.Item name='role_id' label="Role in Company" >
				<Select options={Roles} fieldNames={{ value: 'id', label: 'name' }} placeholder = "Role"/>
			</Form.Item>
			<Form.Item name='language_id' label="Language" >
				<LanguageSelect />
			</Form.Item>
			{children}
		</Form>
	)
}

CustomerContact.propTypes = {
	children: PropTypes.node,
	index: PropTypes.string.isRequired,
	getValidationStatus: PropTypes.func,
}

export default CustomerContact