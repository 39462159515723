import React from "react";
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { CommentForClient, CommentForVendor } from '../index';
import PropTypes from 'prop-types'
const ProofRequested = ({ data }) => {

    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'text-align': 'start' }}>Task For You</h3>
            <div className={styles.message}>
                <ul>
                    <li>Proof has been requested
                    </li>

                </ul>
            </div>
        </div>
        <br />
        <br />
        <CommentForClient comment={data.client_comment} />
        <CommentForVendor comment={data.vendor_comment} />
    </div>
}


ProofRequested.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    // logs: PropTypes.arrayOf(PropTypes.object).isRequired,
    // changeState: PropTypes.func
}
export default ProofRequested;