import { Radio, Rate } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import SmallSection from '../../design/small-section.jsx';
import styles from './project-item-vendor-prices.module.scss';

const formatePrice = (price) => {
    return (price ?? 0).toLocaleString('nl-Nl', {
        style: 'currency',
        currency: 'EUR',
        maximumFractionDigits: 2
    });
}
const getTotal = ({ purchase_price = 0, finisher_price = 0, handling_charge = 0, shipping_charge = 0, margin = 0 }) => {
    return (purchase_price + finisher_price + handling_charge + shipping_charge) * (1 + margin / 100)
}

const ProjectItemVendorPrices = ({ data, onVendorChange }) => {
    return <div key={data.id} className={styles.vendor}>
        <div className={styles.vendorHeader}>
            <div className={styles.vendorSelect}>
                {onVendorChange && <Radio checked={data.isSelected} onChange={() => onVendorChange(data.id)} />}
            </div>
            <div className="">
                <div className={styles.vendorName}>
                    {data.company}
                </div>
                <div className={styles.vendorRating}>
                    <Rate defaultValue={data?.rating} disabled />
                </div>
            </div>
        </div>
        <div className={styles.vendorPrices}>
            <div className={styles.quantityPricesHead}>
                <div>Quantity</div>
                <div>Purchase Price</div>
                <div>Finisher&apos;s Price</div>
                <div>Handling Charges</div>
                <div>Shipping Charges</div>
                <div>Margin</div>
                <div>Sales Price </div>
                <div>Total Price</div>
            </div>
            {data.prices.map((prices) => (
                <SmallSection key={prices.quantity} colorLight="#fff2e9" colorDark="#fe7b1c">
                    <div className={styles.vendorQuantityPrice}>
                        <div>{prices.quantity}</div>
                        <div>{formatePrice(prices.purchase_price)}</div>
                        <div>{formatePrice(prices.finisher_price)}</div>
                        <div>{formatePrice(prices.handling_charge)}</div>
                        <div>{formatePrice(prices.shipping_charge)}</div>
                        <div>{formatePrice(prices.margin)}</div>
                        <div>{formatePrice(getTotal(prices) / prices.quantity)}</div>
                        <div>{formatePrice(getTotal(prices))}</div>
                    </div>
                </SmallSection>
            ))}
        </div>
    </div>;
}

ProjectItemVendorPrices.propTypes = {
    data: PropTypes.object.isRequired,
    onVendorChange: PropTypes.func,
}

export default ProjectItemVendorPrices