import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { ProjectItemVendorPrices } from '../';
import styles from './item-overview.module.scss';
import './item-overview.scss';

const ProjectItemOverview = ({ children, index, data, onEdit, onDelete }) => {
    const formatDate = (date) => {
        const d = new Date(date)
        return d.toLocaleDateString('nl-NL')
    }

    const itemData = useMemo(() => {
        const res = { ...data }
        res.selected_vendors = res.selected_vendors.map(v => ({
            ...v,
            ['prices']: data.vendors_prices[v.id]
        }))
        return res
    }, [data])

    return (
        <div className={`project-item-overview ${styles.wrapper}`}>
            <div className={styles.header}>
                <span className="project-item-index" onClick={onEdit ?? undefined}>
                    #{index + 1}
                </span>
                <span className="project-item-name" onClick={onEdit ?? undefined}>
                    {itemData.product.name}
                </span>
                <span className="project-item-actions">
                    <span className="project-item-actions--action project-item-date">
                        {formatDate(itemData.createdAt)}
                    </span>
                    {typeof onDelete !== 'undefined' && <span className="project-item-actions--action project-item-delete" onClick={onDelete}>
                        <DeleteOutlined />
                    </span>}
                    {typeof onEdit !== 'undefined' && <span className="project-item-actions--action project-item-edit" onClick={onEdit}>
                        <EditOutlined />
                    </span>}
                </span>
            </div>
            <div className={styles.body}>
                {itemData.selected_vendors.map(vendor => <ProjectItemVendorPrices key={vendor.id} data={vendor} />)}
            </div>
            {children}
        </div>
    )
}

ProjectItemOverview.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    data: PropTypes.shape({
        id: PropTypes.number.isRequired,
        selected_vendors: PropTypes.array,
        vendors_prices: PropTypes.object,
        quantities: PropTypes.array,
        product: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired,
        }).isRequired,
    }),
    selectable: PropTypes.bool,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func,
}

export default ProjectItemOverview