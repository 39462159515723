import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Input, Row, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { fetchTemplateDetails, proofRequested } from '../../api/order';
import style from '../../components/general.module.scss';
import { Page } from '../../components/page';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { Server } from "../../library/constants/server";
import { Image } from '../../ui';
const { Panel } = Collapse



const { TextArea } = Input
const SendReadyTemplate = ({ title }) => {
    const { templateDetails } = useLoaderData({});
    let imageData = []

    const selectedTab = 1 ;

    let { id } = useParams();

        const SendtoVendor = async () => {
            let finalData = []
                imageData.map((values) => {
                    if (values?.url) {
                        finalData.push(values.url)
                    }
        
                })
        
        
        
                try {
                    const response = await proofRequested(id, { images: finalData, comment: 'Daily soap' , image_ids:[] })
                    const { message: msg} = response
                    console.log(response);
                    message.success(msg)
        
                }
                catch (e) {
                    message.error(e.message)
                    //throw e
                }
        
        
            }

    const navigate = useNavigate()
    return (

        <Page title={title} header={<Button type="primary" onClick={() => { navigate(generateRouteUrl('order-manager-list')) }}>Back</Button>} >
            <Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header='Sample Artwork shared by studio' key="1">
                   {  imageData=[]}


                    {templateDetails.studio_artwork.map((sample,index) => {
                       
                        console.log(sample);
                        return (<div className={style.itemDetail} key={index}>

                            

                            <Checkbox onChange={(e)=>{

                            
                                if (e.target.checked==true) {
                                    imageData[index]={url:sample.url,comment:''}
                                }
                                else {
                                   delete imageData[index]
                                }


                                
                              

                                

                            
                                
                            }} disabled={selectedTab==2?true:false} />

                            <Image src={Server.BaseUrl + '/api/v1/' + sample.url} height={100} width={90} className={style.imageBox} />
                            <h4>{sample.name}</h4>
                            <div style={{ 'width': '25%' }}>
                                <p><b>Commnet from vendor : TODO TODOTODOTODOTODOTODOTODO</b></p>
                            </div>
                            <Row>
                               <a href={Server.BaseUrl + '/api/v1/' + sample.url} download="sample.png"> <Button  icon={<DownloadOutlined />} >Download</Button> </a>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} target="blank" ><Button icon={<EyeOutlined />}>View</Button> </a>
                            </Row>


                        </div>)
                    })


                    }
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <hr />

                    <br />
                    <br />
                    <br />

                    <div className={style.tabBody}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam vitae odio mollitia reiciendis, quibusdam vel incidunt aut ut consequatur velit deserunt dicta non consequuntur possimus sequi aliquid. Ad, nesciunt expedita!
                                    <br />

                                    <div>
                                        <h3 style={{ 'text-align': 'start' }}>Add a comment</h3>



                                        <TextArea placeholder="Your message Here..." allowClear />


                                    </div>
                                    <br />

                                    <Button onClick={SendtoVendor}>Send to Vendor</Button>
                                </div>






                </Panel>
            </Collapse>

         


        </Page>
    )

}

SendReadyTemplate.Loader = async ({ params }) => {


    if (isNaN(params.id)) {
        throw new Error('Invalid Orrder ID')
    }
    const templateDetails = await fetchTemplateDetails(params.id)
    if (!templateDetails) {
        throw new Error('Invalid Quoation')
    }

    console.log(templateDetails);

    // setCurrent(checkStatus(quotationDetails))






    // const basicquotationDetails = (({
    // 	vendorData, item, specifications
    // }) => ({
    // 	vendorData, item, specifications
    // }))(quotationDetails)



    return { templateDetails }
}


SendReadyTemplate.propTypes = {
    title: PropTypes.string,
}
export default SendReadyTemplate