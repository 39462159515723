import React from 'react'
import PropTypes from 'prop-types'
import { Page } from '../../components/page';

function Settings(props) {
  const { title } = props;
  return (
    <Page className='settings' title={title}>
      This is settings page
    </Page>
  )
}

Settings.propTypes = {
  title: PropTypes.string,
}

export default Settings
