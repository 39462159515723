// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/order/item';
// const ENDPOINT_SINGLE = '/order/item/:id';
// const ENDPOINT_SINGLE_ACTION = '/project/:id/:action';

export const getAllOrders = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}
export const getOrderDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL+'/:id'+'/details', {
        id
    })).then(({ data }) => data)
}

export const getActivityLogs = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL+'/:id'+'/activity-log', {
        id
    })).then(({ data }) => data)
}


export const fetchTemplateDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL+'/:id'+'/document-details', {
        id
    })).then(({ data }) => data)
}

export const fetchArtworkDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL+'/:id'+'/document-details', {
        id
    })).then(({ data }) => data)
}

export const fetchProofDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL+'/:id'+'/document-details', {
        id
    })).then(({ data }) => data)
}

export const sendOrder = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/order-send', {
        id
    }) ,payload )
}

export const sendArtworktoVendor = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/artwork-vendor', {
        id
    }) ,payload )
}


export const sendProoftoClient = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/send-proof-to-client', {
        id
    }) ,payload )
}


export const waitngForFiles = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/waiting-for-files', {
        id
    }) ,payload )
}

export const templateRequest = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/template-request-to-vendor', {
        id
    }) ,payload )
}

export const proofRequested = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/proof-requested', {
        id
    }) ,payload )
}

export const sendTemplatetoStudio = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/send-template-to-studio', {
        id
    }) ,payload )
}

export const sendClientArtworktoStudio = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/send-client-artwork-to-studio', {
        id
    }) ,payload )
}

export const sendVendorArtworktoStudio = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/send-proof-to-studio', {
        id
    }) ,payload )
}


export const sendOrderShippingDetails = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/shipping/:id', {
        id
    }) ,payload )
}


export const sendOrderDispatchDate = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/expected-dispatch-date/:id', {
        id
    }) ,payload )
}

export const confirmOrderDelivery = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/shipping/:id/confirm', {
        id
    }) ,payload )
}


export const changeStatus = (id,status_id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id/change-status/:status_id', {
        id , status_id
    }) ,payload )
}



export const sendVendorTemplate = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/send-template-to-client', {
        id
    }) ,payload )
}