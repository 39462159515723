import { Collapse, Input } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
const { Panel } = Collapse;


 const CommentsForClient = ({ comment }) => {
    return <Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
        <Panel header='Comment From Client' key="1">
            <Input disabled value={comment}></Input>


        </Panel>
    </Collapse>


}
CommentsForClient.propTypes = {
    comment: PropTypes.string,
 
}

export default CommentsForClient