import { Layout, Spin } from "antd";
import PropTypes from "prop-types";
import React from "react";
import { useNavigation } from 'react-router-dom';
import { Header } from "../header";
const { Content } = Layout;

function Page({ title, className, children, header }) {
  const navigation = useNavigation()

  return (
    <>
      <Header title={title} />
      <Content className={`page ${className}`}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '1.5rem', flexDirection: 'row-reverse' }}>
          {header}
        </div>
        {navigation.state === 'loading' ? <Spin /> : children}
      </Content>
    </>
  );
}

Page.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  header: PropTypes.node,
};

export default Page;
