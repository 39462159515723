import styles from '../../../components/data/quotation/quotation-item.module.scss';
import PropTypes from 'prop-types';

import { Button, message } from 'antd';
import React from "react";
import { templateRequest } from '../../../api/order';
import { ActivityLogs, CommentForClient, CommentForVendor } from '../index';
// import { PopUpMessage } from '../../../components/message';


const WaitingforFile = ({ data , logs }) => {

    const  handleRequestTemplate = async ()=>{

       

		try {
			const { message: msg } = await templateRequest(data.id , [])
            message.success(msg)
			
			// navigate(generatePath('/customers/:id', {
			// 	id: data.id
			// }))
		}
		catch (e) {
            message.error(e.message)
            throw e
        }

    }
    return <div>
        <div className={styles.stepBox}>
           

            <div>
                <h3 style={{ 'textAlign': 'start' }}>Task to do with Vendor</h3>

                {data.client_status == 1  ?
                    <div className={styles.message}>
                        <ul>
                            <li>Request sample template from vendor  <span style={{ "float": 'right' }}><Button onClick={handleRequestTemplate}>Request Template</Button></span></li>

                        </ul>
                    </div> : <div></div>}





            </div>




        </div>

        {/* <PopUpMessage isOpen={open} heading= {'Order Sent to Vendor'} onContinue={handleOk} data= {message}/> */}
        <br />
        <br />

        <CommentForClient comment={data.client_comment} />
        <CommentForVendor comment={data.vendor_comment} />
        <ActivityLogs logs ={logs}/>


    </div>


}


WaitingforFile.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    logs: PropTypes.arrayOf(PropTypes.object).isRequired
    //  changeState: PropTypes.func
}
export default WaitingforFile;