// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { axiosInstance } from '../common';
import { generatePath } from 'react-router-dom';
import { pick } from 'lodash-es'


// Define all api endpoint urls at the top
const ENDPOINT = '/address/:id';


// Define all api functions here
export const updateAddress = (id, payload) => {
    payload = pick(payload, ['street', 'street_number', 'zipcode', 'city', 'country_id', 'context', 'context_id', 'county']);
    return axiosInstance.patch(generatePath(ENDPOINT, {
        id
    }), payload)
}

export const deleteAddress = (id) => {
    return axiosInstance.delete(generatePath(ENDPOINT, {
        id
    }))
}