import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { getContact, updateContact } from '../../api/contact/contact[id].js'
import { Page } from '../../components/page'
import PropTypes from 'prop-types'
import {generatePath,useNavigate} from 'react-router-dom';
import { Collapse, Form, Button, message } from 'antd';
import { Contact } from '../../components/form'
const { Panel } = Collapse
import { createProject } from '../../api/project';
//import Customer from 'components/form/customer/customer.jsx'

const ContactDetails = ({ title }) => {
	const { id: contactId } = useParams()
	const [customerContactDetails, setCustomerContactDetails] = useState({})
	const [isLoading, setIsLoading] = useState(true)
	const [isSaving, setIsSaving] = useState(false)
	const navigate = useNavigate()
	useEffect(() => {
		setIsLoading(true)
		getContact(contactId)
			.then(({ data }) => {
				setCustomerContactDetails(data)
				setIsLoading(false)
			})
			.finally(() => {
				setIsLoading(false)
			})
	}, [])

	const makeProject = async (payload) => {
		try {
			const { message: msg, data } = await createProject({ ...payload })
			message.success(msg)
			navigate(generatePath('/projects/:id', {
				id: data.id
			}))
		}
		catch (e) {
			message.error(e.message)
			throw e
		}
	}
	const handleCustomerDetailContactsSubmit = (data) => {
		setIsSaving(true)
		updateContact(contactId, data)
			.then(({ data, message: msg }) => {
				message.success(msg)
				setCustomerContactDetails(data)
			})
			.catch(e => {
				message.error(e.message)
				throw e
			})
			.finally(() => {
				setIsSaving(false)
			})
	}

	return (
		<Page className='customers-detail' title={title}>
			{customerContactDetails && !isLoading &&
				<Collapse collapsible="icon" defaultActiveKey={['1']}>
					<Panel header='Company Details' key="1" extra={
						<Button
						onClick={() => {
							console.log(customerContactDetails);
							makeProject({
								'source_id': 'email',
								'account_manager_id': '1',
								'customer_id': customerContactDetails.customer_id
							})
						}}
						style={{ 'margin-right': '10px' }}>Create Project</Button>
					}>
						<Contact id={Number(contactId)} initialValues={customerContactDetails} onFinish={handleCustomerDetailContactsSubmit}>
							<Form.Item>
								<Button type="primary" htmlType="submit" loading={isSaving}>Save</Button>
							</Form.Item>
						</Contact>
					</Panel>
				</Collapse>	
			}
		</Page>
	)
}

ContactDetails.propTypes = {
	title: PropTypes.string
}

export default ContactDetails