// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

import { generatePath } from 'react-router-dom';
import { axiosInstance, removeNill } from '../common';

// Define all api endpoint urls at the top
const ITEM_CREATE = '/project/:projectId/item';
const ITEM_SINGLE = '/item/:itemId';
const ITEM_SINGLE_ACTION = '/item/:itemId/:action';
const CUSTOM_SPECIFICATION_SINGLE = '/item/custom-specification/:specId';

export const createProjectItem = (projectId, payload) => {
    return axiosInstance.post(generatePath(ITEM_CREATE, {
        projectId
    }), payload)
}

export const getProjectItem = (itemId) => {
    return axiosInstance.get(generatePath(ITEM_SINGLE, {
        itemId
    })).then(({ data }) => data)
}

export const updateProjectItem = (itemId, payload) => {
    return axiosInstance.patch(generatePath(ITEM_SINGLE, {
        itemId
    }), removeNill(payload)).then(({ data }) => data)
}

export const updateProjectItemSpecifications = (itemId, payload) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'specification'
    }), payload).then(({ data }) => data)

}


export const addItemCustomSpecification = (itemId, payload) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'custom-specification'
    }), payload)
}

export const updateItemCustomSpecification = (specId, payload) => {
    return axiosInstance.patch(generatePath(CUSTOM_SPECIFICATION_SINGLE, {
        specId,
    }), payload)
}

export const deleteItemCustomSpecification = (specId) => {
    return axiosInstance.delete(generatePath(CUSTOM_SPECIFICATION_SINGLE, {
        specId,
    }))
}

export const sortItemCustomSpecifications = (itemId, payload) => {
    return axiosInstance.post(generatePath(ITEM_SINGLE_ACTION, {
        itemId,
        action: 'custom-specification/sort'
    }), payload)
}


// export const updateProjectDetails = (id, payload) => {
//     return axiosInstance.patch(generatePath(ITEM_SINGLE, {
//         id
//     }), payload)
// }