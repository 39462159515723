import React, { useState } from 'react';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { Upload, message } from 'antd'
import PropTypes from 'prop-types'
import styles from './ImageUpload.module.scss'

const getBase64 = (img, callback) => {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
};
const beforeUpload = (file) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt1M = file.size / 1024 / 1024 < 1;
    if (!isLt1M) {
        message.error('Image must smaller than 1MB!');
    }
    return isJpgOrPng && isLt1M;
};

const UploadButton = ({loading}) => (
    <div>
        {loading ? <LoadingOutlined /> : <PlusOutlined />}
        <div
            style={{
                marginTop: 8,
            }}
        >
            Upload
        </div>
    </div>
);

UploadButton.propTypes = {
    loading: PropTypes.bool
}

const ImageUpload = ({ value, }) => {
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState(value);

    const handleFileChange = (info) => {
        if (info.file.status === 'uploading') {
            setLoading(true);
            return;
        }
        if (info.file.status === 'done') {
            // Get this url from response in real world.
            getBase64(info.file.originFileObj, (url) => {
                setLoading(false);
                setImageUrl(url);
            });
        }
    };

    return (
        <Upload
            name="avatar"
            listType="picture-card"
            className={styles.ImageUploadWrapper}
            showUploadList={false}
            action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
            beforeUpload={beforeUpload}
            onChange={handleFileChange}
        >
            {imageUrl ?  <img src={imageUrl} /> : <UploadButton loading={loading} /> }
        </Upload>
    );
}

ImageUpload.propTypes = {
    value: PropTypes.string
}

export default ImageUpload;