import {
	Input,
	Select
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ValidationForm as Form } from '../';

import Genders from '../../../library/dynamics/genders';
import { LanguageSelect, PhoneInput } from '../../inputs';
import styles from './vendor-contact.module.scss';



const VendorContact = ({ children, index, getValidationStatus, ...otherProps }) => {



	const [form] = Form.useForm()



	const items = [
		{
			key: '1',
			label: 'Enquiry',
			value: 'enquiry'
		},
		{
			key: '2',
			label: 'Order',
			value: 'order'
		},
	]
	const validationRules = {
		first_name: [
			{
				required: true
			}
		],
		last_name: [
			{
				required: true
			}
		],
		email: [
			{
				required: true
			},
			{
				type: 'email'
			}
		],
		gender_id: [
			{
				required: true
			}
		],
		role_id: [
			{
				required: true
			}
		],
		language_id: [
			{
				required: true
			}
		],
	}

	// return (
	// 	<Form form={form} name={`contact-${index}`} layout="vertical" className={styles.FormWrapper} onValidationStatusChange={getValidationStatus} validationRules={validationRules} {...otherProps}>
	// 		<Form.Item name='role_id'
	// 		>

	// 			<Select options={items} bordered={true}
	// 			styles={{'backgroud-color':'red'}}
	// 			fieldNames={{ value: 'value', label: 'label' }} className={styles.RoleButton} />

	// 			{/* <Dropdown menu={{ items,onClick }} placement="bottomLeft">


	// 				<Button className={styles.RoleButton} onClick={(e) => e.preventDefault() }>{Vrole}</Button>
	// 			</Dropdown> */}

	// 		</Form.Item>

	// 		<Form.Item name="first_name" label="First Name" >
	// 			<Input />
	// 		</Form.Item>
	// 		<Form.Item name="last_name" label="Last Name" >
	// 			<Input />
	// 		</Form.Item>
	// 		<Form.Item name="email" label="Email" >
	// 			<Input type="email" />
	// 		</Form.Item>
	// 		<Form.Item label="Phone No.">
	// 			<PhoneInput valuePropName={{ code: 'phone_country_code', number: 'phone_number' }} />
	// 		</Form.Item>
	// 		<Form.Item label="Mobile No.">
	// 			<PhoneInput valuePropName={{ code: 'mobile_country_code', number: 'mobile_number' }} />
	// 		</Form.Item>
	// 		<Form.Item name='gender_id' label="Gender" >
	// 			<Select options={Genders} fieldNames={{ value: 'id', label: 'name' }} />
	// 		</Form.Item>

	// 		<Form.Item name='language_id' label="Language" >
	// 			<LanguageSelect />
	// 		</Form.Item>
	// 		{children}
	// 	</Form>


	return (
		<Form form={form} name={`contact-${index}`} layout="vertical" className={styles.FormWrapper} onValidationStatusChange={getValidationStatus} validationRules={validationRules} {...otherProps}>

			<Form.Item name='role_id'
			>

				<Select options={items} bordered={true}
					styles={{ 'backgroud-color': 'red' }}
					fieldNames={{ value: 'value', label: 'label' }} className={styles.RoleButton} />

				{/* <Dropdown menu={{ items,onClick }} placement="bottomLeft">


					<Button className={styles.RoleButton} onClick={(e) => e.preventDefault() }>{Vrole}</Button>
				</Dropdown> */}

			</Form.Item>
			<Form.Item name="first_name" label="First Name" >
				<Input placeholder="First Name" />
			</Form.Item>
			<Form.Item name="last_name" label="Last Name" >
				<Input placeholder="Last Name" />
			</Form.Item>
			<br />
			<Form.Item name="email" label="Email Address" >
				<Input type="email" placeholder="Email" />
			</Form.Item>
			<Form.Item label="Phone Number">
				<PhoneInput valuePropName={{ code: 'phone_country_code', number: 'phone_number' }} placeholder="Telephone No." />
			</Form.Item>
			<Form.Item label="Mobile Number">
				<PhoneInput valuePropName={{ code: 'mobile_country_code', number: 'mobile_number' }} placeholder="Mobile No." />
			</Form.Item>

			<Form.Item name='gender_id' label="Gender" >
				<Select options={Genders} fieldNames={{ value: 'id', label: 'name' }} placeholder="Gender" />
			</Form.Item>

			<Form.Item name='language_id' label="Language" >
				<LanguageSelect />
			</Form.Item>
			{children}
		</Form>
	)

}

VendorContact.propTypes = {
	children: PropTypes.node,
	index: PropTypes.string.isRequired,
	getValidationStatus: PropTypes.func,
}

export default VendorContact