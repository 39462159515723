import { GlobalOutlined, MailOutlined, MobileOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import React from 'react';
import { Server } from '../server';
// import styles from '../../../styles/page.scss'

const source = {
    'align-items': "baseline",
    'display': "flex",
    'flex-flow': "row",
    'gap': '17px',
}

function getStatusIcon(status_id = 'eamil', projectID) {
    if (status_id == 'email') {
        return <div style={source}>
            <h4>{projectID}</h4>
            <MailOutlined style={{ fontSize: '20px', color: '#EAB935' }} />
        </div>


    }
    if (status_id == 'phone') {
        return <div style={source}>
            <h4>{projectID}</h4>
            <MobileOutlined style={{ fontSize: '20px', color: '#4178D1' }} />
        </div>
    }
    else {
        return <div style={source}>
            <h4>{projectID}</h4>
            <GlobalOutlined style={{ fontSize: '20px', color: '#6E589D' }} />
        </div>

    }

}

export const FinanceProjectColumns = [
    {
        title: 'Action',
        key: 'operation',
        fixed: 'left',
        width: 100,
        render: () => <Checkbox></Checkbox>,
    },
    {
        title: 'Project Id',
        dataIndex: '',
        sorter: true,
        fixed: 'left',
        render: id => getStatusIcon(id.source_id, id.id)
    },
    {
        title: 'Status',
        dataIndex: 'status_name',
        //sorter: true,
        render: status => status?.name,
    },
    {
        title: 'Relation',
        dataIndex: 'customer',
        render: customer => customer?.company,
        //sorter: true,
    },
    {
        title: 'Notes from Sales',
        dataIndex: 'user',
        render: user => user?.name
        //sorter: true,
    },
    {
        title: 'Language',
        dataIndex: 'language',
        //sorter: true,
        render: language => language.name
    },
    {
        title: 'Country',
        dataIndex: ['customer', 'country'],
        ///sorter: true,
        render: (country) => {
            return <img src={`${Server.BaseUrl}/${country.flag_url}`} alt={country.name} title={country.name} />
        }
    },
    {
        title: 'Invoiced',
        //dataIndex: ['customer', 'country'],
        ///sorter: true,
        // render: (country) => {
        //     return <img src={`${Server.BaseUrl}/${country.flag_url}`} alt={country.name} title={country.name} />
        // }
    },
]