import axios from "axios";
import { isNil, omitBy } from 'lodash-es';
import { defaultHeaders } from "./default-headers";


// eslint-disable-next-line no-undef
const DEBUG = process.env.NODE_ENV === 'development';

const redirectToLogin = async () => {
  localStorage.removeItem('ACCESS_TOKEN');
  localStorage.removeItem('REFRESH_TOKEN');
  localStorage.removeItem('USER');

  window.location.href = '/';
}

const redirectTo404 = async () => {
  //window.location.href = '/404';
}


export const axiosInstance = axios.create({
  withCredentials: true,
  baseURL: `${process.env.REACT_APP_BASE_API_URL}/${process.env.REACT_APP_VERSION_V1}/`,
});

// Intercepting requests
axiosInstance.interceptors.request.use(
  (request) => {
    const headers = {
      ...defaultHeaders(),
      ...request?.headers
    };
    /* eslint-disable no-console */
    if (DEBUG)
      console.info(`%c ${request.method?.toUpperCase()} ===> ${request.url}`, 'background: #62687a; color: #fff');
    /* eslint-enable no-console */

    return { ...request, headers };
  },
  (error) => Promise.reject(error)
);


// Intercepting responses
axiosInstance.interceptors.response.use(
  (response) => {
    // do something here
    if (!response.data.status) {
      return Promise.reject(response.data)
    }
    // return new Promise(resolve => setTimeout(() => {
    //   resolve(response.data);
    // }, 2000))
    return response.data
  },
  async (error) => {
    console.log(error)
    const statusCode = error.response?.status || 500;

    if (DEBUG) console.error(statusCode, error.message);

    switch (statusCode) {
      case 401:
        return redirectToLogin();
      case 430: {
        // do something else, redirecting to login for the time being
        return redirectToLogin();
      }
      case 404: {
        return redirectTo404();
      }
      default:
        return Promise.reject(error);
    }
  }
)

export const axiosCancellable = (action) => {
  const controller = new AbortController();
  const cancelIntercept = axiosInstance.interceptors.request.use((config) => {
    config.signal = controller.signal
    return config
  },
    (error) => Promise.reject(error)
  )
  action().then(() => {
    axiosInstance.interceptors.request.eject(cancelIntercept)
  })
  return controller
}

export const removeNill = (obj) => {
  return omitBy(obj, isNil);
}