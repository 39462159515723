import { Button } from 'antd';
import React from "react";
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { CommentForClient, CommentForVendor } from '../index';
import PropTypes from 'prop-types'


const Delivered = ({ data }) => {
    const handleViewOrder = async () => {

        console.log('view order details');

    }

    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'text-align': 'start' }}>Task For You</h3>
            <div className={styles.message}>
                <ul>
                    <li> Order delivered on time on {data?.delivered_date ?? '24th August 2001'}
                        <span style={{ "float": 'right' }}><Button onClick={handleViewOrder}>View Order Details</Button></span>
                    </li>

                </ul>



            </div>

            <div className={styles.message}>
                <ul>
                    <li> How would you rate the expierience with vendor ?

                    </li>

                </ul>



            </div>
        </div>
        <br />
        <br />

        <CommentForClient comment={data.client_comment} />
        <CommentForVendor comment={data.vendor_comment} />


    </div>
}

Delivered.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Delivered;