import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import styles from './cms-prices-selectable.module.scss'

const CmsPricesSelectable = ({ children, prices, selectedQuantities = [], onSelectionChange }) => {
    const _selectedRef = useRef(selectedQuantities)

    useEffect(() => {
        _selectedRef.current = selectedQuantities
    }, [selectedQuantities])

    const handleItemClick = (quantity) => {
        const quantityAlreadyAdded = _selectedRef.current.findIndex(qty => qty === quantity)
        if (quantityAlreadyAdded > -1) {
            //    _selectedRef.current.splice(quantityAlreadyAdded, 1)
        }
        else {
            _selectedRef.current.push(quantity)
            _selectedRef.current.sort((a, b) => a - b)
        }
        if (onSelectionChange) {
            onSelectionChange(_selectedRef.current.map(String))
        }
    }
    return (
        <div className={styles.wrapper}>
            {Object.entries(prices).map(([qty, price]) => (
                <div className={`${styles.item} ${selectedQuantities.includes(Number(qty)) ? styles.active : ''}`} key={qty} onClick={() => handleItemClick(String(qty))} >
                    <span className={styles.quantity}>{qty}</span>
                    <span className={styles.price}>{price || 'NA'}</span>
                </div>
            ))}
            {children}
        </div>
    )
}

CmsPricesSelectable.propTypes = {
    children: PropTypes.node,
    prices: PropTypes.shape({

    }),
    onSelectionChange: PropTypes.func,
    selectedQuantities: PropTypes.arrayOf(Number)
}

export default CmsPricesSelectable