/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/studio/item';


export const getStudioList = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const getStudioDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL+'/:id', {
        id
    })).then(({ data }) => data)
}

export const sendStudioArtworktoclient = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/send-proof', {
        id
    }) ,payload )
}

export const sendStudioArtworktoVendor = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/send-artwork',{
        id
    }) ,payload )
}

export const sendTemplateFilestoClient = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+'/:id'+'/send-template', {
        id
    }) ,payload )
}

