import { CaretDownOutlined } from '@ant-design/icons'
import { Dropdown } from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import styles from './DropdownSelect.module.scss'

function DropdownSelect({ value, onChange, options }) {
    const availableOptions = useMemo(() => options.filter(o => o !== value), [value])

    if (!options.length) {
        return (
            <span className={styles.selectedValue}>
                <span className={styles.selectedText}>{value}</span>
            </span>
        )
    }

    return (
        <Dropdown menu={{
            items: availableOptions.map(i => ({
                key: i,
                label: i
            })),
            onClick: ({ key }) => {
                if (typeof onChange === 'function') {
                    onChange(key)
                }
            }
        }} trigger={['click']}>
            <a onClick={(e) => {
                
            
                e.preventDefault()}} className={value=='Authority'?styles.selectedValueAuth : styles.selectedValue}>
                <span className={styles.selectedText}>{value}</span>
                <CaretDownOutlined />
            </a>
        </Dropdown>
    );
}

DropdownSelect.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    options: PropTypes.arrayOf(PropTypes.string)
}

export default DropdownSelect;