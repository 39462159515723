
import { SendOutlined } from '@ant-design/icons';
import { Button, Form, Input, Row, message } from 'antd';
import PropTypes from 'prop-types';
import React from "react";
import { generatePath, useNavigate } from 'react-router-dom';
import { changeStatus, sendOrder } from "../../../api/order";
import styles from '../../../components/data/OrdeManager/order-item.module.scss';
import { DatePicker } from "../../../components/inputs";
import { ActivityLogs, CommentForClient, CommentForVendor } from '../index';
const { TextArea } = Input;

const NewItem = ({ data, logs, changeState }) => {
    const navigate = useNavigate()
    const handleRequestTemplate = async () => {
        navigate(
            generatePath('/send-direct-artwork/:id', { id: data.id }),

            { state: data }

        )





    }

    const handleWishtoCancel = async () => {
        try {
            const { message: msg } = await changeStatus(data.id, 2, [])
            message.success(msg)
            navigate(
                generatePath('/order-manager-list'),
            )
        }
        catch (e) {
            message.error(e.message)
            throw e
        }

    }



    const [form] = Form.useForm();
    const handleOrderSend = async (orderData) => {



        try {
            const { message: msg} = await sendOrder(data.id, orderData)
            message.success(msg)
            changeState(2)
            // navigate(generatePath('/customers/:id', {
            // 	id: data.id
            // }))
        }
        catch (e) {
            message.error(e.message)
            throw e
        }

    }



    return (
        <div>
            <div className={styles.stepBox}>
                <h3 style={{ 'text-align': 'start' }}>Task For You</h3>

                <div className={styles.message}>
                    <ul>
                        <li>Verify all the details</li>
                    </ul>
                </div>
                <br />
                <div className={styles.message}>
                    <h4>Item Details</h4>

                    <div className={styles.wrapper}>

                        <div className={styles.right}>

                            <div className={styles.specifications}>
                                {data.specification.map(({ name, value }) => (
                                    <React.Fragment key={name}>
                                        <div className={styles.name}>{name}</div>
                                        <div className={styles.value}>{value}</div>
                                    </React.Fragment>
                                ))}
                            </div>

                            {/* <QuotationItemQuantities prices={item.prices} selectedId={selectedId} onSelectionChange={(selected) => {
                    onSelectionChange({ itemId: item.id, priceId: selected })
                }} /> */}
                        </div>
                    </div>
                </div>
                <br />

                <hr />

                <br />
                <Form
                    form={form}
                    // onFinish={onFinish}
                    // onFinishFailed={onFinishFailed}
                    autoComplete="off"
                >
                    <Row className={styles.datesrow}>

                        <Form.Item
                            name={"deadline_artwork_customer"}
                            label={'Deadline Artwork Customer'}
                        >
                            <DatePicker />

                        </Form.Item>

                        <Form.Item
                            name={"deadline_approval_proof_customer"}
                            label={'Deadline Approval proof Customer'}
                        >
                            <DatePicker />

                        </Form.Item>

                        <Form.Item
                            name={"deadline_approval_proof_vendor"}
                            label={'Deadline Approval proof Vendor'}
                        >
                            <DatePicker />

                        </Form.Item>


                    </Row>

                    <Form.Item
                        name={"artwork_details"}
                        label={'Artwork Details'}
                    >
                        <TextArea placeholder="Your message Here..." allowClear />

                    </Form.Item>



                </Form>

                <Button onClick={handleRequestTemplate} type='secondary'>Upload ArtWork</Button>


            </div>
            <br />
            <br />

            <CommentForClient comment={data.client_comment} />
            <br />
            <CommentForVendor comment={data.vendor_comment} />

            <br />
            <ActivityLogs logs={logs} />
            <Row className={styles.datesrow}>
                <Row>
                    <Button type='primary' onClick={() => {

                    }} icon={<SendOutlined />}>Notify Sales Manager</Button>

                    <Button style={{ 'background-color': 'orange', 'border-color': 'orange' }} type='primary' onClick={handleWishtoCancel} icon={<SendOutlined />}>Wish to Cancel</Button>

                </Row>

                <Button type='primary' onClick={() => {
                    handleOrderSend(form.getFieldValue())

                }} icon={<SendOutlined />}>Send Order</Button>
            </Row>

        </div>


    )

}

NewItem.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    logs: PropTypes.arrayOf(PropTypes.object).isRequired,
    changeState: PropTypes.func
}

export default NewItem;