import { Button } from 'antd';
import React from "react";
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { CommentForClient, CommentForVendor } from '../index';
import PropTypes from 'prop-types'


const OnHold = ({ data }) => {
    const handleOnHold = async () => {

        console.log('The  order is On Hold');

    }

    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'text-align': 'start' }}>Task For You</h3>
            <div className={styles.message}>
                <ul>
                    <li> The order is on Hold
                        <span style={{ "float": 'right' }}><Button onClick={handleOnHold}>Continue</Button></span>
                    </li>

                </ul>
                <p> <b>Reason for hold :</b> TODO TODO</p>
            </div>
        </div>
        <br />
        <br />

        <CommentForClient comment={data.client_comment} />
        <CommentForVendor comment={data.vendor_comment} />


    </div>
}

OnHold.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default OnHold;