// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/customer';

export const createCustomer = (payload) => {
    return axiosInstance.post(ENDPOINT, payload)
}