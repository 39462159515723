// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/vendor/quotation/';

const ENDPOINT_ITEM = '/item/quotation/'


export const getVendorQuotationList = () => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL+'list'))
}

export const getQuotationDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL+':id', {
        id
    })).then(({ data }) => data)
}

export const visualReceived = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_GENERAL+':id'+'/visual-received', {
        id
    })).then(({ data }) => data)
}



export const sendPrices = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+':id', {
        id
    }) ,payload )
}

export const sendPricesFile = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+':id'+'/file', {
        id
    }) ,payload )
}

export const requestVisual = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_GENERAL+':id'+'/visual-request', {
        id
    }) ,payload )
}

export const sendVisualByAdmin = (id,payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_ITEM+':id'+'/send-visual', {
        id
    }) ,payload )
}