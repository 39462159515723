import React from 'react';
import PropTypes from 'prop-types'
import { ValidationForm as Form } from '../';
import {
	Input,
	Select,
	Radio,
} from 'antd';
import { CountrySelect, CurrencySelect, ExecutiveEntitySelect, ImageUpload, LanguageSelect, PhoneInput } from '../../inputs'
import styles from './customer.module.scss'
import Industries from '../../../library/dynamics/industries'
import PaymentTerms from '../../../library/dynamics/payment-terms'
import InvoiceTypes from '../../../library/dynamics/invoice-types'

const Customer = ({ children, getValidationStatus, ...otherProps }) => {
	const [form] = Form.useForm()

	const validationRules = {
		company: [
			{
				required: true
			}
		],
		payment_term_id: [
			{
				required: false
			}
		],
		country_id: [
			{
				required: true
			}
		],
		language_id: [
			{
				required: true
			}
		],
		contact_country_code: [
			{
				required: true,
			}
		],
		contact_number: [
			{
				required: true,
			}
		],
		email: [
			{
				required: true
			},
			{
				type: 'email'
			}
		],
		currency_id: [
			{
				required: true
			}
		],
		entity_id: [
			{
				required: true
			}
		],
	}

	return (
		<Form form={form} name='customerDetails' onValidationStatusChange={getValidationStatus ?? undefined} layout="vertical" validateTrigger={['onChange', 'onBlur']} className={styles.FormWrapper} validationRules={validationRules} {...otherProps}>
			<Form.Item valuePropName="fileList">
				<ImageUpload />
			</Form.Item>
			<Form.Item name="company" label="Company" className={styles['FormField--TwoCol']}>
				<Input />
			</Form.Item>
			<Form.Item name='country_id' label="Country" >
				<CountrySelect onChange={(val, country) => {
					console.log(country);

					form.setFieldsValue({
						language_id: country.default_language_id,
						currency_id: country.currency_id,
						contact_country_code: country.phone_number_code,
						entity_id: country?.entity?.id,
					})
				}} />
			</Form.Item>
			<Form.Item name='language_id' label="Language" dependencies={['country_id']} >
				<LanguageSelect />
			</Form.Item>
			<Form.Item label="Phone Number" dependencies={['country_id']} required>
				<PhoneInput valuePropName={{ code: 'contact_country_code', number: 'contact_number' }} required />
			</Form.Item>
			<Form.Item name="email" label="Email" onChange={() => {
				form.validateFields(['email']).then(({ email }) => {
					form.setFieldValue('website', email.split('@').pop())
				}
				).catch(() => { })
			}}>
				<Input type="email" />
			</Form.Item>
			<Form.Item name="website" label="website">
				<Input type="text" />
			</Form.Item>
			<Form.Item name='currency_id' label="Currency" dependencies={['country_id']} >
				<CurrencySelect />
			</Form.Item>
			<Form.Item name='entity_id' label="Executive Entity" dependencies={['country_id']} >
				<ExecutiveEntitySelect onChange={(val, entity) => {
					form.setFieldsValue({

						currency_id: entity.currency_id,

					})

				}} />
			</Form.Item>
			<Form.Item name='industry_id' label="Industry">
				<Select options={Industries} fieldNames={{ value: 'id', label: 'name' }} />
			</Form.Item>
			<Form.Item name='payment_term_id' label="Payment Term">
				<Select options={PaymentTerms} fieldNames={{ value: 'id', label: 'name' }} />
			</Form.Item>
			<Form.Item name='vat_number' label="VAT Number">
				<Input />
			</Form.Item>
			<Form.Item name='invoice_type_id' label="Invoice Type">
				<Radio.Group buttonStyle="solid" optionType="button" options={InvoiceTypes.map(({ id, name }) => ({ value: id, label: name }))} fieldNames={{ value: 'id', label: 'name' }} />
			</Form.Item>
			<Form.Item name={['hubspot_identifier']} label="Hubspot Identifier">
				<Input disabled />
			</Form.Item>
			<Form.Item name={['internal_notes']} label="Internal Notes" className={styles['FormField--FullCol']}>
				<Input.TextArea rows={4} />
			</Form.Item>
			{children}
		</Form>
	)
}

Customer.propTypes = {
	children: PropTypes.node,
	onSubmit: PropTypes.func,
	getValidationStatus: PropTypes.func,
}

export default Customer