import { DeleteOutlined, EditOutlined, SaveOutlined, StarOutlined, UserAddOutlined, UserDeleteOutlined } from '@ant-design/icons'
import { Button, Collapse, Form, message, Popconfirm, Row, Tooltip } from 'antd'
import { customAlphabet } from 'nanoid/non-secure'
import PropTypes from 'prop-types'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useNavigate, useParams, generatePath } from 'react-router-dom'
import { createAddress, deleteAddress, updateAddress } from '../../api/address'
import { toggleContactAuthority } from '../../api/contact/contact-authority.js'
import { createContact } from '../../api/contact/contact.js'
import { deleteContact, updateContact } from '../../api/contact/contact[id].js'
import { getCustomerDetails, setCustomerPrimaryContact } from '../../api/customer'
import { updateCustomerDetails } from '../../api/customer/customer-details.js'
import { SmallSection } from '../../components/design'
import { Customer, CustomerAddress, CustomerContact } from '../../components/form'
import { Page } from '../../components/page'
import { createProject } from '../../api/project';
const { Panel } = Collapse
const nanoid = customAlphabet('1234567890abcdef', 6)

const ACTIONS = {
	SET: 'set',
	ADD: 'add',
	REMOVE: 'remove',
	UPDATE: 'update',
}

const reducer = (state, action) => {
	try {
		switch (action.type) {
			case ACTIONS.SET:
				if (typeof action.value === 'undefined') {
					throw new Error('value require for Set')
				}
				return { ...action.value }
			case ACTIONS.ADD:
				if (typeof action.value === 'undefined') {
					throw new Error('value require for update')
				}
				return { ...state, [nanoid()]: { ...action.value } }

			case ACTIONS.REMOVE: {
				if (!action.id) {
					throw new Error('Id is required for update')
				}
				if (typeof state[action.id] === 'undefined') {
					throw new Error('Invalid Id for update')
				}
				const tempState = { ...state }
				delete tempState[action.id]
				return { ...tempState }
			}

			case ACTIONS.UPDATE:
				if (!action.id) {
					throw new Error('Id is required for update')
				}
				if (typeof state[action.id] === 'undefined') {
					throw new Error('Invalid Id for update')
				}
				if (typeof action.value === 'undefined') {
					throw new Error('value require for update')
				}

				return { ...state, [action.id]: action.value }

			default:
				throw new Error('Invalid action')
		}
	}
	catch (e) {
		console.log(e)
		return state
	}
}

const CustomerDetails = ({ title }) => {
	const { id: customerId } = useParams()
	const [isLoading, setIsLoading] = useState(true)
	const [basicDetailsForm, setBasicDetailsForm] = useState(false)
	const [addressForms, dispatchAddressForm] = useReducer(reducer, {})
	const [contactForms, dispatchContactForm] = useReducer(reducer, {})
	const addressSubmitButtonRefs = useRef({})
	const contactSubmitButtonRefs = useRef({})
	const navigate = useNavigate()

	useEffect(() => {
		setIsLoading(true)
		getCustomerDetails(customerId)
			.then((data) => {
				setBasicDetailsForm(data)
				dispatchAddressForm({ type: ACTIONS.SET, value: data.addresses })
				dispatchContactForm({ type: ACTIONS.SET, value: data.contacts })
			})
			.finally(() => {
				setIsLoading(false)
			})
	}, [])

	const handleCustomerDetailsSubmit = async (details) => {
		try {
			setBasicDetailsForm({ ...basicDetailsForm, __isLoading: true })
			const { message: msg, data } = await updateCustomerDetails(customerId, details)
			message.success(msg)
			setBasicDetailsForm({ ...data })
		}
		catch (e) {
			message.error(e.message)
			throw e
		}
		finally {
			setBasicDetailsForm({ ...basicDetailsForm, __isLoading: false, __isEditable: false })
		}
	}

	const handleCustomerAddressSubmit = async (index, address) => {
		address.context_id = customerId
		address.context = 'customer'
		try {
			address = { ...addressForms[index], ...address, __isLoading: true }
			dispatchAddressForm({ type: ACTIONS.UPDATE, id: index, value: address })
			const { message: msg, data } = await (!address.id ? createAddress(address) : updateAddress(address.id, address))
			message.success(msg)
			dispatchAddressForm({ type: ACTIONS.UPDATE, id: index, value: { ...data, __isEditable: false, __isLoading: false } })
		}
		catch (e) {
			dispatchAddressForm({ type: ACTIONS.UPDATE, id: index, value: { ...address, __isLoading: false } })
			throw e
		}
	}

	const handleCustomerContactSubmit = async (index, contact) => {
		contact.customer_id = customerId
		try {
			contact = { ...contactForms[index], ...contact, __isLoading: true }
			console.log(contact)
			dispatchContactForm({ type: ACTIONS.UPDATE, id: index, value: contact })
			const { message: msg, data } = await (!contact.id ? createContact(contact) : updateContact(contact.id, contact))
			message.success(msg)
			dispatchContactForm({ type: ACTIONS.UPDATE, id: index, value: { ...data, __isEditable: false, __isLoading: false } })
		}
		catch (e) {
			dispatchContactForm({ type: ACTIONS.UPDATE, id: index, value: { ...contact, __isLoading: false } })
			throw e
		}
	}
	const makeProject = async (payload) => {
		try {
			const { message: msg, data } = await createProject({ ...payload })
			message.success(msg)
			navigate(generatePath('/projects/:id', {
				id: data.id
			}))
		}
		catch (e) {
			message.error(e.message)
			throw e
		}
	}

	const handleAddressDelete = async (index, addressId) => {
		const { message: msg } = await deleteAddress(addressId)
		message.success(msg)
		dispatchAddressForm({ type: ACTIONS.REMOVE, id: index })
	}

	const handleContactDelete = async (index, contactId) => {
		const { message: msg } = await deleteContact(contactId)
		message.success(msg)
		dispatchContactForm({ type: ACTIONS.REMOVE, id: index })
	}

	const handleMarkContactPrimary = async (index, contactId) => {
		const { message: msg, data } = await setCustomerPrimaryContact(contactId)
		message.success(msg)
		setBasicDetailsForm({ basicDetailsForm, primary_contact_id: data.primary_contact_id })
	}

	const handleToggleAuthority = async (index, contactId, action) => {
		console.log({ index, contactId, action })
		const { message: msg, data } = await toggleContactAuthority(contactId, Boolean(action))
		message.success(msg)
		dispatchContactForm({ type: ACTIONS.UPDATE, id: index, value: data })
	}

	const getAddressActions = (index, address) => {
		return ([
			address.__isEditable ?
				<Button onClick={() => {
					//console.log(addressSubmitButtonRefs.current[index]);

					addressSubmitButtonRefs.current[index].click()
				}
				} type="primary" shape="circle" icon={<SaveOutlined />} key="save" loading={address.__isLoading ?? false} /> :
				<Button onClick={() => { dispatchAddressForm({ type: ACTIONS.UPDATE, id: index, value: { ...address, __isEditable: true } }) }} type="primary" shape="circle" icon={<EditOutlined />} key="edit" />,
			<Popconfirm
				key="delete"
				title="Are you sure to delete this address?"
				onConfirm={() => handleAddressDelete(index, address.id)}
			>
				<Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
			</Popconfirm>
		])
	}

	const getContactAction = (index, contact) => {
		return ([
			contact.__isEditable ?
				<Tooltip title="Save" key={index}>
					<Button onClick={() => contactSubmitButtonRefs.current[index].click()} type="primary" shape="circle" icon={<SaveOutlined />} key="save" loading={contact.__isLoading ?? false} />

				</Tooltip> :
				<Tooltip title="Edit" key={index}>
					<Button onClick={() => { dispatchContactForm({ type: ACTIONS.UPDATE, id: index, value: { ...contact, __isEditable: true } }) }} type="primary" shape="circle" icon={<EditOutlined />} key="edit" />
				</Tooltip>,
			...contact.id !== basicDetailsForm.primary_contact_id ?
				[
					<Tooltip title="Give Contact Authority" key={index}>

						<Button onClick={() => { handleToggleAuthority(index, contact.id, !contact.is_authority) }} type="primary" shape="circle" icon={contact.is_authority ? <UserDeleteOutlined /> : <UserAddOutlined />} key="primary" />
					</Tooltip>,

					<Tooltip title="Make Contact Primary" key={index}>
						<Button onClick={() => { handleMarkContactPrimary(index, contact.id) }} type="primary" shape="circle" icon={< StarOutlined />} key="authority" />
					</Tooltip>,
				] : [],
			<Popconfirm
				key="delete"
				title="Are you sure to delete this contact?"
				onConfirm={() => handleContactDelete(index, contact.id)}
			>
				<Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
			</Popconfirm>
		])
	}

	return (
		<Page className='customers-detail' title={title} header={<Button type="primary" onClick={() => { navigate(-1) }}>Back</Button>}>
			{basicDetailsForm && !isLoading &&
				<>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Company Details' key="1" extra={
							<Row>
								<Button
									onClick={() => {
										makeProject({
											'source_id': 'email',
											'account_manager_id': '1',
											'customer_id': customerId
										})
									}}
									style={{ 'margin-right': '10px' }}>Create Project</Button>


								<Button onClick={(e) => { setBasicDetailsForm({ ...basicDetailsForm, __isEditable: true }); e.stopPropagation(); }} icon={<EditOutlined />} disabled={basicDetailsForm.__isEditable}>Edit</Button>


							</Row>


						}>


							<Customer id={Number(customerId)} initialValues={basicDetailsForm} onFinish={(data) => handleCustomerDetailsSubmit(data)} disabled={!basicDetailsForm.__isEditable} loading={basicDetailsForm.__isLoading}>
								<Button type="primary" htmlType="submit">Save</Button>
							</Customer>
						</Panel>
					</Collapse>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Addresses' key="1" >
							{Object.entries(addressForms).map(([id, address]) => (
								<SmallSection key={id} actions={getAddressActions(id, address)} >
									<CustomerAddress index={id} initialValues={address} onFinish={(data) => handleCustomerAddressSubmit(id, data)} disabled={!address.__isEditable || address.__isLoading}>
										<Form.Item style={{ display: 'none' }}>
											<Button htmlType="submit" ref={el => addressSubmitButtonRefs.current[id] = el}>Save</Button>
										</Form.Item>
									</CustomerAddress>
								</SmallSection>
							))}
							<Button onClick={() => dispatchAddressForm({ type: ACTIONS.ADD, value: { __isEditable: true } })}>Add New Address</Button>
						</Panel>
					</Collapse>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Contacts' key="1">
							{Object.entries(contactForms).map(([id, contact]) => (
								<SmallSection key={id} actions={getContactAction(id, contact)} primary={contact.id === basicDetailsForm.primary_contact_id} authority={contact.id !== basicDetailsForm.primary_contact_id && !!contact.is_authority}>
									<CustomerContact index={id} initialValues={contact} onFinish={(data) => handleCustomerContactSubmit(id, data)} disabled={!contact.__isEditable || contact.__isLoading}>
										<Form.Item style={{ display: 'none' }}>
											<Button htmlType="submit" ref={el => contactSubmitButtonRefs.current[id] = el}>Save</Button>
										</Form.Item>
									</CustomerContact>
								</SmallSection>
							))}
							<Button onClick={() => { dispatchContactForm({ type: ACTIONS.ADD, value: { __isEditable: true } }) }}>Add New Contact</Button>
						</Panel>
					</Collapse>
				</>
			}
		</Page>
	)
}

CustomerDetails.propTypes = {
	title: PropTypes.string
}

export default CustomerDetails