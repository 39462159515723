// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/customer';


// Define all api functions here
export const getAlCustomers = (payload) => {
    return axiosInstance.get(ENDPOINT, { params: payload })
}

export async function searchCustomers(query) {
    return axiosInstance.get('/customer/search', { params: {q: query}})
        .then(({data}) =>
            data.map(({id, company}) => ({
                label: company,
                value: id,
            })),
        )
}