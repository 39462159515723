import PropTypes from 'prop-types';
import React from "react";
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { ActivityLogs, CommentForClient, CommentForVendor } from '../index';


const RequestTemplateFromVendor = ({ data, logs }) => {
    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'textAlign': 'start' }}>Task For You</h3>

            {data.vendor_status == 3 ?
                <div className={styles.message}>
                    <ul>
                        <li>Template has been requested, Waiting for vendor reply
                        </li>

                    </ul>
                </div> : <div></div>
            }
            <br />
            <br />





        </div>

        {/* <PopUpMessage isOpen={open} heading= {'Order Sent to Vendor'} onContinue={handleOk} data= {message}/> */}
        <br />
        <br />
        <CommentForClient comment={data.client_comment} />
        <CommentForVendor comment={data.vendor_comment} />
        <ActivityLogs logs={logs} />


    </div>
}

RequestTemplateFromVendor.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    logs: PropTypes.arrayOf(PropTypes.object).isRequired
    //  changeState: PropTypes.func
}


export default RequestTemplateFromVendor;