import React from 'react';
import PropTypes from 'prop-types'
import { Badge } from 'antd'
import styles from './SmallSection.module.scss'
import './SmallSection.scss'

const SmallSection = ({ children, actions, primary, authority, colorLight, colorDark }) => {
    const inlineStyles = {}
    if (colorLight) {
        inlineStyles['backgroundColor'] = colorLight
    }
    if (colorDark) {
        inlineStyles['borderColor'] = colorDark
        inlineStyles['outlineColor'] = colorDark
    }

    {/* <Card className={`small-section ${styles.container} ${primary ? styles.primary : ''}`} style={inlineStyles} actions={actions}>
        {children}
    </Card> */}

    const header = () => (
        <header className={styles.header}>
            {actions.map((action) => (
                <span className={styles.action} key={action.key}>{action}</span>
            ))}
        </header>
    )

    const content = () => (
        <section className={`small-section ${styles.container} ${primary ? styles.primary : ''}`} style={inlineStyles}>
            {actions && header()}
            <div className={styles.body}>
                {children}
            </div>
        </section>
    )

    if (primary) {
        return (
            <Badge.Ribbon text="Primary" placement="start">
                {content()}
            </Badge.Ribbon>
        )
    }

    if (authority) {
        return (
            <Badge.Ribbon text="Authority" placement="start" color="purple">
                {content()}
            </Badge.Ribbon>
        )
    }

    return content()
}

SmallSection.propTypes = {
    children: PropTypes.node.isRequired,
    actions: PropTypes.arrayOf(
        PropTypes.element
    ),
    primary: PropTypes.bool,
    authority: PropTypes.bool,
    colorLight: PropTypes.string,
    colorDark: PropTypes.string,
}

export default SmallSection