// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/project';
const ENDPOINT_SINGLE = '/project/:id';
const ENDPOINT_SINGLE_ACTION = '/project/:id/:action';

export const getAllProjects = (payload) => {
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const createProject = (payload) => {
    return axiosInstance.post(ENDPOINT_GENERAL, payload)
}

export const getProjectDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        id
    })).then(({ data }) => data)
}

export const updateProjectDetails = (id, payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        id
    }), payload)
}

export const sendProjectClientQuotation = (id, payload) => {
    return axiosInstance.post(generatePath(ENDPOINT_SINGLE_ACTION, {
        id,
        action: 'client-quotation'
    }), Object.entries(payload).map(([item_id, vendor_id]) => ({
        item_id,
        vendor_id
    })))
}