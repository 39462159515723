import React from 'react';
import { Server } from '../../constants/server'

export const VendorColumns = [
    {
        title: 'Supplier Name',
        dataIndex: 'company',
        sorter: true,
        fixed: 'left',
    },
    {
        title: 'Vendor Type',
        dataIndex: 'type',
        sorter: true,
        fixed: 'left',
    },
    {
        title: 'Contact No.',
        dataIndex: 'phone_number',
        sorter: true,
        fixed: 'left',
    },
    {
        title: 'Email',
        dataIndex: 'email',
        sorter: true,
        fixed: 'left',
    },
    {
        title: 'Country',
        dataIndex: 'country',
        sorter: true,
        render: (country) => {
            return <img src={`${Server.BaseUrl}/${country.flag_url}`} alt={country.name} title={country.name} />
        }
    },
    {
        title: 'Language',
        dataIndex: 'language',
        sorter: true,
        render: language => language.name
    }
    // ,
    // {
    //     title: 'Rating',
    //     dataIndex: 'language',
    //     sorter: true,
    //     render: language => language.name
    // },
]