import { Checkbox } from 'antd';
import React from 'react';
import { Server } from '../server';

export const UserColumns = [
    {
        title: 'Action',
        key: 'operation',
        fixed: 'left',
        width: 100,
        render: () => <Checkbox></Checkbox>,
    },
    {
        title: 'Company',
        dataIndex: 'customer',
        render: customer => customer?.company,
        //sorter: true,
    },
    {
        title: 'Account Manager',
        dataIndex: 'user',
        render: user => user?.name
        //sorter: true,
    },
    {
        title: 'Status',
        dataIndex: 'status_name',
        //sorter: true,
        render: status => status?.name,
    },
    {
        title: 'Amount',
        dataIndex: 'amount',
        //sorter: true,
        render: () => '100 $ : dummy',
    },
    {
        title: 'Country',
        dataIndex: ['customer', 'country'],
        ///sorter: true,
        render: (country) => {
            return <img src={`${Server.BaseUrl}/${country.flag_url}`} alt={country.name} title={country.name} />
        }
    }
    // {
    //     title: 'Language',
    //     dataIndex: 'language',
    //     //sorter: true,
    //     render: language => language.name
    // },
    // {
    //     title: 'Order Manager',
    //     dataIndex: 'order_manager',
    //     //sorter: true,
    //     render: user => user?.name
    // },
    // {
    //     title: 'Contact',
    //     dataIndex: 'contact',
    //     render: contact => contact?.email
    //     //sorter: true,
    // },

]