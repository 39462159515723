// common headers for all endpoints
export const defaultHeaders = () => ({
	//platform: 'web',
	//'api-version': '1',
	accept: 'application/json',
	// "Access-Control-Allow-Origin": "*",
	
	// 'x-client-time': Date.now().toString(),
	// 'x-device-id': `${localStorage.getItem('DEVICE_ID') || ''}`,
	//Authorization: localStorage.getItem('ACCESS_TOKEN') ? `Bearer ${localStorage.getItem('ACCESS_TOKEN')}` : '',
	//locale: 'en',
	//country: '1'
});
