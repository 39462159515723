import { Button, Result } from 'antd';
import { intersection } from "lodash-es";
import React from 'react';
import { createBrowserRouter, Outlet, RouterProvider, useNavigate, useRouteError } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import AuthenticatedPage from './auth-wrapper.jsx';
import { routesList } from "./library/constants";
import AppProvider from './providers/app/app-provider.jsx';
import Login from './routes/login/login.jsx';

const formatRoute = (route) => {
  const { component: Component, title, url: path } = route
  const obj = {
    element: Component ? <Component title={title} /> : <div><Outlet /></div>,
  }
  if (path) {
    obj.path = path
  }
  else {
    obj.index = true
  }
  if (route.children) {
    obj.children = route.children.map(formatRoute)
  }
  if (Component?.Loader) {
    obj.loader = Component.Loader
  }

  if (Component?.Action && Component?.Actions) {
    throw new Error('Route cannot have both `Action` and `Actions`', route)
  }

  if (Component?.Actions) {
    obj.action = async ({ params, request }) => {
      const { action, ...data } = Object.fromEntries(await request.formData())
      const { method } = request
      if (!Component.Actions[action]) {
        throw new Error(`Unknow action: ${action} on route: ${Component.name}`, route)
      }
      return await Component.Actions[action]({ params, method, data })
    }
  }
  else if (Component?.Action) {
    obj.action = Component.Action
  }
  return obj
}

function App() {

  // These user roles can later on come from the profile based on authentication
  const userRoles = [999, 102];
  const routes = routesList.filter(
    (item) => intersection(item.roles_allowed, userRoles).length > 0
  );

  return (
    <React.Fragment>
      <ToastContainer
        theme="dark"
        position="top-center"
        hideProgressBar
        limit={3}
      />
      <AppProvider>
        <RouterProvider router={createBrowserRouter([
          {
            path: '/',
            element: <AuthenticatedPage />,
            errorElement: <ErrorBoundary />,
            children: routes.map(formatRoute)
          },
          {
            path: '/',
            element: <Login />,
          }
        ])} />
      </AppProvider>
    </React.Fragment>
  );
}

function ErrorBoundary() {
  const navigate = useNavigate()
  let error = useRouteError();
  console.error(error);
  // Uncaught ReferenceError: path is not defined
  return (
    <Result
      status="500"
      title="500"
      subTitle="Sorry, something went wrong."
      extra={<Button type="primary" onClick={() => navigate(-1)}>Go Back</Button>}
    />
  );
}

export default App;
