/* eslint-disable no-unused-vars */
import { DeleteOutlined, EditOutlined, InboxOutlined } from '@ant-design/icons';
import { Button, Collapse ,message} from 'antd';
import PropTypes from 'prop-types';
import React, { useMemo, useRef, useState } from 'react';
import { useLoaderData, useNavigate ,generatePath} from 'react-router-dom';
import { addProjectContact, getProjectDetails, removeProjectContact, removeProjectContactAuthority, sendProjectClientQuotation, setProjectContactAuthority, setProjectContactPrimary } from '../../api/project';
import { getProjectItem } from '../../api/project-item';
import { ProjectItemVendorPrices } from '../../components/data/index.js';
import projectItemStyles from '../../components/data/project-item/item-overview.module.scss';
import '../../components/data/project-item/item-overview.scss';
import { Page } from '../../components/page';
import { generateRouteUrl } from '../../library/constants/routes.js';
const { Panel } = Collapse


const ProjectItemOverviewSelectable = ({ children, index, data, onEdit, onDelete, onVendorChange }) => {
	const [selectedVendorId, setSelectedVendorId] = useState(null)
	const formatDate = (date) => {
		const d = new Date(date)
		return d.toLocaleDateString('nl-NL')
	}

	const itemData = useMemo(() => {
		const res = { ...data }
		res.selected_vendors = res.selected_vendors.map(v => ({
			...v,
			['prices']: data.vendors_prices[v.id]
		}))
		return res
	}, [data])
	

	return (
		<div className={`project-item-overview ${projectItemStyles.wrapper}`}>
			<div className={projectItemStyles.header}>
				<span className="project-item-index" onClick={onEdit ?? undefined}>
					#{index + 1}
				</span>
				<span className="project-item-name" onClick={onEdit ?? undefined}>
					{itemData.product.name}
				</span>
				<span className="project-item-actions">
					<span className="project-item-actions--action project-item-date">
						{formatDate(itemData.createdAt)}
					</span>
					{typeof onDelete !== 'undefined' && <span className="project-item-actions--action project-item-delete" onClick={onDelete}>
						<DeleteOutlined />
					</span>}
					{typeof onEdit !== 'undefined' && <span className="project-item-actions--action project-item-edit" onClick={onEdit}>
						<EditOutlined />
					</span>}
				</span>
			</div>
			<div className={projectItemStyles.body}>
				{itemData.selected_vendors.map(vendor => <ProjectItemVendorPrices key={vendor.id} data={{ ...vendor, ['isSelected']: selectedVendorId === vendor.id }} onVendorChange={(vendorId) => {
					setSelectedVendorId(vendorId)
					onVendorChange(data.id, vendorId)
				}} />)}
			</div>
			{children}
		</div>
	)
}

ProjectItemOverviewSelectable.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	data: PropTypes.shape({
		id: PropTypes.number.isRequired,
		selected_vendors: PropTypes.array,
		vendors_prices: PropTypes.object,
		quantities: PropTypes.array,
		product: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
		}).isRequired,
	}),
	onEdit: PropTypes.func,
	onDelete: PropTypes.func,
	onVendorChange: PropTypes.func,
}

const SendClientQuotation = ({ title }) => {
	const { projectId, items } = useLoaderData()
	const selectedItemVendors = useRef({})
	const navigate = useNavigate()
	//const fetcher = useFetcher()

	return (
		<Page className='projects-send-client-quotation' title={title} header={<Button type="primary" onClick={() => { navigate(-1) }}>Back</Button>}>
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				alignTtems: 'stretch',
			}} >
				<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
					<Panel header={<><InboxOutlined /> Item Details</>} key="1">
						{Object.entries(items).map(([index, item]) => (
							<ProjectItemOverviewSelectable key={item.id} index={Number(index)} data={item} onVendorChange={(_itemId, _vendorId) => {
								selectedItemVendors.current[_itemId] = _vendorId
							}} />
						))}
						<div className="ant-collapse-footer">
							<Button onClick={async () =>{
								console.log(selectedItemVendors.current);
								try {
									const { message: msg } = await sendProjectClientQuotation(projectId, selectedItemVendors.current)
									message.success(msg)
									navigate(generatePath('/projects/:id', {
										id: projectId
									}))
								}
								catch (e) {
									message.error(e.message)
									throw e
								}
								

							}} type="primary" ghost>Send to Client</Button>
						</div>
					</Panel>
				</Collapse>
			</div>
		</Page >
	)
}

SendClientQuotation.propTypes = {
	title: PropTypes.string
}

SendClientQuotation.Actions = {
	addContact: ({ params, data }) => addProjectContact(params.projectId, data.contactId),
	removeContact: ({ params, data }) => removeProjectContact(params.projectId, data.contactId),
	primaryContact: ({ params, data }) => setProjectContactPrimary(params.projectId, data.contactId),
	authorityContact: ({ params, data, method }) => {
		if (method.toLowerCase() === 'delete') {
			return removeProjectContactAuthority(params.projectId, data.contactId)
		}
		else {
			return setProjectContactAuthority(params.projectId, data.contactId)
		}
	}
}

SendClientQuotation.Loader = async ({ params }) => {
	const { projectId = undefined } = params
	if (isNaN(projectId)) {
		throw new Error('Invalid Project ID')
	}
	const projectDetails = await getProjectDetails(projectId)
	if (!projectDetails) {
		throw new Error('Invalid Project')
	}

	const items = await Promise.all(projectDetails.items.map(async ({ id }) => {
		return await getProjectItem(id)
	}))

	return { projectId, items }
}

export default SendClientQuotation