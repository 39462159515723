import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types';
import { AppContext } from './app-context.js';

const themes = {
  DARK: 'dark',
  LIGHT: 'light'
}

function AppProvider(props) {
  const { children } = props;

  const [theme, setTheme] = useState('light');
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);



  const state = useMemo(() => ({
    theme,
    sidebarCollapsed,
    actions: {
      toggleTheme: () => {
        setTheme((val) => val===themes.DARK?themes.LIGHT:themes.DARK)
      },
      toggleSidebarCollapsed: () => {
        setSidebarCollapsed((val) => !val)
      }
    }
  }), [theme, sidebarCollapsed])

  return <AppContext.Provider value={state}>{children}</AppContext.Provider>;
}

AppProvider.propTypes = {
  children: PropTypes.node,
}

export default AppProvider
