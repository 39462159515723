import { ApartmentOutlined, SearchOutlined } from '@ant-design/icons';
import { Input, Select, Spin, TreeSelect } from 'antd';
import debounce from 'lodash/debounce';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { getAllCategories, getProductDetails, searchProducts } from '../../api/product';
import Languages from '../../library/dynamics/languages';

function ProductSelect({ language, value = null, onChange, currentSelection = {}, disabled = false }) {
    const debounceTimeout = 800
    const [fetching, setFetching] = useState(false)
    const [product, setProduct] = useState({})
    const [categories,
        setCategories
        ] = useState([])
    const [selectedCategory, setSelectedCategory] = useState()
    const [options, setOptions] = useState([{ value: currentSelection?.id, label: currentSelection?.name }]);
    const fetchRef = useRef(0);
    

    useEffect(() => {
        // getProductDetails(id)
        //                     .then((data) => {
                                
        //                         setSelectedCategory(data?.category_id)
        //                         onChange(data)
        //                         console.log(data);
        //                         setProduct(data);
        //                         console.log(product);
        //                     })
        debounceFetcher()
        getAllCategories(language)
            .then(data => {
                setCategories(data)
               // searchProducts(data)
            })
    } ,[])

    const debounceFetcher = useMemo(() => {
        const loadOptions = (value) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setOptions([]);
            setFetching(true);
            searchProducts(value, selectedCategory, language).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    // for fetch callback order
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };
        return debounce(loadOptions, debounceTimeout);
    }, [selectedCategory, language, searchProducts, debounceTimeout]);

    return (
        <>
            <Input.Group compact>
                <TreeSelect
                    value={selectedCategory}
                    style={{ width: '30%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={categories}
                    fieldNames={{
                        label: 'name',
                        value: 'id',
                    }}
                    onChange={(category_id) => {
                        setSelectedCategory(category_id)
                        debounceFetcher()
                    }}
                    placeholder="Category"
                    /* onChange={onCategoryChange} */
                    suffixIcon={<ApartmentOutlined />}
                    allowClear
                    disabled={disabled}
                //showSearch
                />
                <Select
                    filterOption={false}
                    onSearch={debounceFetcher}
                    notFoundContent={fetching ? <Spin size="small" /> : null}
                    options={options}
                    onChange={(id) => {
                        getProductDetails(id)
                            .then((data) => {
                                
                                setSelectedCategory(data?.category_id)
                                onChange(data)
                                console.log(data);
                                setProduct(data);
                                console.log(product);
                            })
                    }}
                    value={value ?? currentSelection?.id}
                    placeholder="Select a Product"
                    suffixIcon={<SearchOutlined />}
                    style={{ width: '70%' }}
                    showSearch
                    allowClear
                    disabled={disabled}
                />
            </Input.Group>
            <span>
                {Object.keys(product).length > 0 && (
                    <>
                        Product URL: <Link to={product.url}>{product.url}</Link>
                    </>
                )}
            </span>
        </>
    )
}

ProductSelect.propTypes = {
    language: PropTypes.oneOf(Languages.map(({ cms_language_code }) => cms_language_code)).isRequired,
    value: PropTypes.number,
    onChange: PropTypes.func,
    currentSelection: PropTypes.shape({
        id: PropTypes.number.isRequired,
        name: PropTypes.string.isRequired
    }),
    disabled: PropTypes.bool,
}

export default ProductSelect;