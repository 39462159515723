import React from "react";
import { Image } from '../../../ui';
import styles from './item.module.scss';
import PropTypes from 'prop-types';
const ItemDetailsSection = ({detail})=>{
 
    console.log(detail);


    return (
        <div className={styles.wrapper}>
            <div className={styles.left}>
                <div className={styles.image}>
                    {detail.product.main_photos && <Image src={`https://leoprinting.nl/files/photos/${detail.product.main_photos[0]}`} height= '200px'
                    width='150px'
                    />}
                </div>
                Image is for illustration.Real product may vary
            </div>
            <div className={styles.right}>
              
                <div className={styles.specifications}>
                    {detail.specification.map(({ name, value }) => (
                        <React.Fragment key={name}>
                            <div className={styles.name}>{name}</div>
                            <div className={styles.value}>{value}</div>
                        </React.Fragment>
                    ))}
                </div>
               
                {/* <QuotationItemQuantities prices={item.prices} selectedId={selectedId} onSelectionChange={(selected) => {
                    onSelectionChange({ itemId: item.id, priceId: selected })
                }} /> */}
            </div>
        </div>
    );
}

ItemDetailsSection.propTypes = {
	detail: PropTypes.arrayOf(PropTypes.object).isRequired
}
export default ItemDetailsSection