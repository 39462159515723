import { axiosInstance } from "../common";

const ENDPOINT = `/image`;

export const uploadFileFromCanvas = async (token, canvas, filename = Date.now()) => {
    const blob = await new Promise(resolve => canvas.toBlob(resolve))
    const formData = new FormData();
    formData.append('image', blob, `${filename}.png`);
    return axiosInstance.post(ENDPOINT, formData, {
        params: {
            token
        }
    }).then(({ id }) => id)
}

export const uploadFileFromObject = async (token, file) => {
    const formData = new FormData();
    formData.append('image', file);
    return axiosInstance.post(ENDPOINT, formData, {
        params: {
            token
        }
    }).then(({ id }) => id)
}