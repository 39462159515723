import React from 'react';
import PropTypes from 'prop-types'
import { ValidationForm as Form } from '../';
import {
	Input,
} from 'antd';
import { CountrySelect } from '../../inputs'
import styles from './customer-address.module.scss'

const CustomerAddress = ({children, index, getValidationStatus, ...otherProps }) => {
	const [form] = Form.useForm()
	const validationRules = {
		street: [
			{
				required: true
			}
		],
		street_number: [
			{
				required: true
			}
		],
		city: [
			{
				required: true
			}
		],
		zipcode: [
			{
				required: true
			}
		],
		country_id: [
			{
				required: true
			}
		],
	}

	return (
		<Form form={form} name={`address-${index}`} onValidationStatusChange={getValidationStatus} layout="vertical" className={styles.FormWrapper} validationRules={validationRules} {...otherProps}>
			
            <Form.Item name="street" label="Street">
                <Input />
            </Form.Item>
			<Form.Item name="street_number" label="Street Number">
                <Input />
            </Form.Item>
			<Form.Item name="city" label="City">
                <Input type="text" />
            </Form.Item>
			<Form.Item name='county' label="County">
                <Input type="text" />
            </Form.Item>
			<Form.Item name='country_id' label="Country">
                <CountrySelect />
            </Form.Item>
			<Form.Item name="zipcode" label="Zipcode" >
                <Input type="text" />
            </Form.Item>
			{children}
        </Form>
	)
}

CustomerAddress.propTypes = {
	children: PropTypes.node,
	index: PropTypes.string.isRequired,
	getValidationStatus: PropTypes.func,
}

export default CustomerAddress