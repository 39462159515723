import { Button, Checkbox, Form, Input, InputNumber, Rate, Modal, message } from 'antd';
import { isNil, omitBy } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useMemo, useState, useRef } from 'react';
import { Currency, Percent } from '../../inputs';
import styles from './vendor-item-prices.module.scss';
import './vendor-item-prices.scss';
import { getVendorProjectItemQuotationRequest, selectVendor } from '../../../api/project-item';
import { Server } from '../../../library/constants/server';
import { FilePdfOutlined } from '@ant-design/icons'
import { useActionData, useParams } from 'react-router-dom'
import { MultifileUpload, Message, MessageTypes } from "../../../ui";
import { uploadFileFromObject } from '../../../api/common';
import { sendVisualByAdmin } from '../../../api/vendor/vendorQuotation';
import style2 from '../../../routes/quotation/stepBody.module.scss';
import { ActionError } from '../../../library/classes/exceptions';

const calculateTotals = (quantity, values) => {
    const { purchase_price = 0, finisher_price = 0, handling_charge = 0, shipping_charge = 0, margin = 0 } = values
    const totalPrice = ((purchase_price * quantity) + finisher_price + handling_charge + shipping_charge) * (1 + margin / 100)
    const salesUnitPrice = totalPrice / quantity
    return {
        totalPrice,
        salesUnitPrice
    }
}


const calculateMargin = (values, customPrice, quantity) => {
    const { purchase_price = 0, finisher_price = 0, handling_charge = 0, shipping_charge = 0 } = values
    const totalPrice = ((purchase_price * quantity) + finisher_price + handling_charge + shipping_charge)
    return (((customPrice * quantity) / totalPrice - 1)) * 100
}

const VendirItemQuantityPriceRpw = ({ quantity, initialValues = {}, onUpdate }) => {
    const [form] = Form.useForm()


    const parsedInitialValues = useMemo(() => {
        return Object.fromEntries(Object.entries(initialValues).map(([i, v]) => [i, Number(v)]))
    }, [initialValues])
    const [calculatedTotals, setCalculatedTotals] = useState(calculateTotals(quantity, parsedInitialValues))

    const handleValueChange = (changedFields, values) => {
        const totals = calculateTotals(quantity, values)
        if (typeof changedFields.custom_selling_price_unit === "undefined") {
            form.setFieldValue('custom_selling_price_unit', totals.salesUnitPrice)
        }
        else {
            form.setFieldValue('margin', calculateMargin(values, changedFields.custom_selling_price_unit, quantity))
        }
        setCalculatedTotals(totals)
        onUpdate(omitBy(form.getFieldsValue(true), isNil))
    }

    return (
        <Form className={`${styles.vendorPriceRow} vendor-price-row`} form={form} initialValues={parsedInitialValues} name={`vendor-price-item-${quantity}`} onValuesChange={handleValueChange}>
            <Form.Item>
                <InputNumber value={quantity} disabled controls={false} />
            </Form.Item>
            <Form.Item name="purchase_price">
                <Currency prefix="€" type='currency' controls={false} />
            </Form.Item>
            <Form.Item name="finisher_price">
                <Currency prefix="€" type='currency' controls={false} />
            </Form.Item>
            <Form.Item name="handling_charge">
                <Currency prefix="€" type='currency' controls={false} />
            </Form.Item>
            <Form.Item name="shipping_charge">
                <Currency prefix="€" type='currency' controls={false} />
            </Form.Item>
            <Form.Item name="margin">
                <Percent type='percent' controls={false} />
            </Form.Item>
            <Form.Item name="custom_selling_price_unit">
                <Currency prefix="€" type='currency' controls={false} />
            </Form.Item>
            <Form.Item >
                <Currency prefix="€" value={calculatedTotals.salesUnitPrice} disabled controls={false} />
            </Form.Item>
            <Form.Item >
                <Currency prefix="€" value={calculatedTotals.totalPrice} disabled controls={false} />
            </Form.Item>
        </Form >
    )
}
VendirItemQuantityPriceRpw.propTypes = {
    quantity: PropTypes.number.isRequired,
    initialValues: PropTypes.object,
    onUpdate: PropTypes.func.isRequired,
}

const VendorItemPrices = ({ vendor, quantities, children, onVendorSelectionChange, onPriceUpdate, onVendorCommentChange, onSendRequest, item, selectedVendorId }) => {
    console.log('==========' + selectedVendorId);

    const actionData = useActionData()
    const { quotationToken } = useParams()
    const [quotationData, setQuotationData] = useState(null)
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const fileUploaderRef = useRef([])

    const showModal = async () => {
        const v = await getVendorProjectItemQuotationRequest(item, vendor.id)
        setQuotationData(v)


        // console.log(quotationData.length)

        // quotationData == null || quotationData.length ==0 ?
        // message.warning('Quotaion Not available'):
        setIsModalOpen(true);
    };


    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleUploadCancel = () => {
        setOpen(false);
    };
    const handleFilesUpload = async () => {
        if (fileUploaderRef.current.length === 0) {
            alert('Please select atleast one file')
            return
        }
        const promises = fileUploaderRef.current.map(file => uploadFileFromObject(quotationToken, file))
        try {
            const fileIds = await Promise.all(promises)
            const response = await sendVisualByAdmin(quotationData[0].id, { image_ids: fileIds })
            const { message: msg } = response
            message.success(msg);
            setOpen(false);
            setIsModalOpen(false)
        }
        catch (error) {
            throw new Response('Unable to upload files', { status: 500 })
        }
    }

    const handleSelectVendor = async () => {
        const response = await selectVendor(item, vendor.id)
        const { message: msg } = response
        message.success(msg);

    }
    return (
        <div className={styles.container}>
            <table className={styles.vendorDetails}>
                <colgroup>
                    <col width="50px" />
                </colgroup>
                <thead>
                    <tr>
                        <td></td>
                        <td>Vendor</td>
                        <td>Price List</td>
                        <td>Price List Link</td>
                        <td>Rating</td>
                        <td>Status</td>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <th><Checkbox defaultChecked={!!vendor.quotationAssignment} onChange={(e) => onVendorSelectionChange(vendor.id, e.target.checked)} /></th>
                        <th>{vendor.company}</th>
                        <th>{vendor?.priceList ? 'Available' : 'Unavailable'}</th>
                        <th>{vendor.priceList ?? ''}</th>
                        <th><Rate defaultValue={vendor.rating ?? 0} disabled /></th>
                        <th>Status</th>
                    </tr>
                </tbody>
            </table>
            {!!vendor.quotationAssignment && <div className={styles.quantityPrices}>
                <div className={styles.quantityPricesHead}>
                    <div>Quantity</div>
                    <div>Purchase Price</div>
                    <div>Finisher&apos;s Price</div>
                    <div>Handling Charges</div>
                    <div>Shipping Charges</div>
                    <div>Margin</div>
                    <div>Custom Selling Price/Unit</div>
                    <div>Sales Price </div>
                    <div>Total Price</div>
                </div>
                {quantities.map(quantity => <VendirItemQuantityPriceRpw key={quantity} quantity={quantity} initialValues={vendor.prices.find(p => p.quantity === quantity)} onUpdate={(prices) => onPriceUpdate(vendor.id, quantity, prices)} />)}


                <Button onClick={showModal} >View Quotation</Button>

                {(quotationData == null || quotationData.length == 0) ? <Modal open={isModalOpen} onCancel={handleCancel}>Quotataion Not Avaiable</Modal> :


                    <Modal title="Quotation Details" open={isModalOpen} onCancel={handleCancel}>
                        {quotationData.map((qData, index) => {

                            return <div key={index}>

                                <div className='priceBox'>
                                    <h4>
                                        version {index + 1}
                                    </h4>
                                    <p className='smallText'>Created At : {quotationData == null ? '' : qData.createdAt}</p>
                                    <p className='smallText'>Comment : {quotationData == null ? '' : qData.comment}</p>
                                    <hr />
                                    {
                                        qData.status == 3 || qData.status == 1 ?
                                            <div>

                                                <table border="1" style={{ 'border': '1px solid black', 'width': '100%' }}>
                                                    <tr>
                                                        <td className='cellPadding'>
                                                            Quantity
                                                        </td>
                                                        <td className='cellPadding'>
                                                            Purchase Price
                                                        </td>
                                                        <td className='cellPadding'>
                                                            Shipping Price
                                                        </td>
                                                    </tr>

                                                    {qData.item_vendor_quotation_prices.map((value) => {
                                                        return (
                                                            <tr key={value} >
                                                                <td className='itemCellPadding'>
                                                                    {value.quantity ?? 0}
                                                                </td>
                                                                <td className='itemCellPadding'>
                                                                    {value.price_unit ?? 0}
                                                                </td>
                                                                <td className='itemCellPadding'>
                                                                    {value.transport_price ?? 0}
                                                                </td>
                                                            </tr>
                                                        )
                                                    }



                                                    )}

                                                </table>

                                            </div> :
                                            qData.status == 4 ?
                                                <div>
                                                    <span>

                                                        <a href={Server.BaseUrl + '/api/v1/' + qData.file.quotation.url} target="blank">
                                                            <Button onClick={() => {
                                                                console.log(Server.BaseUrl + '/api/v1/' + qData.file.quotation.url);
                                                            }} type='secondary' icon={<FilePdfOutlined />}>Download File</Button>
                                                        </a>

                                                    </span>

                                                </div> :
                                                qData.status == 6 ?
                                                    <Button onClick={() => {
                                                        setOpen(true)
                                                    }}> Send Visual</Button> :

                                                    qData.status == 7 ?

                                                        qData.request_files.map((value) => {
                                                            return (
                                                                <div key={value.name}>
                                                                    <br />
                                                                    <p>{value.name}</p>
                                                                    <span>

                                                                        <a href={Server.BaseUrl + '/api/v1/' + value.url} target="blank">
                                                                            <Button onClick={() => {
                                                                                console.log(Server.BaseUrl + '/api/v1/' + value.url);
                                                                            }} type='secondary' icon={<FilePdfOutlined />}>Download File</Button>
                                                                        </a>

                                                                    </span>

                                                                </div>
                                                            )
                                                        })


                                                        : <div></div>

                                    }


                                </div>

                                <br />
                            </div>
                        })}
                    </Modal>







                }

                <Modal
                    title="Upload Visual"
                    open={open}


                    onCancel={handleUploadCancel}
                >

                    <div className="wrapper clientartwork-wrapper">
                        <div className="main">
                            <h2 className="main--title">
                                <img src="/icons/add-location.svg" alt="" />
                                Upload History
                            </h2>
                            <div className={`box--white ${style2.wrapper}`}>

                                <div className={style2.content}>
                                    {!!actionData && actionData instanceof ActionError && <Message type={MessageTypes.ERROR} message={actionData.message} timeOut={3} />}
                                    {actionData === true && <Message type={MessageTypes.SUCCESS} message="File Uploaded Successfully" timeOut={3} />}
                                    <MultifileUpload ref={fileUploaderRef} />
                                    <i>Supported formats : PDF</i>
                                </div>
                                <div className="actions align--right">
                                    <button className="button--primary" type="submit" onClick={handleFilesUpload}>Upload</button>
                                </div>
                                {/* {!!artworks && (
                                          <div className={style2.uploadedArtworks}>
                                              <h2>Upload History</h2>
                                              <div className={style2.artworksHistory}>
                                                  <ArtworksPreview key={artworks.id} id={artworks.id} documents={artworks.documents} date={artworks.createdAt} />
                                              </div>
                                          </div>
                                      )} */}
                            </div>
                        </div>
                    </div>

                </Modal>


                <Form layout="vertical" initialValues={vendor.quotationAssignment} onValuesChange={(_, values) => onVendorCommentChange(vendor.id, values)}>
                    <Form.Item name="comment" label="Special Comment for Vendor">
                        <Input.TextArea />
                    </Form.Item>
                </Form>
                {
                    typeof onSendRequest === 'function' &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'space-evenly'
                    }}>
                        <Button onClick={() => onSendRequest(vendor.id)}>
                            {vendor.quotationAssignment.status === 2 ? 'Resend Request' : 'Send Request'}
                        </Button>

                        {selectedVendorId == vendor.id ? <div className={styles.selectedVendor}>This vendor has been selected</div> : <Button onClick={handleSelectVendor}>
                            Select Vendor
                        </Button>}
                    </div>
                }
            </div>}
            {children}


        </div>

    )


}

VendorItemPrices.propTypes = {
    vendor: PropTypes.object.isRequired,
    item: PropTypes.number,
    selectedVendorId: PropTypes.number,
    quantities: PropTypes.arrayOf(Number).isRequired,
    onVendorSelectionChange: PropTypes.func.isRequired,
    onPriceUpdate: PropTypes.func.isRequired,
    onVendorCommentChange: PropTypes.func.isRequired,
    onSendRequest: PropTypes.func,
    children: PropTypes.node,
}

export default VendorItemPrices