import PropTypes from 'prop-types';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { Page } from '../../components/page';
// import { getVendorQuotationList } from '../../api/vendor/vendorQuotation';
import { Segmented, Spin } from 'antd';

import { AppstoreOutlined, BarsOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';
import { getStudioList } from '../../api/studio/studio';
import { StudioKanaban } from '../../components/data';


const VIEW_MODES = Object.freeze({
    LIST: "list",
    KANABAN: "kanaban",
})



const StudioKanabanView = () => {
    const navigate = useNavigate()
    const [list, setList] = useState([])



    useEffect(() => {
        getStudioList().then(({ data }) => {
            console.log(data);
            setList(data)
        })
    }, [])

    const handleRowClick = (_, record) => {
        console.log(record);
        record.studio_status == 2 || record.studio_status == 4 || record.studio_status == 6 ?
        console.log(record):
        navigate(generatePath('/studio-item-details/:id', {
            id: record.id
        }))
    }

    if (!list) {
        console.log('studio data list------->');
        console.log(list);
        return (
            <>
                <Spin />
            </>
        )
    }

    return (
        <StudioKanaban data={[...list]} columns={[


            {
                key: 1,
                title: 'Request Template',
            },
            {
                key: 2,
                title: 'Send Template',
            },
            {
                key: 3,
                title: 'Request Vendor Artwork',
            },

            {
                key: 4,
                title: 'Send Vendor Artwork',
            },
            {
                key: 5,
                title: 'Request Client Artwork',
            },
            {
                key: 6,
                title: 'Send Clinet Artwork',
            },





        ]} onItemClick={(order) => {
            handleRowClick(null, order)
        }} />
    )
}



function StudioList(props) {
 
    const [viewMode, setViewMode] = useState(VIEW_MODES.KANABAN)

    return (
        <Page className='quotation-list' title={props.title} header={
            <>
                {/* <Button onClick={() => setFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button> */}
                {/* <Form.Item name="client_order_number" label="Client Order Number" > */}





                <Segmented
                    value={viewMode}
                    options={[
                        {
                            value: VIEW_MODES.LIST,
                            icon: <BarsOutlined />,
                        },
                        {
                            value: VIEW_MODES.KANABAN,
                            icon: <AppstoreOutlined />,
                        },
                    ]}
                    onChange={setViewMode}
                />
            </>
        }>
            {/*  <Drawer open={filtersVisible} onClose={() => setFiltersVisible(false)} >
        <CustomerFilterForm data={filters} onFinish={(data) => setFilters(data)} />
      </Drawer> */}

            {/* {viewMode === VIEW_MODES.LIST
        ? <QuoteKanabanView />
        : <QuoteKanabanView />
      } */}


            <StudioKanabanView />
        </Page>
    )
}

StudioList.propTypes = {
    title: PropTypes.string,
}

export default StudioList
