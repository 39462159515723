import React from "react";
// import {Row,Col} from 'antd'
import { CheckOutlined } from '@ant-design/icons';
import { Button, Form, message } from 'antd';
import PropTypes from 'prop-types';
import { sendOrderDispatchDate } from "../../../api/order";
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { DatePicker } from "../../../components/inputs";
import { CommentForClient, CommentForVendor } from '../index';





const DispatchDateConfirm = ({ data }) => {
    const [form] = Form.useForm()
    const handleDispatchDateSubmit = async (shippingID, jsonData) => {
        try {
            const response = await sendOrderDispatchDate(shippingID, jsonData)
            const { message: msg } = response
            console.log(response);
            message.success(msg);



        }
        catch (error) {
            throw new Response('Unable to upload files', { status: 500 })
        }

    }





    return (
        <div>

            <div className={styles.stepBox}>
                <h3 style={{ 'text-align': 'start' }}>Task For You</h3>
                <div>
                    <Form
                        form={form}
                        // onFinish={onFinish}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >



                        <table style={{ 'width': '100%' }}>
                            <tbody>

                                <tr>
                                    <td>Quantity</td>
                                    <td>Delivery Address</td>
                                    <td>Ship Quantity</td>
                                    <td>Expected Dispatch Date</td>
                                    <td>Tracking URL</td>
                                    <td>Expected Delivery Date</td>

                                    <td></td>



                                </tr>


                                {data.item_delivery_address && data.item_delivery_address.map((value, index) => {



                                    return (



                                        <tr style={{ 'vertical-align': 'baseline' }} key={index}>

                                            <td>{value.quantity}</td>
                                            <td>{value.street_number},{value.street},{value.city}</td>
                                            <td>{value.ship_quantity}</td>
                                            <td><Form.Item

                                                name={"expected_dispatch_date" + index}
                                                rules={[{ required: true, message: 'Please input tracking url !' }]}

                                                initialValue={value.dispatch_date}
                                            >
                                                <DatePicker />
                                            </Form.Item></td>
                                            <td>{value.tracking_url}</td>
                                            <td>{value.expected_date}</td>
                                            <td>
                                                <Button onClick={() => {
                                                    const payload = {
                                                        "dispatch_date": form.getFieldValue()['expected_dispatch_date' + index]
                                                    }
                                                    console.log(payload);
                                                    handleDispatchDateSubmit(value.id, payload)
                                                }} type="primary" icon={<CheckOutlined />} />
                                            </td>
                                        </tr>
                                    )
                                }
                                )
                                }
                            </tbody>
                        </table>
                    </Form>
                </div>
            </div>
            <br />

            <CommentForClient comment={data.client_comment} />
            <br />
            <CommentForVendor comment={data.vendor_comment} />
        </div>

    )
}

DispatchDateConfirm.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default DispatchDateConfirm;


