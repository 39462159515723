import { DeleteOutlined, EyeOutlined, PlusSquareOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { forwardRef, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import styles from './addFile.module.scss';
const { TextArea } = Input

const getPreviewUrl = (file) => {
    if (/image\/[a-z+.-]+/.test(file.type)) {
        return file.preview
    }

    switch (file.type) {
        case "application/pdf":
            return "./icons/filetypes/pdf.svg"

        default:
            return "./icons/filetypes/file.svg"
    }
}

const AddFileUpload = forwardRef(function AddFileUpload(_, ref) {
    const [, setFiles] = useState([])

    useEffect(() => {
        if (!ref.fileUploaderRef.current) {
            ref.fileUploaderRef.current = []
        }

        // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
        return () => ref.fileUploaderRef.current.forEach(file => URL.revokeObjectURL(file.preview));
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        multiple: true,
        maxSize: 10 * 1024 * 1024,
        maxFiles: 10,
        accept: {
            'image/png': ['.png'],
            'image/jpg': ['.jpg'],
            'application/pdf': ['.pdf'],
            'image/tiff': ['.tiff', '.tif'],
            'image/svg+xml': ['.svg'],
            'image/vnd.adobe.photoshop': ['.psd'],
            'application/postscript': ['.eps'],
            'application/eps': ['.eps'],
            'application/x-eps': ['.eps'],
            'image/eps': ['.eps'],
            'image/x-eps': ['.eps'],
        },
        onDropRejected: rejections => {
            alert(rejections.map(({ errors }) => errors.map(({ message }) => message)).join("\n"))
        },
        onDrop: acceptedFiles => {
            acceptedFiles.forEach(file => {
                addFile(file);
            });
        }
    })

    const addFile = (file) => {
        setFiles(files => {
            return [...files, Object.assign(file, {
                preview: URL.createObjectURL(file)
            })]
        })
        ref.fileUploaderRef.current.push(file)
    }

    const addComment = (cmnt) => {
        ref.commentData.push(cmnt)

    }

    return (
        <>

            <div className={styles.files}>
                {ref.fileUploaderRef.current.length > 0 && (
                    ref.fileUploaderRef.current.map((file, index) => {

                        return (
                            <div className={styles.file} key={file.name}>
                                <img
                                    src={getPreviewUrl(file)}
                                    // Revoke data uri after image is loaded
                                    onLoad={() => { URL.revokeObjectURL(file.preview) }}
                                />
                                <p className="label">{file.name}</p>
                                <div style={{ 'width': '20%' }}>

                                    <TextArea onBlur={(e) => {
                                        addComment(e.target.value)
                                    }} name={index} label="Add a comment" placeholder="type your message here.." />

                                </div>

                                <div style={{ 'width': '20%' }}>
                                    {/* <a href.fileUploaderRef={Server.BaseUrl + '/api/v1/' + sample.url} download="sample.png">  */}
                                    <Button icon={<DeleteOutlined />} >Delete</Button>
                                    {/* </a> */}
                                    {/* <a href={Server.BaseUrl + '/api/v1/' + sample.url} target="blank" > */}
                                    <Button icon={<EyeOutlined />}>View</Button>

                                    {/* </a> */}

                                </div>


                            </div>)

                    }

                    )
                )}
            </div>

            <div {...getRootProps({ className: styles.dropArea })}>
                <div className={styles.dropIcon}>
                    <PlusSquareOutlined style={{ fontSize: '64px', color: 'lightgray' }} />


                </div>

                <input className="input-zone" {...getInputProps()} />
                <div className={`button--primary ${styles.uploadButton ?? ''}`}>Add File</div>
            </div>
        </>
    )
})

AddFileUpload.propTypes = {
    inputName: PropTypes.string,
}

export { AddFileUpload };

