import { AppstoreOutlined, BarsOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Segmented, Spin } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { getAllVendors } from '../../api/vendor/vendor';
import { KanabanView } from '../../components/data';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { generateRouteUrl, VendorColumns } from '../../library/constants/index.js';
// import { VendorDetails } from '../../components/form';



const VIEW_MODES = Object.freeze({
	LIST: "list",
	KANABAN: "kanaban",
})

const VendorListView = () => {
	const navigate = useNavigate()
	//const [filtersVisible, setFiltersVisible] = useState(false)
	const [loading, error, list, hasNoMore, /* page */, /* sort */, /* filters */, {
		nextPage,
		setSort,
		/* setFilters */
	}] = useFetch({
		functionToFetchData: getAllVendors
	})

	const loadData = (sort) => {
		setSort(sort)
	}

	const handleRowClick = (_, record) => {
		console.log(record.id);
		navigate(generatePath('/vendors/:id', {
			id: record.id
		}))
	}

	if (error) {
		throw new Error('Unable to load projects list')
	}

	return (
		<Table
			columns={VendorColumns}
			data={list}
			/* perPage={Number(meta?.limit)} currentPage={Number(meta?.page)} total={Number(meta?.totalCount)} */
			isLoading={loading}
			onChange={loadData}
			onRowClick={handleRowClick}
			hasMore={!hasNoMore}
			loadMore={nextPage}
		/>
	)
}

const ProjectsKanabanView = () => {
	const navigate = useNavigate()
	const [list, setList] = useState([])

	useEffect(() => {
		getAllVendors().then(({ data }) => {
			setList(data)
		})
	}, [])

	const handleRowClick = (_, record) => {
		navigate(generatePath('/vendor/:id', {
			id: record.id
		}))
	}

	if (!list) {
		return (
			<>
				<Spin />
			</>
		)
	}

	return (
		<KanabanView data={[...list]} columns={[
			{
				key: 1,
				title: 'New Request'
			},
			{
				key: 2,
				title: 'Call for more information'
			},
			{
				key: 3,
				title: 'Enquiry Request'
			},
			{
				key: 4,
				title: 'Quotation Send'
			},
		]} onItemClick={(project) => {
			handleRowClick(null, project)
		}} />
	)
}

function Vendors(props) {
	const navigate = useNavigate()
	const [viewMode, setViewMode] = useState(VIEW_MODES.LIST)
	return (
		<Page className='vendor-list' title={props.title} header={
			<>
				{/* <Button onClick={() => setFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button> */}
				<Button type="primary" onClick={() => { navigate(generateRouteUrl('VendorCreate')) }} icon={<PlusOutlined/>}>Add new Vendor</Button>
				<Segmented
					value={viewMode}
					options={[
						{
							value: VIEW_MODES.LIST,
							icon: <BarsOutlined />,
						},
						{
							value: VIEW_MODES.KANABAN,
							icon: <AppstoreOutlined />,
						},
					]}
					onChange={setViewMode}
				/>
			</>
		}>
			{/*  <Drawer open={filtersVisible} onClose={() => setFiltersVisible(false)} >
			<CustomerFilterForm data={filters} onFinish={(data) => setFilters(data)} />
		  </Drawer> */}
			{viewMode === VIEW_MODES.LIST
				? <VendorListView />
				: <ProjectsKanabanView />
			}

		</Page>
	)
}

Vendors.propTypes = {
	title: PropTypes.string,
}

export default Vendors
