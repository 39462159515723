import { Input, Select } from 'antd';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { ValidationForm as Form } from '../';
import PaymentTerms from '../../../library/dynamics/payment-terms';
import { CountrySelect, CurrencySelectDropDown, LanguageSelect, PhoneInput } from '../../inputs';
import styles from './vendor-details.module.scss';

import { Checkbox } from 'antd';


const validationRules = {
	company: [
		{
			required: true
		},
	],
	account_manager_id: [
		{
			required: true
		}
	],
	payment_condition_id: [
		{
			required: true
		}
	],
	// payment_term_id: [
	// 	{
	// 		required: true
	// 	}
	// ],
	vat_number: [
		{
			required: true
		}
	]
}


const options = [
	{
		label: 'Item',
		value: 1,
	},
	{
		label: 'Finisher',
		value: 2,
	},
	{
		label: 'Transport',
		value: 3,
	},
	{
		label: 'Duty Fees',
		value: 4,
	},
	{
		label: 'Accept Skonto',
		value: 5,
	},
];

const VendorDetails = ({ children, getValidationStatus,

	...otherProps }) => {
	const [form] = Form.useForm()
	const [value, setValue] = useState("");
	const onChange = (checkedValues) => {

		setValue(checkedValues)
		console.log(value);


		// form.setFieldsValue({
		// 	type: checkedValues
		// })
	};



	return (
		<Form
			form={form}
			name='vendorDetails'
			onValidationStatusChange={getValidationStatus ?? undefined}
			layout="vertical"
			validateTrigger={['onChange', 'onBlur']}
			className={styles.FormWrapper}
			validationRules={validationRules}
			{...otherProps}>
			<Form.Item name="company" label="Company Name" >
				<Input type="text" />

			</Form.Item>
			<Form.Item name="ph_no" label="Phone Number" >
				<PhoneInput valuePropName={{ code: 'phone_country_code', number: 'phone_number' }} />

			</Form.Item>
			<Form.Item name="mobile_no" label="Mobile Number" >
				<PhoneInput valuePropName={{ code: 'mobile_country_code', number: 'mobile_number' }} />
			</Form.Item>
			<Form.Item name="email" label="Email" onChange={() => {
				form.validateFields(['email']).then(({ email }) => {
					form.setFieldValue('website', email.split('@').pop())
				}
				).catch(() => { })
			}}>
				<Input type="email" />
			</Form.Item>
			<Form.Item name="website" label="website">
				<Input type="text" />
			</Form.Item>
			<Form.Item name='country_id' label="Country" >
				<CountrySelect onChange={(val, country) => {
					form.setFieldsValue({
						language_id: country.default_language_id,
						currency_id: country.currency_id,
						// contact_country_code: country.phone_number_code,
						// entity_id: country?.entity?.id,
					})
				}} />
			</Form.Item>
			<Form.Item name='language_id' label="Language" dependencies={['country_id']} >
				<LanguageSelect />
			</Form.Item>

			<Form.Item name='currency_id' label="Currency" dependencies={['country_id']} >
				<CurrencySelectDropDown />
			</Form.Item>
			<Form.Item name='payment_term_id' label="Payment Term">
				<Select options={PaymentTerms} fieldNames={{ value: 'id', label: 'name' }} />
			</Form.Item>

			{/* <Form.Item name="hubspot_Identifier" label="Hubspot Identifier">
				<Input type="text" />
			</Form.Item>

			<Form.Item name="twinfield_id_creditor" label="Twinfield ID Creditor">
				<Input type="text" />
			</Form.Item> */}
			<Form.Item name="vat_number" label="VAT Number">
				<Input type="text" />
			</Form.Item>


			{/* <Form.Item name="type" >  */}
			<br />



			<Checkbox.Group style={{
				'display': 'flex',
				'position': 'absolute',
				'width': '500px',
				'margin-top': '15%'
			}} options={options} defaultValue={['Item']} onChange={onChange}


			/>


			{/* </Form.Item>  */}


			{children}
		</Form>
	)
}

VendorDetails.propTypes = {
	children: PropTypes.node,
	onSubmit: PropTypes.func,
	getValidationStatus: PropTypes.func,
}

export default VendorDetails