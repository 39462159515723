import { AppstoreOutlined, BarsOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Segmented, Spin, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { getAllProjects } from '../../api/project/project.js';
import { KanabanView } from '../../components/data';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { generateRouteUrl, ProjectColumns } from '../../library/constants/index.js';
import styles from '../../components/form/project/project-details.module.scss'

const search = {
  'width': '50%',
  // 'display':'flex'
}
const VIEW_MODES = Object.freeze({
  LIST: "list",
  KANABAN: "kanaban",
})

const ProjectsListView = () => {
  const navigate = useNavigate()
  //const [filtersVisible, setFiltersVisible] = useState(false)
  const [loading, error, list, hasNoMore, /* page */, /* sort */, /* filters */, {
    nextPage,
    setSort,
    setFilters
  }] = useFetch({
    functionToFetchData: getAllProjects
  })

  const loadData = (sort) => {
    setSort(sort)
  }

  const handleRowClick = (_, record) => {
    navigate(generatePath('/projects/:id', {
      id: record.id
    }))
  }

  if (error) {
    throw new Error('Unable to load projects list')
  }

  return (
    <div >


      <div className={styles.searchHeader} >

        <Button type="primary" onClick={() => { navigate(generateRouteUrl('ProjectCreate')) }} icon={<PlusOutlined />}>Add new Project</Button>


        <div style={search}>
          <Input.Search placeholder="Search" prefix={<SearchOutlined />}

            onChange={(e) => setFilters({ 'search': e.target.value })



            }
          />
        </div>
      </div>
      <br />
      {/* <Button onClick={() => setFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button> */}
      <Table
        columns={ProjectColumns}
        data={list}
        /* perPage={Number(meta?.limit)} currentPage={Number(meta?.page)} total={Number(meta?.totalCount)} */
        isLoading={loading}
        onChange={loadData}
        onRowClick={handleRowClick}
        hasMore={!hasNoMore}
        loadMore={nextPage}
      />

    </div>


  )
}

const ProjectsKanabanView = () => {
  const navigate = useNavigate()
  const [list, setList] = useState([])

  useEffect(() => {
    getAllProjects().then(({ data }) => {
      setList(data)
    })
  }, [])

  const handleRowClick = (_, record) => {
    navigate(generatePath('/projects/:id', {
      id: record.id
    }))
  }

  if (!list) {
    return (
      <>
        <Spin />
      </>
    )
  }

  return (
    <KanabanView data={[...list]} columns={[
      {
        key: 1,
        title: 'New Request'
      },
      {
        key: 2,
        title: 'Call for more information'
      },
      {
        key: 3,
        title: 'Enquiry Request'
      },
      {
        key: 4,
        title: 'Quotation Send'
      },
    ]} onItemClick={(project) => {
      handleRowClick(null, project)
    }} />
  )
}


function Projects(props) {
  const [viewMode, setViewMode] = useState(VIEW_MODES.LIST)

  return (
    <Page className='projects-list' title={props.title} header={
      <>
        {/* <Button onClick={() => setFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button> */}
        {/* <Form.Item name="client_order_number" label="Client Order Number" > */}





        <Segmented
          value={viewMode}
          options={[
            {
              value: VIEW_MODES.LIST,
              icon: <BarsOutlined />,
            },
            {
              value: VIEW_MODES.KANABAN,
              icon: <AppstoreOutlined />,
            },
          ]}
          onChange={setViewMode}
        />
      </>
    }>
      {/*  <Drawer open={filtersVisible} onClose={() => setFiltersVisible(false)} >
        <CustomerFilterForm data={filters} onFinish={(data) => setFilters(data)} />
      </Drawer> */}
      {viewMode === VIEW_MODES.LIST
        ? <ProjectsListView />
        : <ProjectsKanabanView />
      }

    </Page>
  )
}

Projects.propTypes = {
  title: PropTypes.string
}

export default Projects
