import React from 'react'
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Form, Input } from 'antd';
//import { emailLogin } from '../../api/auth';
import PropTypes from 'prop-types';
import { LoginProvider } from '../../library/constants';
import styles from './login.module.scss'

const validationRules = {
  username: [
    {
      required: true,
      message: 'Please enter your Username!',
    },
  ],
  password: [
    {
      required: true,
      message: 'Please enter your Password!',
    },
  ],
}

const Login = ({ onLogin }) => {

  const handleSubmit = ({ username, password }) => {
    // emailLogin({}, {
    //   username, password
    // }).then(() => {
    //   window.location.href = '/';
    // })
    onLogin(LoginProvider.EMAIL, { username, password })
  };

  return (
    <div className="page login">
      <div className={styles.pageWrapper}>
        <div className={styles.imageWrapper}></div>
        <div className={styles.loginWrapper}>
          <img src='/images/logo.svg' className={styles.logo} />
          <Form onFinish={handleSubmit} size='large'>
            <Form.Item name="username">
              <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Username" rules={validationRules.username} />
            </Form.Item>
            <Form.Item name="password">
              <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" rules={validationRules.password} />
            </Form.Item>
            <Form.Item>
              <div className={styles.loginButtonWrapper}>
                <a className="login-form-forgot" href="" onClick={() => alert('Not implemented yet')}>
                  Forgot password
                </a>
                <Button type="primary" htmlType="submit" className="login-form-button">
                  Log in
                </Button>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>

      {/* Enter your components here for the page
      Do remember that a single JSX block can only have a single element. Use React.Fragments for using more than one */}

    </div>
  )
}

Login.propTypes = {
  onLogin: PropTypes.func,
}

export default Login