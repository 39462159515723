/* eslint-disable no-unused-vars */
import { add, debounce, isEqual } from 'lodash-es';
import { useCallback, useEffect, useReducer, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

let formatFilters = (obj) => obj ? Object.entries(obj).reduce((prev, [key, val]) => {
  if (!val) {
    delete (prev[key])
  }
  else {
    prev[`filter[${key}]`] = val
  }
  return prev
}, {}) : {}

const ACTIONS = Object.freeze({
  NEXTPAGE: 'nextPage',
  PREVPAGE: 'prevPage',
  RESETPAGE: 'resetPage',
  SET: 'set',
  SETFILTERS: 'setFilters',
  SETPAGE: 'setPage',
  SETSORT: 'setSort',
  SEARCHQUERY:'searchQuery'
})

const reducer = (state, action) => {
  try {
    if (![ACTIONS.NEXTPAGE, ACTIONS.PREVPAGE, ACTIONS.RESETPAGE].includes(action.type) && typeof action.value === 'undefined') {
      throw new Error('value require for Set')
      
    }
    switch (action.type) {
      case ACTIONS.SETFILTERS:
        return {
          ...state,
          ['filters']: action.value,
          ...(!isEqual(state?.filters, action.value) && { 'page': 1 })
        }

      case ACTIONS.SETPAGE:
        return { ...state, ['page']: action.value }

      case ACTIONS.RESETPAGE:
        return { ...state, ['page']: 1 }

      case ACTIONS.NEXTPAGE:
        return { ...state, ['page']: state.page + 1 }

      case ACTIONS.PREVPAGE:
        return { ...state, ['page']: state.page - 1 }

      case ACTIONS.SETSORT:
        return {
          ...state,
          ['sort']: action.value,
          ...(!isEqual(state?.sort, action.value) && { 'page': 1 })
        }

      case ACTIONS.SET: {
        const { page = 1, sort = {}, filters = {} } = action.value
        return {
          page: Number(page),
          sort: sort ?? {},
          filters: filters ?? {},
        }
      }
      case ACTIONS.SEARCHQUERY:{
        return{ ...state,
          ['searchQuery']:action.value
        }


      }

      default:
        throw new Error('Invalid action')
    }
  }
  catch (e) {
    console.log(e)
    return state
  }
}

function useFetch({ limit = 20, functionToFetchData }) {
  // const [page, setPage] = useState(initialPage);
  // const [sort, setSort] = useState(initialSort);
  // const [filters, setFilters] = useState(initialFilters);
  const [searchState, dispatchSearchState] = useReducer(reducer, {
    page: 1,
    limit,
    sort: {},
    filters: {},
    searchQuery:'testing'
  })
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [list, setList] = useState([]);
  const [hasNoMore, setHasNoMore] = useState(false);
  let [searchParams, setSearchParams] = useSearchParams();
  const resetList = useRef(false)

  const nextPage = useCallback(() => {
    dispatchSearchState({ type: ACTIONS.NEXTPAGE })
  }, [])

  const prevPage = useCallback(() => {
    dispatchSearchState({ type: ACTIONS.PREVPAGE })
  }, [])

  const setSort = useCallback((s) => {
    dispatchSearchState({ type: ACTIONS.SETSORT, value: s })
  }, [])

  const setFilters = useCallback((f) => {
    dispatchSearchState({ type: ACTIONS.SETFILTERS, value: f })
  }, [])

  const setSearchQuery = useCallback((q) => {
    dispatchSearchState({ type: ACTIONS.SEARCHQUERY, value: q })
  }, [])

  useEffect(() => {
    const params = ([...searchParams.entries()]).reduce((prev, [key, val]) => {
      //Check if filter
      const [, filterKey] = key.match(/filter\[(.+)\]/i) ?? []
      if (filterKey) {
        prev.filters = { ...prev.filters ?? {}, [filterKey]: val }
      }
      else {
        prev[key] = val
      }
      return prev
    }, {})

    dispatchSearchState({ type: ACTIONS.SET, value: params })

    return () => {
      setList([])
    }

  }, [])

  const sendQuery = useCallback(async (search, reset) => {
    try {
      setLoading(true);
      setError(false);
      const { page, sort, filters } = search
      const { sortBy = undefined, sortType = undefined } = sort
      const { data, meta, status, message = '' } = await functionToFetchData({ page, limit, sortBy, sortType, filter: filters})
      if (!status) {
        throw new Error(message)
      }
      setHasNoMore((meta.page >= meta.totalPages))
      if (reset) {
        setList([...data])
        resetList.current = false
      }
      else {
        setList(prevList => {
          //return [...prevList, ...data]
          return [...prevList, ...data].filter((value, index, self) =>
            index === self.findIndex((t) => (t.id === value.id))
          )
        })
      }
      setLoading(false)

    } catch (err) {
      console.dir(err)
      setError(err);
    }
  }, [searchState]);

  // const sendQueryDebounced = useRef(debounce(sendQuery, 300, {
  //   trailing: true
  // })).current

  const sendQueryDebounced = debounce(sendQuery, 300)

  useEffect(() => {
    setList([])
    //resetPage()
    setHasNoMore(false)
    resetList.current = true
  }, [searchState.sort, searchState.filters]);

  useEffect(() => {
    const { page, sort, filters } = searchState
    setSearchParams({
      ...formatFilters(filters),
      ... (page && (Number(page) !== 1) && { page }),
      ... (sort.sortBy && { sortBy: sort.sortBy }),
      ... (sort.sortType && { sortType: sort.sortType }),
    })

    if (!hasNoMore) {
      sendQueryDebounced(searchState, resetList.current)
    }
  }, [searchState]);


  return [loading, error, list, hasNoMore, searchState.page, searchState.sort, searchState.filters, {
    nextPage,
    prevPage,
    setSort,
    setFilters,
    setSearchQuery
  }];
}

export default useFetch;
