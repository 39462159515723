 import { Button, Checkbox, Collapse, Row, message } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { useActionData, useLoaderData, useNavigate, useParams } from 'react-router-dom';
import { uploadFileFromObject } from '../../api/common';
import { Page } from '../../components/page';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { ActionError } from '../../library/classes/exceptions';
import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { sendStudioArtworktoVendor, sendStudioArtworktoclient } from '../../api/studio';
import { getStudioDetails, sendTemplateFilestoClient } from '../../api/studio/studio';
import styles from '../../components/data/production/item.module.scss';
import style from '../../components/general.module.scss';
import { Server } from "../../library/constants/server";
import { AddFileUpload, Image, Message, MessageTypes } from '../../ui';

const { Panel } = Collapse

const StudioItemDetails = ({ title }) => {
    const { studioDetails } = useLoaderData({});
    const actionData = useActionData()
    const fileUploaderRef = useRef([])
    let commentData=[]
    const { quotationToken } = useParams()
    let imageData = []


    const handleFilesUpload = async () => {

        let finalData = []
        imageData.map((values) => {
            if (values?.url) {
                finalData.push(values.url)
            }

        })
     const promises = fileUploaderRef.current.map(file => uploadFileFromObject(quotationToken, file))
        try {

        
       const fileIds = await Promise.all(promises) 
            const imagesIdData=[]

           

            fileIds.map((value,key) => {
                console.log(commentData[key],value);
                imagesIdData.push({id : value,comment:commentData[key]})

            })

            console.log(imageData);
        
            const response = await studioDetails.studio_status==1 ?
            sendTemplateFilestoClient(studioDetails.id, {images:finalData ,image_ids:imagesIdData} ):
            studioDetails.studio_status==3 ?
            sendStudioArtworktoVendor(studioDetails.id, {images:finalData ,image_ids:imagesIdData} ):
            studioDetails.studio_status==5 ?
            sendStudioArtworktoclient(studioDetails.id, {images:finalData ,image_ids:imagesIdData} ):
            console.log(studioDetails.studio_status);
            
                const { message: msg } = response
           
                message.success(msg);
              
        }
        catch (error) {
            console.log(error);
            throw new Response('Unable to upload files', { status: 500 })
        }
    }

    const navigate = useNavigate()
    return (
        <Page title={title} header={<Button type="primary" onClick={() => { navigate(generateRouteUrl('studio-list')) }}>Back</Button>} >
        <Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header='Item Details' key="1"> 

            <div >

                <h3>#{studioDetails.product.name}</h3>
                <p>Product Link from vendor:  {studioDetails?.product_link ?? ''} </p>

                <div className={styles.tabContainer} >
                

                <p style={{ 'margin-top': '16px', 'margin-bottom': '16px' }}>Specifications : </p>


                <div className={styles.wrapper}>

                    <div className={styles.right}>

                        <div className={styles.specifications}>
                            {studioDetails.specification.map(({ name, value }) => (
                                <React.Fragment key={name}>
                                    <div className={styles.name}>{name}</div>
                                    <div className={styles.value}>{value}</div>
                                </React.Fragment>
                            ))}
                        </div>

                        {/* <QuotationItemQuantities prices={item.prices} selectedId={selectedId} onSelectionChange={(selected) => {
    onSelectionChange({ itemId: item.id, priceId: selected })
}} /> */}
                    </div>

                </div>

                </div>





            </div>

            </Panel>
            </Collapse>

           
         



            <Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header={studioDetails.studio_status==1 ? 'Vendor Template':studioDetails.studio_status==3? 'Customer Artwork' : 'Vendor Proof' } key="1">
                    {imageData = []}

                    

                    {
                    studioDetails.studio_status==1 ?

                    studioDetails.document_details?.order_upload_template_for_studio && studioDetails.document_details.order_upload_template_for_studio.map((sample, index) => {

                        console.log(sample);
                        return (<div className={style.itemDetail} key={index}>

                            <Checkbox onChange={(e) => {

                                if (e.target.checked == true) {
                                    imageData[index] = { url: sample.url, comment: '' }
                                }
                                else {
                                    delete imageData[index]
                                }









                            }} />

                            <Image src={Server.BaseUrl + '/api/v1/' + sample.url} height={100} width={90} className={style.imageBox} />
                            <h4>{sample.name}</h4>
                            <div style={{ 'width': '25%' }}>

                                <p><b>Commnet from vendor : {sample.comment}</b></p>
                            </div>
                            <Row>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} download="sample.png"> <Button icon={<DownloadOutlined />} >Download</Button> </a>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} target="blank" ><Button icon={<EyeOutlined />}>View</Button> </a>
                            </Row>


                        </div>)
                    }) : 

                    studioDetails.studio_status==3 ?


                    
                    studioDetails.document_details?.order_upload_client_artwork_for_studio && studioDetails.document_details.order_upload_client_artwork_for_studio.map((sample, index) => {

                        console.log(sample);
                        return (<div className={style.itemDetail} key={index}>

                            <Checkbox onChange={(e) => {

                                if (e.target.checked == true) {
                                    imageData[index] = { url: sample.url, comment: '' }
                                }
                                else {
                                    delete imageData[index]
                                }









                            }} />

                            <Image src={Server.BaseUrl + '/api/v1/' + sample.url} height={100} width={90} className={style.imageBox} />
                            <h4>{sample.name}</h4>
                            <div style={{ 'width': '25%' }}>

                                <p><b>Commnet from vendor : {sample.comment}</b></p>
                            </div>
                            <Row>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} download="sample.png"> <Button icon={<DownloadOutlined />} >Download</Button> </a>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} target="blank" ><Button icon={<EyeOutlined />}>View</Button> </a>
                            </Row>


                        </div>)
                    })

                    :studioDetails.studio_status==5 ?

                    studioDetails.document_details?.order_upload_vendor_artwork_for_studio && studioDetails.document_details.order_upload_vendor_artwork_for_studio.map((sample, index) => {

                        console.log(sample);
                        return (<div className={style.itemDetail} key={index}>

                            <Checkbox onChange={(e) => {

                                if (e.target.checked == true) {
                                    imageData[index] = { url: sample.url, comment: '' }
                                }
                                else {
                                    delete imageData[index]
                                }









                            }} />

                            <Image src={Server.BaseUrl + '/api/v1/' + sample.url} height={100} width={90} className={style.imageBox} />
                            <h4>{sample.name}</h4>
                            <div style={{ 'width': '25%' }}>

                                <p><b>Commnet from vendor : {sample.comment}</b></p>
                            </div>
                            <Row>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} download="sample.png"> <Button icon={<DownloadOutlined />} >Download</Button> </a>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} target="blank" ><Button icon={<EyeOutlined />}>View</Button> </a>
                            </Row>


                        </div>)
                    }) : <div></div>


                
                
                
                
                }








                </Panel>
            </Collapse>




            <Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
            <Panel header='Upload Revices Artwork' key="1">

            <div>
                {!!actionData && actionData instanceof ActionError && <Message type={MessageTypes.ERROR} message={actionData.message} timeOut={3} />}
                {actionData === true && <Message type={MessageTypes.SUCCESS} message="File Uploaded Successfully" timeOut={3} />}
                <AddFileUpload ref={{fileUploaderRef ,commentData}}  />
                <i>Supported formats : PDF ,ESP,PSD,AI,TIFF,SVG</i>




            </div>
            </Panel>
            </Collapse>


            <Button onClick={handleFilesUpload}>Send</Button>




        </Page>
    )

}

StudioItemDetails.Loader = async ({ params }) => {


    if (isNaN(params.id)) {
        throw new Error('Invalid Orrder ID')
    }
    const studioDetails = await getStudioDetails(params.id)
    if (!studioDetails) {
        throw new Error('Invalid Quoation')
    }

    console.log(studioDetails);

    // setCurrent(checkStatus(quotationDetails))






    // const basicquotationDetails = (({
    // 	vendorData, item, specifications
    // }) => ({
    // 	vendorData, item, specifications
    // }))(quotationDetails)



    return { studioDetails }
}


StudioItemDetails.propTypes = {
    title: PropTypes.string,
}
export default StudioItemDetails