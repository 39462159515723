import { Layout } from "antd";
import { intersection } from "lodash-es";
import React from "react";
import { Outlet } from "react-router-dom";
import AuthManager from './auth-manager';
import { Sidebar } from "./components/sidebar";
import { routesList } from "./library/constants";
import { AuthProvider } from './providers/auth';

export default function AuthenticatedPage() {
  // These user roles can later on come from the profile based on authentication
  const userRoles = [999, 102];
  const routes = routesList.filter(
    (item) => intersection(item.roles_allowed, userRoles).length > 0
  );

  return (
    <AuthProvider>
      <AuthManager>
        <Layout hasSider style={{ backgroundColor: '#f3f2f7' }}>
          <Sidebar routes={routes} />
          <Layout>
            <Outlet />
          </Layout>
        </Layout>
      </AuthManager>
    </AuthProvider>
  );
}
