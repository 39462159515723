import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import styles from './message.module.scss';

const MessageTypes = {
    ERROR: "error",
    SUCCESS: "success",
    INFO: "info"
}

const Message = ({ type, message, timeOut = null }) => {
    const [isVisible, setIsVisible] = useState(true)

    useEffect(() => {
        if (timeOut && timeOut > 0) {
            const timer = setTimeout(() => {
                setIsVisible(false)
            }, timeOut * 1000)

            return () => {
                clearTimeout(timer);
                setIsVisible(true);
            };
        }
    }, [])

    useEffect(() => {
        setIsVisible(true)
    }, [message, type, timeOut])


    if (!isVisible) {
        return
    }
    return <div className={`${styles.wrapper} ${styles[type]}`}>{message}</div>
}

Message.propTypes = {
    message: PropTypes.string.isRequired,
    type: PropTypes.oneOf(Object.values(MessageTypes)).isRequired,
    timeOut: PropTypes.number,
}

export { MessageTypes };

export default Message