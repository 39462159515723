import React from 'react';
import { ContactCreate, ContactDetails, Contacts } from "../../routes/contacts";
import { CustomerCreate, CustomerDetails, Customers } from "../../routes/customers";
import { Dashboard } from "../../routes/dashboard";
import { ProjectCreate, ProjectUpdate, Projects, SendClientQuotation } from "../../routes/projects";
import { VendorCreate, VendorUpdate, Vendors } from "../../routes/vendors";

import {
  BarChartOutlined, DropboxOutlined, SettingOutlined,
  ShoppingCartOutlined,
  TeamOutlined,
  UserOutlined,
  UsergroupDeleteOutlined
} from '@ant-design/icons';
import { generatePath } from 'react-router-dom';
import { Finance } from "../../routes/finance";
import { OrderDetails, OrderManagerList } from '../../routes/orderManager/index';
import { ProjectItemCreate, ProjectItemUpdate } from '../../routes/projects/items';
import { Settings } from "../../routes/settings";
import { StudioItemDetails, StudioList } from '../../routes/studio';
import { Users } from "../../routes/users";
import { UserRoleTypes } from './userRoles';

import { CustomerArtwork, SendDirectArtwork, SendReadyArtwork, SendReadyProof, SendReadyTemplate, UploadSampleTemplate, VendorArtwork } from '../../routes/template';

export const routesList = [
  {
    label: 'Dashboard',
    title: 'Dashboard',
    url: '/',
    component: Dashboard,
    icon: <BarChartOutlined />,
    showOnMenu: true,
    roles_allowed: [UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  },
  {
    label: 'Projects',
    title: 'Projects',
    url: '/projects',
    //component: Projects,
    icon: <ShoppingCartOutlined />,
    showOnMenu: true,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
    children: [
      {
        label: 'Create Project',
        title: 'Create Project',
        url: '/projects/create',
        component: ProjectCreate,
        showOnMenu: true,
        roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
      },
      {
        label: 'Project List',
        title: 'Project List',
        component: Projects,
        showOnMenu: true,
        roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
      },
      {
        url: '/projects/:projectId',
        showOnMenu: false,
        roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
        children: [
          {
            label: 'Update Project',
            title: 'Update Project',
            component: ProjectUpdate,
            showOnMenu: false,
            roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
          },
          {
            label: 'Send Quotation to Client',
            title: 'Send Quotation to Client',
            url: '/projects/:projectId/client-quotation',
            component: SendClientQuotation,
            showOnMenu: false,
            roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
          },
          {
            label: 'Create Project Item',
            title: 'Create Item',
            url: '/projects/:projectId/items/create',
            component: ProjectItemCreate,
            showOnMenu: false,
            roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
          },
          {
            label: 'Update Project Item',
            title: 'Project Item',
            url: '/projects/:projectId/items/:itemId',
            component: ProjectItemUpdate,
            showOnMenu: false,
            roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
          }
        ]
      },
    ]
  },
  {
    label: 'Customers',
    title: 'Customers',
    url: '/customers',
    //nolink: true,
    icons: <UsergroupDeleteOutlined />,
    showOnMenu: true,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
    children: [
      {
        label: 'Company',
        title: 'Company',
        component: Customers,
        showOnMenu: true,
        roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
      },
      {
        label: 'Customers Details',
        title: 'Customers Details',
        url: '/customers/:id',
        component: CustomerDetails,
        showOnMenu: false,
        roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
      },
      {
        label: 'Create Customer',
        title: 'Create Customer',
        url: '/customers/create',
        component: CustomerCreate,
        showOnMenu: false,
        roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
      },

      {
        label: 'Contacts',
        title: 'Contacts',
        url: '/customers/contacts',
        showOnMenu: true,
        roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
        children: [
          {
            label: 'Contacts',
            title: 'Contacts',
            component: Contacts,
            showOnMenu: false,
            roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
          },
          {
            label: 'Create Contact',
            title: 'Create Contact',
            url: '/customers/contacts/create',
            component: ContactCreate,
            showOnMenu: false,
            roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
          },
          {
            label: 'Contacts',
            title: 'Contacts',
            url: ':id',
            component: ContactDetails,
            showOnMenu: false,
            roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
          },
        ]
      },
    ]
  },

  {
    label: 'Studio',
    title: 'Studio',
    url: '/studio-list',
    component: StudioList,
    showOnMenu: true,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  }, {
    label: 'Studio Item Details',
    title: 'Studio Item Details',
    url: '/studio-item-details/:id',
    component: StudioItemDetails,
    showOnMenu: false,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  },
  {
    label: 'Order Manager',
    title: 'Order Manager',
    url: '/order-manager-list',
    component: OrderManagerList,
    showOnMenu: true,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  },
  {
    label: 'Order Manager',
    title: 'Order Manager',
    url: '/order-manager/:id',
    component: OrderDetails,
    showOnMenu: false,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  },
  {
    label: 'Upload Sample Template',
    title: 'Upload Sample Template',
    url: '/upload-sample-template/:id',
    component: UploadSampleTemplate,
    showOnMenu: false,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  },

  {
    label: 'Send ready artwork to vendor',
    title: 'Send ready artwork to vendor',
    url: '/send-ready-artwork/:id',
    component: SendReadyArtwork,
    showOnMenu: false,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  },
  {
    label: 'Send Ready Proof To Customer',
    title: 'Send Ready Proof To Customer',
    url: '/send-ready-proof/:id',
    component: SendReadyProof,
    showOnMenu: false,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  },
  {
    label: 'Send ready Template to Customer',
    title: 'Send ready Template to Customer',
    url: '/send-ready-template/:id',
    component: SendReadyTemplate,
    showOnMenu: false,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  },
  {
    label: 'Customer Artwork',
    title: 'Customer Artwork',
    url: '/customer-artwork/:id',
    component: CustomerArtwork,
    showOnMenu: false,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  },

  {
    label: 'Send Artwork',
    title: 'Send Artwork',
    url: '/send-direct-artwork/:id',
    component: SendDirectArtwork,
    showOnMenu: false,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  },


  {
    label: 'Vendor Artwork',
    title: 'Prinatable Final Proof',
    url: '/vendor-artwork/:id',
    component: VendorArtwork,
    showOnMenu: false,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  },
  {
    label: 'Vendors',
    title: 'Vendors',
    url: '/vendors',
    icon: <TeamOutlined />,
    // component: Vendors,
    showOnMenu: true,
    roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
    children:
      [{
        label: 'Add New Vendor',
        title: 'Add New Vendor',
        url: '/vendors/create',
        component: VendorCreate,
        showOnMenu: true,
        roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
      },
      {
        label: 'Vendors',
        title: 'Vendors',
        url: '/vendors/list',
        component: Vendors,
        showOnMenu: true,
        roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
      },
      {
        label: 'Vendor Details',
        title: 'Vendor Details',
        url: '/vendors/:id',
        component: VendorUpdate,
        showOnMenu: false,
        roles_allowed: [UserRoleTypes.DataEntry, UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
      },


      ]
  },
  {
    label: 'Finance',
    title: 'Finance',
    url: '/finance',
    icon: <DropboxOutlined />,
    component: Finance,
    showOnMenu: true,
    roles_allowed: [UserRoleTypes.SuperAdmin, UserRoleTypes.Admin, UserRoleTypes.Supervisor],
  },
  {
    label: 'Users',
    title: 'Users',
    url: '/users',
    icon: <UserOutlined />,
    component: Users,
    showOnMenu: true,
    roles_allowed: [UserRoleTypes.SuperAdmin, UserRoleTypes.Admin],
  },
  {
    label: 'Settings',
    title: 'Settings',
    url: '/settings',
    icon: <SettingOutlined />,
    component: Settings,
    showOnMenu: true,
    roles_allowed: [UserRoleTypes.SuperAdmin],
  }
];

const flattenRoutes = (route, parent = []) => (
  route.children ?
    [{ url: route?.url ?? parent, component: route?.component }, ...route.children.map(_r => flattenRoutes(_r, [...parent, route?.url]))] :
    { url: route?.url ?? parent, component: route?.component }
)

export const generateRouteUrl = (componentName, params = {}) => {
  const matchedRoute = routesList.map(route => flattenRoutes(route))
    .flat(Infinity)
    .filter(route => route?.component)
    .find(route => route?.component?.name === componentName)

  if (!matchedRoute)
    throw new Error(`Unable to generate URL: Invalid Route Component: ${componentName}`)

  let url = ''
  if (Array.isArray(matchedRoute.url)) {
    for (const i of matchedRoute.url.reverse()) {
      url += i
      if (i.startsWith('/')) break
    }
  }
  else {
    url = matchedRoute.url
  }

  return generatePath(url, params)
}