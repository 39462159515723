import {
	Input
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { ValidationForm as Form } from '../';
import { DatePicker } from '../../inputs';
import ProductSelect from '../../inputs/ProductSelect.jsx';
import styles from './project-item.module.scss';

const ProjectDetails = ({ children, getValidationStatus, handleProductChange, product, isUpdate, ...otherProps }) => {
	const [form] = Form.useForm()

	const validationRules = {
		product_id: [
			{
				required: true,
				type: 'number'
			}
		]
	}

	return (
		<Form form={form} name='projectItemDetails' onValidationStatusChange={getValidationStatus ?? undefined} layout="vertical" className={styles.FormWrapper} validationRules={validationRules} {...otherProps}>
			<Form.Item name="product_id" label="Product" className={styles['FormField--FullCol']} getValueFromEvent={({ id }) => id}>
				<ProductSelect language='en' currentSelection={product ?? null} onChange={handleProductChange ?? undefined} disabled={isUpdate} />
			</Form.Item>
			<Form.Item name="expected_delivery_period" label="Expected Delivery Period">
				<Input />
			</Form.Item>
			
			<Form.Item name="delivery_date" label="Delivery Date">
				<DatePicker />
			</Form.Item>
			<Form.Item name="client_expected_delivery_date" label="Client's Expected Delivery Date">
				<DatePicker />
			</Form.Item>
			{children}
		</Form>
	)
}

ProjectDetails.propTypes = {
	children: PropTypes.node,
	onSubmit: PropTypes.func,
	getValidationStatus: PropTypes.func,
	handleProductChange: PropTypes.func,
	product: PropTypes.object,
	isUpdate: PropTypes.bool,
}

export default ProjectDetails