
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import PropTypes from 'prop-types';

import React from "react";
import { CommentForClient, CommentForVendor } from '../index';



const TemplateSenttoStudio = ({ data }) => {
    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'textAlign': 'start' }}>Task For You</h3>
            <div className={styles.message}>
                <ul>
                    <li> Template has been sent to studio. Waiting from studio department.
                        {/* <span style={{ "float": 'right' }}><Button onClick={handleRequestTemplate}>Upload Artwork</Button></span> */}
                    </li>

                </ul>
            </div>
        </div>
        <br />
        <br />

        <CommentForClient comment={data.client_comment} />
        <CommentForVendor comment={data.vendor_comment} />


    </div>
}

TemplateSenttoStudio.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    //logs: PropTypes.arrayOf(PropTypes.object).isRequired
    //  changeState: PropTypes.func
}

export default TemplateSenttoStudio;