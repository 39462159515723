import React from "react";
import PropTypes from 'prop-types'
import {
    ArtWorkRejected,
    ArtworkReady, ArtworkReceived,
    ArtworkSentToStudio,
    CancelRequest,
    DelayInDelivery, Delivered, DispatchDateConfirm, InProduction, MakeArtWork, NewItem, OnHold,
    OrderSent,
    ProductDispatch,
    ProofReady,
    ProofRecieved, ProofRejected, ProofRejectedbyCustomer,
    ProofRequested,
    ProofSentToStudio,
    ProofSenttoCustomer, ReadyForProduction, RequestTemplatefromVendor, SendArtworktoVendor, SendTemplate,
    TemplateReady,
    TemplateRecievedFromVendor,
    TemplateSenttoStudio,
    WaitingForFile
} from "./index";



const StepBody = ({ i, data, activitylogs, stateChanger }) => {
    console.log(i);
    // console.log("5555222131242451235351351353513513453124");

    // console.log(data);
    // console.log(activitylogs);
    // console.log("5555222131242451235351351353513513453124");

    return (

        i == 1 ? <NewItem data={data} logs={activitylogs} changeState={stateChanger} /> :
            i == 2 ? <OrderSent data={data} logs={activitylogs} /> :
                i == 3 ? <WaitingForFile data={data} logs={activitylogs} /> :
                    i == 4 ? <RequestTemplatefromVendor data={data} logs={activitylogs} /> :
                        i == 5 ? <TemplateRecievedFromVendor data={data} logs={activitylogs} /> :
                            i == 6 ? <TemplateSenttoStudio data={data} logs={activitylogs} /> :
                                i == 7 ? <TemplateReady data={data} logs={activitylogs} /> :
                                    i == 8 ? <SendTemplate data={data} logs={activitylogs} /> :
                                        i == 9 ? <MakeArtWork data={data} logs={activitylogs} /> :
                                            i == 10 ? <SendArtworktoVendor data={data} logs={activitylogs} /> :
                                                i == 11 ? <ArtworkReceived data={data} logs={activitylogs} stateChanger={stateChanger} /> :
                                                    i == 12 ? <ArtWorkRejected data={data} logs={activitylogs} /> :
                                                        i == 13 ? <ArtworkSentToStudio data={data} logs={activitylogs} /> :
                                                            i == 14 ? <ArtworkReady data={data} logs={activitylogs} /> :
                                                                i == 15 ? <ProofRequested data={data} logs={activitylogs} /> :
                                                                    i == 16 ? <ProofRecieved data={data} logs={activitylogs} /> :
                                                                        i == 17 ? <ProofRejected data={data} logs={activitylogs} /> :
                                                                            i == 18 ? <ProofSentToStudio data={data} logs={activitylogs} /> :
                                                                                i == 19 ? <ProofReady data={data} logs={activitylogs} /> :
                                                                                    i == 20 ? <ProofSenttoCustomer data={data} logs={activitylogs} /> :
                                                                                        i == 21 ? <ProofRejectedbyCustomer data={data} logs={activitylogs} /> :
                                                                                            i == 22 ? <ReadyForProduction data={data} logs={activitylogs} /> :
                                                                                                i == 23 ? <InProduction data={data} logs={activitylogs} changeState={stateChanger} /> :
                                                                                                    i == 24 ? <DispatchDateConfirm data={data} logs={activitylogs} changeState={stateChanger} /> :
                                                                                                        i == 25 ? <ProductDispatch data={data} logs={activitylogs} changeState={stateChanger} /> :
                                                                                                            i == 26 ? <Delivered data={data} logs={activitylogs} /> :
                                                                                                                i == 27 ? <DelayInDelivery data={data} logs={activitylogs} /> :
                                                                                                                    i == 28 ? <OnHold data={data} logs={activitylogs} /> :
                                                                                                                        i == 29 ? <CancelRequest data={data} logs={activitylogs} /> :

                                                                                                                            <div></div>



    );
}

StepBody.propTypes = {
    activitylogs: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    stateChanger: PropTypes.func,
    i: PropTypes.number

}

export default StepBody