import React from 'react';
import PropTypes from 'prop-types'
import Entities from '../../library/dynamics/entities'
import { Select } from 'antd'
const { Option } = Select

function ExecutiveEntitySelect({value = null, onChange }) {
    return (
        <Select
            optionLabelProp="label"
            onChange={onChange}
            value={value}
        >
            {Entities.map((entity) => (
                 <Option key={entity.id} value={entity.id} label={entity.name} >
                    {entity.name}
                </Option>
            ))}
        </Select>
    );
}

ExecutiveEntitySelect.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func
}

export default ExecutiveEntitySelect;