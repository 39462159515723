export class AuthenticationError extends Error {
    constructor(onLogin) {
        super("You are required to login")
        this.onLogin = onLogin
    }
}

export class ActionError extends Error {
    constructor(message, data = null) {
        super(message)
        this.data = data
        this.name = this.constructor.name
    }
}