import { Form } from 'antd';
import { isEqual } from 'lodash-es';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormProvider, useValidationForm } from '../../providers/form';


const ValidationForm = ({ form, entityId, name, children, validationRules, onFinish, onValidationStatusChange, ...otherProps }) => {
	const [isValid, setIsValid] = useState(false)

	const handleChange = async () => {
		const checkValidation =
			(form.isFieldsTouched(Object.keys(validationRules), true)
				|| !Object.values(form.getFieldsValue(Object.keys(validationRules))).some(v => (v === undefined || v?.length === 0))) &&
			!form.getFieldsError().some(({ errors }) => errors.length > 0)
		setIsValid(checkValidation)
	}
	useEffect(() => {
		if (onValidationStatusChange)
			onValidationStatusChange(isValid)
	}, [])

	useEffect(() => {
		if (onValidationStatusChange)
			onValidationStatusChange(isValid)
	}, [isValid])

	const handleFormFinish = (data) => {
		if (onFinish) {
			onFinish(data, entityId, (_exception) => {
				if (_exception.type === 'ValidationError' && _exception?.errors) {
					handleServerErrors(form, _exception.errors)
					return true
				}
				else {
					return false
				}
			})
		}
	}

	return (
		<FormProvider validationRules={validationRules}>
			<Form name={name} form={form} layout="vertical" onFieldsChange={onValidationStatusChange ? handleChange : false} onFinish={handleFormFinish} validateTrigger={otherProps.validateTrigger ?? ['onChange']} onFinishFailed={(values, errorFields, outOfDate) => console.log(values, errorFields, outOfDate)} {...otherProps} >
				{children}
			</Form>
		</FormProvider>
	)
}

ValidationForm.propTypes = {
	form: PropTypes.object.isRequired,
	entityId: PropTypes.number,
	name: PropTypes.string.isRequired,
	children: PropTypes.node.isRequired,
	data: PropTypes.object,
	validationRules: PropTypes.object.isRequired,
	onFinish: PropTypes.func,
	onValidationStatusChange: PropTypes.func,
}

const Item = ({ children, name, ...otherProps }) => {
	const validationRules = useValidationForm();
	return (
		<Form.Item name={name ?? false} rules={name && validationRules[name] ? validationRules[name] : false} {...otherProps}>
			{children}
		</Form.Item>
	)
}
Item.propTypes = {
	children: PropTypes.node.isRequired,
	name: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
}

ValidationForm.Item = Item
ValidationForm.List = Form.List
ValidationForm.ErrorList = Form.ErrorList
ValidationForm.useForm = Form.useForm
ValidationForm.useFormInstance = Form.useFormInstance
ValidationForm.useWatch = Form.useWatch
ValidationForm.Provider = Form.Provider

export default ValidationForm

export const handleServerErrors = (form, errors) => {
	const fields = Object.entries(form.getFieldsValue()).map(([key, value]) => {
		if (!Array.isArray(key)) {
			key = [key]
		}
		return {
			name: key,
			errors: errors.filter(({ path }) => isEqual(path, key))?.map((({ message }) => message)) ?? [],
			touched: true,
			value,
		}
	})
	form.setFields(fields)
}