import { DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Button, Checkbox, Collapse, Input, Row, Tabs, message } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useLoaderData, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchArtworkDetails, sendProoftoClient, sendVendorArtworktoStudio } from '../../api/order';
import OrderManagerStudio from '../../components/data/studio/orderMangerStudio';
import SendToStudio from '../../components/data/studio/sendTOstudio';
import style from '../../components/general.module.scss';
import { Page } from '../../components/page';
import { generateRouteUrl } from '../../library/constants/routes.js';
import { Server } from "../../library/constants/server";
import { Image } from '../../ui';
const { Panel } = Collapse

const { TextArea } = Input
const VendorArtwork = ({ title }) => {
    const { artworkDetails } = useLoaderData({});
    let imageData = []
    const { state } = useLocation();
    let { id } = useParams();
    const handleSubmit = async () => {
        let finalData = []
        imageData.map((values) => {
            if (values?.url) {
                finalData.push(values.url)
            }

        })



        try {
            const response = await sendVendorArtworktoStudio(id, { images: finalData, comment: 'Daily soap', image_ids: [] })
            const { message: msg } = response
            console.log(response);
            message.success(msg)

        }
        catch (e) {
            message.error(e.message)
            throw e
        }


    }

    const handleSendtoCustomewr = async () => {
        let finalData = []
        imageData.map((values) => {
            if (values?.url) {
                finalData.push(values.url)
            }

        })



        try {
            const response = await sendProoftoClient(id, { images: finalData, comment: 'Daily soap', image_ids: [] })
            const { message: msg } = response
            console.log(response);
            message.success(msg)

        }
        catch (e) {
            message.error(e.message)
            throw e
        }


    }

    const navigate = useNavigate()
    return (

        <Page title={title} header={<Button type="primary" onClick={() => { navigate(generateRouteUrl('order-manager-list')) }}>Back</Button>} >
            <Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
                <Panel header='Customer Artwork' key="1">
                    {imageData = []}


                    {artworkDetails.vendor_proof.map((sample, index) => {

                        console.log(sample);
                        return (<div className={style.itemDetail} key={index}>

                            <Checkbox onChange={(e) => {

                                console.log(index);

                                console.log(e.target.checked);
                                if (e.target.checked == true) {
                                    imageData[index] = { url: sample.url, comment: '' }
                                }
                                else {
                                    delete imageData[index]
                                }









                            }} />

                            <Image src={Server.BaseUrl + '/api/v1/' + sample.url} height={100} width={90} className={style.imageBox} />
                            <h4>{sample.name}</h4>
                            <div style={{ 'width': '25%' }}>
                                <p><b>Commnet from vendor : TODO TODOTODOTODOTODOTODOTODO</b></p>
                            </div>
                            <Row>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} download="sample.png"> <Button icon={<DownloadOutlined />} >Download</Button> </a>
                                <a href={Server.BaseUrl + '/api/v1/' + sample.url} target="blank" ><Button icon={<EyeOutlined />}>View</Button> </a>
                            </Row>


                        </div>)
                    })


                    }
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />

                    <hr />

                    <br />
                    <br />
                    <br />

                    <Tabs
                        defaultActiveKey="1"
                        type="card"


                        items={
                            [{

                                label: 'Send to Studio',
                                key: 1,
                                children: <SendToStudio data={state} heading={'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam vitae odio mollitia reiciendis, quibusdam vel incidunt aut ut consequatur velit deserunt dicta non consequuntur possimus sequi aliquid. Ad, nesciunt expedita!'} onSubmit={handleSubmit} />
                            },
                            {

                                label: 'Order Manager (Studio)',
                                key: 2,
                                children: <OrderManagerStudio data={state} heading={'Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam vitae odio mollitia reiciendis, quibusdam vel incidunt aut ut consequatur velit deserunt dicta non consequuntur possimus sequi aliquid. Ad, nesciunt expedita!'} imageIds={imageData} />
                            },
                            {

                                label: 'Send to Customer',
                                key: 3,
                                children: <div className={style.tabBody}>Lorem, ipsum dolor sit amet consectetur adipisicing elit. Totam vitae odio mollitia reiciendis, quibusdam vel incidunt aut ut consequatur velit deserunt dicta non consequuntur possimus sequi aliquid. Ad, nesciunt expedita!
                                    <br />

                                    <div>
                                        <h3 style={{ 'text-align': 'start' }}>Add a comment</h3>



                                        <TextArea placeholder="Your message Here..." allowClear />


                                    </div>

                                    <Button onClick={handleSendtoCustomewr}> Send to Customer</Button>
                                </div>
                            },]


                        }
                    />







                </Panel>
            </Collapse>




        </Page>
    )

}

VendorArtwork.Loader = async ({ params }) => {


    if (isNaN(params.id)) {
        throw new Error('Invalid Orrder ID')
    }
    const artworkDetails = await fetchArtworkDetails(params.id)
    if (!artworkDetails) {
        throw new Error('Invalid Quoation')
    }

    console.log(artworkDetails);

    // setCurrent(checkStatus(quotationDetails))






    // const basicquotationDetails = (({
    // 	vendorData, item, specifications
    // }) => ({
    // 	vendorData, item, specifications
    // }))(quotationDetails)



    return { artworkDetails }
}


VendorArtwork.propTypes = {
    title: PropTypes.string,
}
export default VendorArtwork