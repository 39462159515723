// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/product/categories/:language_code'

// Define all api functions here
export async function getAllCategories(language_code) {
    return axiosInstance.get(generatePath(ENDPOINT, {
        language_code
    })).then(({ data }) => data)
}