import React from "react";
import PropTypes from 'prop-types'
import { CheckOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import { sendOrderShippingDetails } from "../../../api/order";
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { DatePicker } from "../../../components/inputs";
import { CommentForClient, CommentForVendor } from '../index';






const InProduction = ({ data, changeState }) => {

    console.log(data);




    const [form] = Form.useForm()
    const fieldStyle = { 'width': '50%', 'height': '35px' }

    const handleShippingSubmit = async (shippingID, jsonData) => {
        try {
            const response = await sendOrderShippingDetails(shippingID, jsonData)
            const { message: msg } = response
            console.log(response);
            message.success(msg);



        }
        catch (error) {
            throw new Response('Unable to upload files', { status: 500 })
        }

    }




    return (
        <div>

            <div className={styles.stepBox}>
                <h3 style={{ 'text-align': 'start' }}>Task For You</h3>
                <div className={styles.message}>
                    <ul>
                        <li>
                            Vendor has started the Production.
                        </li>
                        <li>Ask Vendor to provide an expected transit date of the order
                            <span style={{ "float": 'right' }}><Button onClick={() => {
                                changeState(17)
                            }}>Ask Vendor</Button></span>
                        </li>

                    </ul>
                </div>
            </div>
            <br />

            <div className={styles.stepBox}>


                <div>
                    <Form
                        form={form}
                        // onFinish={onFinish}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >



                        <table style={{ 'width': '100%' }}>
                            <tbody>

                                <tr>
                                    <td>Quantity</td>
                                    <td>Delivery Address</td>
                                    <td>Ship Quantity</td>
                                    <td>Tracking URL</td>
                                    <td>Expected Delivery Date</td>
                                    <td></td>



                                </tr>


                                {data.item_delivery_address && data.item_delivery_address.map((value, index) => {



                                    return (



                                        <tr style={{ 'vertical-align': 'baseline' }} key={index}>

                                            <td>{value.quantity}
                                            </td>

                                            <td>{value.street_number},{value.street},{value.city}</td>
                                            <td><Form.Item

                                                name={"shipping_quantity" + index}
                                                rules={[{ required: true, message: 'Please input shipping quantity url !' }]}

                                                initialValue={value.ship_quantity}
                                            >
                                                <Input style={fieldStyle} />
                                            </Form.Item></td>






                                            <td><Form.Item

                                                name={"tracking_url" + index}
                                                rules={[{ required: true, message: 'Please input tracking url !' }]}

                                                initialValue={value.tracking_url}
                                            >
                                                <Input style={fieldStyle} />
                                            </Form.Item></td>

                                            <td><Form.Item
                                                name={"expected_date" + index}


                                                initialValue={value.expected_date}

                                            >
                                                <DatePicker />

                                            </Form.Item></td>

                                            <td>
                                                <Button onClick={() => {



                                                    const payload = {
                                                        "ship_quantity": form.getFieldValue()['shipping_quantity' + index],
                                                        "tracking_url": form.getFieldValue()['tracking_url' + index],
                                                        "expected_date": form.getFieldValue()['expected_date' + index]

                                                    }

                                                    console.log(payload);

                                                    handleShippingSubmit(value.id, payload)

                                                }} type="primary" icon={<CheckOutlined />} />


                                            </td>



                                        </tr>












                                    )
                                }



                                )

                                }
                            </tbody>


                        </table>



                    </Form>



                </div>


            </div>

            <br />

            <CommentForClient comment={data.client_comment} />
            <br />
            <CommentForVendor comment={data.vendor_comment} />







        </div>




    )
}

InProduction.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    changeState : PropTypes.func
}
export default InProduction;


