import { InputNumber } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';

const Currency = ({ value = null, currency = 'EUR', onChange, ...otherProps }) => {
    // const formatter = (val) => {
    //     switch (currency) {
    //         case 'EUR':
    //             //return `${val}`.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
    //             return new Intl.NumberFormat('de-DE').format(val)

    //         case 'GBP':
    //             return new Intl.NumberFormat('en-EN').format(val)

    //         case 'USD':
    //             return new Intl.NumberFormat('en-US').format(val)

    //         default:
    //             return val
    //     }
    // }

    // const parser = (val) => {
    //     switch (currency) {
    //         case 'EUR':
    //             return new Intl.NumberFormat('de-DE').parse(val)

    //         case 'GBP':
    //             return new Intl.NumberFormat('en-EN').parse(val)

    //         case 'USD':
    //             return new Intl.NumberFormat('en-US').parse(val)

    //         default:
    //             return val
    //     }
    // }

    const prefix = () => {
        switch (currency) {
            case 'EUR':
                return '€'

            case 'GBP':
                return '£'

            case 'USD':
                return '$'

            default:
                return ''
        }
    }

    return (
        <InputNumber
            value={value}
            controls={false}
            prefix={prefix()}
            // 23
            onChange={onChange}
            min="0.00"
            step="0.01"
            precision="2"
            {...otherProps}
        />
    )
}

Currency.propTypes = {
    value: PropTypes.number,
    onChange: PropTypes.func,
    currency: PropTypes.oneOf(['EUR', 'USD', 'GBP']),
}

export default Currency;