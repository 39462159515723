import React, { useState } from "react";
import PropTypes from "prop-types";
import {Layout, Button } from "antd";
import { ControlOutlined, BellOutlined } from "@ant-design/icons";
// import { useApp } from '../../providers/app/app-context.js';
const { Header: AntdHeader } = Layout;

function Header(props) {
  const { title } = props;
  const [loadings, setLoadings] = useState([]);
  

  const enterLoading = (index) => {
    setLoadings((prevLoadings) => {
      const newLoadings = [...prevLoadings];
      newLoadings[index] = true;
      return newLoadings;
    });
    setTimeout(() => {
      setLoadings((prevLoadings) => {
        const newLoadings = [...prevLoadings];
        newLoadings[index] = false;
        return newLoadings;
      });
    }, 2000);
  };



  return (
    <div className="site-page-header-ghost-wrapper">
      <AntdHeader className='header'>
        <div className="main-header">
          
          <div className="title-area">{title && <h1>{title}</h1>}</div>
          <div className="action-area">
            <BellOutlined className="notifications" />
            <Button
              className="filters"
              icon={<ControlOutlined />}
              loading={loadings[1]}
              onClick={() => enterLoading(1)}
            >
              Filters
            </Button>
            
          </div>
        </div>
      </AntdHeader>
    </div>
  );
}

Header.propTypes = {
  title: PropTypes.string.isRequired,
  user: PropTypes.shape({}),
};

export default Header;
