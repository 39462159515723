import React  from 'react';
import { Server } from '../../constants/server'

export const ContactColumns = [
    {
        title: 'First Name',
        dataIndex: 'first_name',
        fixed: 'left',
        sorter: true,
    },
    {
        title: 'Last Name',
        dataIndex: 'last_name',
        fixed: 'left',
        sorter: true,
    },
    {
        title: 'Company',
        dataIndex: 'customer',
        sorter: true,
        render: customer => customer.company,
    },
    {
        title: 'Email',
        dataIndex: 'email',
        sorter: true,
    },
    {
        title: 'Contact Number',
        dataIndex: 'phone_number',
        sorter: true,
    },
    {
        title: 'Country',
        dataIndex: ['customer', 'country'],
        sorter: true,
        render: (country) => {
            return <img src={`${Server.BaseUrl}/${country.flag_url}`} alt={country.name} title={country.name} />
        }
    },
    {
        title: 'Language',
        dataIndex: 'language',
        render: language => language.name,
        sorter: true,
    },

    // {
    //   title: 'Action',
    //   key: 'operation',
    //   fixed: 'right',
    //   width: 100,
    //   render: () => <a>action</a>,
    // },
]