import { DeleteOutlined, FileTextOutlined, InboxOutlined, PlusOutlined, UserAddOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, message, Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { useFetcher, useLoaderData, useNavigate } from 'react-router-dom';
import { getCustomerDetails } from '../../api/customer';
import { addProjectContact, getProjectDetails, removeProjectContact, removeProjectContactAuthority, setProjectContactAuthority, setProjectContactPrimary, updateProjectDetails } from '../../api/project';
import { getProjectItem } from '../../api/project-item';
import { ContactDescription, ProjectItemOverview } from '../../components/data';
import { SmallSection } from '../../components/design';
import { ProjectDetails } from '../../components/form';
import { ContactRoleSelect } from '../../components/inputs';
import { Page } from '../../components/page';
import { SelectableContacts } from '../../components/selectables';
import { generateRouteUrl } from '../../library/constants/routes.js';
const { Panel } = Collapse

const Contact = ({ contact, primaryContactId }) => {
	const fetcher = useFetcher()
	const [confirmVisible, setConfirmVisible] = useState(false)
	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
				<ContactRoleSelect contact={contact} primaryContactId={primaryContactId} />
				{contact.id !== primaryContactId && <Popconfirm
					title="Title"
					open={confirmVisible}
					onConfirm={() => {
						fetcher.submit({
							action: 'removeContact',
							contactId: contact.id
						}, {
							method: 'post',
						})
						setConfirmVisible(false)
					}}
					okButtonProps={{
						loading: fetcher.state === 'submitting',
					}}
					onCancel={() => setConfirmVisible(false)}
				>
					<Button type="danger" shape="circle" icon={<DeleteOutlined />} onClick={() => setConfirmVisible(true)} size="small" />
				</Popconfirm>}
			</div>
			<SmallSection>
				<ContactDescription contact={contact} />
			</SmallSection>
		</>
	)
}

Contact.propTypes = {
	contact: PropTypes.object.isRequired,
	primaryContactId: PropTypes.number.isRequired,
}

const ProjectUpdate = ({ title }) => {
	const { projectId, items, contacts, projectDetails, basicProjectDdetails, customerDetails } = useLoaderData()
	//const [projectDetails, setProjectDetails] = useState(false)
	//const [customerDetails, setCustomerDetails] = useState({})
	const formIsValid = useRef(true)
	const formData = useRef({ ...basicProjectDdetails })
	//const [contacts, { add: addContact, remove: removeContact, update: updateContact, set: setContacts }] = useListReducer(projectDetails.contacts)
	const [showContactSelect, SetShowContactSelect] = useState(false)
	const navigate = useNavigate()
	const fetcher = useFetcher()

	const handleCustomerChange = async (/* customer */) => {
		// if (!customer?.primary_contact_id || !customer?.contacts) {
		// 	setCustomerContact(null)
		// 	setProjectDetails({ ...projectDetails, ['primary_contact_id']: null })
		// }

		// const primaryContact = customer?.contacts.find(({ id }) => id === customer.primary_contact_id)
		// if (!primaryContact) {
		// 	setCustomerContact(null)
		// 	setProjectDetails({ ...projectDetails, ['primary_contact_id']: null })
		// }

		// setCustomerContact(primaryContact)
		// setProjectDetails({ ...projectDetails, ['primary_contact_id']: primaryContact.id })
		//submitForm()
	}

	const handleProjectDetailsChange = async (_, values) => {
		formData.current = ({ ...formData.current, ...values })
		submitForm()
	}

	// useEffect(() => {
	// 	setIsLoading(true)
	// 	getProjectDetails(projectId)
	// 		.then((project) => {
	// 			setProjectDetails(project)
	// 			setContacts(project.contacts)
	// 			setItems(project.items ?? {})
	// 			if (project.customer_id) {
	// 				getCustomerDetails(project.customer_id)
	// 					.then((data) => setCustomerDetails(data))
	// 			}
	// 			// if (project.primary_contact_id) {
	// 			// 	setCustomerContact(project.project_contacts.find(({ id }) => project.primary_contact_id)?.contact)
	// 			// }
	// 			// else {
	// 			// 	setCustomerContact(null)
	// 			// }
	// 		})
	// 		.finally(() => {
	// 			setIsLoading(false)
	// 		})

	// }, [projectId])

	const submitForm = async () => {
		if (!formIsValid.current)
			return

		try {
			/* const { message: msg, data } = */ await updateProjectDetails(projectId, formData.current)
			//message.success(msg)
		}
		catch (e) {
			message.error(e.message)
			throw e
		}
	}

	const handleFormUpdate = (name, { forms }) => {
		if (name === 'projectDetails') {
			formData.current = { ...formData.current, ...forms[name].getFieldsValue(true) }
			return
		}

		// const [type, id] = name.split('-')
		// if (!id) {
		// 	return
		// }
		// switch (type) {
		// 	case 'address':
		// 		//formData.current.addresses = mutateArrayItem(formData.current.addresses, id, forms[name].getFieldsValue(true))
		// 		formData.current.addresses[id] = forms[name].getFieldsValue(true)
		// 		break
		// 	case 'contact':
		// 		//formData.current.contacts = mutateArrayItem(formData.current.contacts, id, forms[name].getFieldsValue(true))
		// 		formData.current.contacts[id] = forms[name].getFieldsValue(true)
		// 		break
		// }
	}

	return (
		<Page className='projects-create' title={title} header={<Button type="primary" onClick={() => { navigate(generateRouteUrl('Projects')) }}>Back</Button>}>
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				alignTtems: 'stretch',
			}} >
				<Form.Provider onValuesChange={handleFormUpdate}>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Project Information' key="1">
							<ProjectDetails
								initialValues={basicProjectDdetails}
								getValidationStatus={(isValid) => { formIsValid.current = !!isValid }}
								onCustomerChange={handleCustomerChange}
								onValuesChange={handleProjectDetailsChange}
							/>
						</Panel>
					</Collapse>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Contact Information' key="1">
							{Object.entries(contacts).map(([, contact]) => (
								<Contact key={contact.id} contact={contact} primaryContactId={projectDetails.primary_contact_id} />
							))}
							<div className="ant-collapse-footer">
								<Button onClick={() => SetShowContactSelect(true)} icon={<UserAddOutlined />} type="primary" ghost>Add New Contact</Button>
							</div>
							{customerDetails?.contacts &&
								<SelectableContacts
									show={showContactSelect}
									//contacts={customerDetails.contacts.filter(({ id }) => !(Object.values(contacts).map(({ id }) => id)).includes(id))}
									customerId={customerDetails.id}
									onSelectionChanged={(contact) => {
										fetcher.submit({
											action: 'addContact',
											contactId: contact.id
										}, {
											method: 'post',
										})
										SetShowContactSelect(false)
									}}
									onContactsUpdate={() => {
										//setCustomerDetails({ ...customerDetails, ['contacts']: contacts })
									}}
									exclude={Object.values(contacts).map(({ id }) => id)}
									handleClose={() => { SetShowContactSelect(false) }}
								/>
							}
						</Panel>
					</Collapse>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header={<><InboxOutlined /> Item Details</>} key="1">
							{Object.entries(items).map(([index, item]) => (
								<ProjectItemOverview key={index} index={Number(index)} data={item} onEdit={() => {
									navigate(generateRouteUrl('ProjectItemUpdate', {
										projectId,
										itemId: item.id
									}))
								}} />
							))}
							<div className="ant-collapse-footer">
								<Button onClick={() => navigate(generateRouteUrl('ProjectItemCreate', {
									projectId,
								}))} icon={<PlusOutlined />} type="primary" ghost>Add Item</Button>
							</div>
						</Panel>
					</Collapse>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header={<><FileTextOutlined /> Documents</>} key="1">
							<Button onClick={() => navigate(generateRouteUrl('SendClientQuotation', {
								projectId,
							}))} icon={<PlusOutlined />} type="primary" ghost>Preview & Send to Client</Button>
						</Panel>
					</Collapse>
				</Form.Provider>
			</div>
		</Page >
	)
}

ProjectUpdate.propTypes = {
	title: PropTypes.string
}

ProjectUpdate.Actions = {
	addContact: ({ params, data }) => addProjectContact(params.projectId, data.contactId),
	removeContact: ({ params, data }) => removeProjectContact(params.projectId, data.contactId),
	primaryContact: ({ params, data }) => setProjectContactPrimary(params.projectId, data.contactId),
	authorityContact: ({ params, data, method }) => {
		if (method.toLowerCase() === 'delete') {
			return removeProjectContactAuthority(params.projectId, data.contactId)
		}
		else {
			return setProjectContactAuthority(params.projectId, data.contactId)
		}
	}
}

ProjectUpdate.Loader = async ({ params }) => {
	if (isNaN(params.projectId)) {
		throw new Error('Invalid Project ID')
	}
	const projectDetails = await getProjectDetails(params.projectId)
	if (!projectDetails) {
		throw new Error('Invalid Project')
	}

	const customerDetails = await getCustomerDetails(projectDetails.customer_id)

	const basicProjectDdetails = (({
		source_id, customer_id, entity_id, account_manager_id, client_order_number, payment_condition_id, payment_term_id, client_comment
	}) => ({
		source_id, customer_id, entity_id, account_manager_id, client_order_number, payment_condition_id, payment_term_id, client_comment
	}))(projectDetails)

	const { id: projectId, contacts } = projectDetails

	const items = await Promise.all(projectDetails.items.map(async ({ id }) => {
		return await getProjectItem(id)
	}))

	return { projectId, contacts, items, projectDetails, basicProjectDdetails, customerDetails }
}

export default ProjectUpdate