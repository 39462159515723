
import { Button } from 'antd';
import PropTypes from 'prop-types';
import React from "react";
import { generatePath, useNavigate } from 'react-router-dom';
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { CommentForClient, CommentForVendor } from '../index';



const TemplateReady = ({ data }) => {
    const navigate = useNavigate()
    const  handleRequestTemplate = async ()=>{
        navigate(
            generatePath('/send-ready-template/:id', { id: data.id }),
            
            { state: data }
        
        )

       

	

    }
    return <div>
    <div className={styles.stepBox}>
        <h3 style={{ 'textAlign': 'start' }}>Task For You</h3>
        <div className={styles.message}>
            <ul>
                <li> Template is ready, please send it to customer
                    <span style={{ "float": 'right' }}><Button onClick={handleRequestTemplate}>View & Send to client</Button></span>
                </li>

            </ul>
        </div>
    </div>
    <br />
    <br />

    <CommentForClient comment={data.client_comment} />
    <CommentForVendor comment={data.vendor_comment} />


</div>
}

TemplateReady.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
  //  logs: PropTypes.arrayOf(PropTypes.object).isRequired
    //  changeState: PropTypes.func
}
export default TemplateReady ;