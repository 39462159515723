import React from "react";
import styles from '../../../components/data/production/item.module.scss';
import { Input ,Button} from "antd";
const {TextArea} = Input
import PropTypes from 'prop-types';
const SendToStudio = ({ data, heading, onSubmit }) => {
    console.log(data);

    return (

        <div className={styles.tabContainer} >

            <h3>{heading}</h3>
            <p>Product Link from vendor</p>
            <Input value={data.product_link??''} disabled/>
           
            <p style={{'margin-top':'16px','margin-bottom':'16px'}}>Specifications : </p>
            

            <div className={styles.wrapper}>

            <div className={styles.right}>

                <div className={styles.specifications}>
                    {data.specification.map(({ name, value }) => (
                        <React.Fragment key={name}>
                            <div className={styles.name}>{name}</div>
                            <div className={styles.value}>{value}</div>
                        </React.Fragment>
                    ))}
                </div>

                {/* <QuotationItemQuantities prices={item.prices} selectedId={selectedId} onSelectionChange={(selected) => {
                    onSelectionChange({ itemId: item.id, priceId: selected })
                }} /> */}
            </div>

            </div>

            <p  style={{'margin-top':'16px','margin-bottom':'16px'}}>Add a Comment</p>
                <TextArea name="comment" placeholder="Type your message here"/>

                <br />
                <Button style={{'margin-top':'16px','margin-bottom':'16px' , 'float':'right'}} onClick={onSubmit}> Submit </Button>


            </div>
    )



}
SendToStudio.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    heading: PropTypes.string,
    imageIds: PropTypes.arrayOf(PropTypes.object),
    onSubmit : PropTypes.func

}

export default SendToStudio