import { DeleteOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Collapse, Form, Popconfirm, message } from 'antd'
import { customAlphabet } from 'nanoid/non-secure'
import PropTypes from 'prop-types'
import React, { useEffect, useReducer, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { createAddress, deleteAddress, updateAddress } from '../../api/address'
import { createVendorContact, deleteVendorContact, updateVendorContactDetails } from '../../api/vendor/vendorContact.js'


import { getVendorDetails, updateVendorDetails } from '../../api/vendor/vendor'

import { SmallSection } from '../../components/design'
import { VendorAddress, VendorContact, VendorDetails } from '../../components/form'
import { Page } from '../../components/page'
const { Panel } = Collapse
const nanoid = customAlphabet('1234567890abcdef', 61)

const ACTIONS = {
	SET: 'set',
	ADD: 'add',
	REMOVE: 'remove',
	UPDATE: 'update',
}

const reducer = (state, action) => {
	try {
		switch (action.type) {
			case ACTIONS.SET:
				if (typeof action.value === 'undefined') {
					throw new Error('value require for Set')
				}
				return { ...action.value }
			case ACTIONS.ADD:
				if (typeof action.value === 'undefined') {
					throw new Error('value require for update')
				}
				return { ...state, [nanoid()]: { ...action.value } }

			case ACTIONS.REMOVE: {
				if (!action.id) {
					throw new Error('Id is required for update')
				}
				if (typeof state[action.id] === 'undefined') {
					throw new Error('Invalid Id for update')
				}
				const tempState = { ...state }
				delete tempState[action.id]
				return { ...tempState }
			}

			case ACTIONS.UPDATE:
				if (!action.id) {
					throw new Error('Id is required for update')
				}
				if (typeof state[action.id] === 'undefined') {
					throw new Error('Invalid Id for update')
				}
				if (typeof action.value === 'undefined') {
					throw new Error('value require for update')
				}

				return { ...state, [action.id]: action.value }

			default:
				throw new Error('Invalid action')
		}
	}
	catch (e) {
		console.log(e)
		return state
	}
}

const VendorUpdate = ({ title }) => {
	const { id: vendorId } = useParams()
	const [isLoading, setIsLoading] = useState(true)
	const [basicDetailsForm, setBasicDetailsForm] = useState(false)
	const [addressForms, dispatchAddressForm] = useReducer(reducer, {})
	const [contactForms, dispatchContactForm] = useReducer(reducer, {})
	const addressSubmitButtonRefs = useRef({})
	const contactSubmitButtonRefs = useRef({})
	const navigate = useNavigate()

	useEffect(() => {
		setIsLoading(true)
		getVendorDetails(vendorId)
			.then((data) => {
				setBasicDetailsForm(data)
				dispatchAddressForm({ type: ACTIONS.SET, value: data.addresses })
				dispatchContactForm({ type: ACTIONS.SET, value: data.contacts })
			})
			.finally(() => {
				setIsLoading(false)
			})
	}, [])

	const handleVendorDetailsSubmit = async (details) => {
		try {
			setBasicDetailsForm({ ...basicDetailsForm, __isLoading: true })
			const { message: msg, data } = await updateVendorDetails(vendorId, details)
			console.log(msg);
			message.success(msg)
			setBasicDetailsForm({ ...data })
		}
		catch (e) {
			message.error(e.message)
			throw e
		}
		finally {
			setBasicDetailsForm({ ...basicDetailsForm, __isLoading: false, __isEditable: false })
		}
	}

	const handleVendorAddressSubmit = async (index, address) => {
		address.context_id = vendorId
		address.context = 'vendor'
		try {
			address = { ...addressForms[index], ...address, __isLoading: true }
			dispatchAddressForm({ type: ACTIONS.UPDATE, id: index, value: address })
			const { message: msg, data } = await (!address.id ? createAddress(address) : updateAddress(address.id, address))
			message.success(msg)
			dispatchAddressForm({ type: ACTIONS.UPDATE, id: index, value: { ...data, __isEditable: false, __isLoading: false } })
		}
		catch (e) {
			dispatchAddressForm({ type: ACTIONS.UPDATE, id: index, value: { ...address, __isLoading: false } })
			throw e
		}
	}

	const handleVendorContactSubmit = async (index, contact) => {
		contact.vendor_id = vendorId
		//contact = { ...contactForms[index], ...contact, __isLoading: true }
		
		try {
		//	contact = { ...contactForms[index], ...contact, __isLoading: true }
			
			dispatchContactForm({ type: ACTIONS.UPDATE, id: index, value: contact })
			const { message: msg, data } = await (!contact.id ? createVendorContact(contact) : updateVendorContactDetails(contact.id, contact))
			message.success(msg)
			dispatchContactForm({ type: ACTIONS.UPDATE, id: index, value: { ...data, __isEditable: false, __isLoading: false } })
		}
		catch (e) {
		
			message.error(e.errors[0].message)
			dispatchContactForm({ type: ACTIONS.UPDATE, id: index, value: { ...contact, __isEditable: true } })

			throw e
			
		}
	}
	// const makeProject = async (payload) => {
	// 	try {
	// 		const { message: msg, data } = await createProject({ ...payload })
	// 		message.success(msg)
	// 		navigate(generatePath('/projects/:id', {
	// 			id: data.id
	// 		}))
	// 	}
	// 	catch (e) {
	// 		message.error(e.message)
	// 		throw e
	// 	}
	// }

	const handleAddressDelete = async (index, addressId) => {
		const { message: msg } = await deleteAddress(addressId)
		message.success(msg)
		dispatchAddressForm({ type: ACTIONS.REMOVE, id: index })
	}


	
	const handleContactDelete = async (index, contactId) => {
		try{
			const { message: msg } = await deleteVendorContact(contactId)
			message.success(msg)
			dispatchContactForm({ type: ACTIONS.REMOVE, id: index })
		}
		catch(e){
			dispatchContactForm({ type: ACTIONS.REMOVE, id: index })
		}
		
	}

	// const handleMarkContactPrimary = async (index, contactId) => {
	// 	const { message: msg, data } = await setCustomerPrimaryContact(contactId)
	// 	message.success(msg)
	// 	setBasicDetailsForm({ basicDetailsForm, primary_contact_id: data.primary_contact_id })
	// }

	// const handleToggleAuthority = async (index, contactId, action) => {
	// 	console.log({ index, contactId, action })
	// 	const { message: msg, data } = await toggleContactAuthority(contactId, Boolean(action))
	// 	message.success(msg)
	// 	dispatchContactForm({ type: ACTIONS.UPDATE, id: index, value: data })
	// }
	const getAddressActions = (index, address) => {
		return ([
			address.__isEditable ?
				<Button onClick={() => {
					console.log(

						addressSubmitButtonRefs);

					addressSubmitButtonRefs.current[index].click()
				}} type="primary" shape="circle" icon={<SaveOutlined />} key="save" loading={address.__isLoading ?? false} /> :
				<Button onClick={() => { dispatchAddressForm({ type: ACTIONS.UPDATE, id: index, value: { ...address, __isEditable: true } }) }} type="primary" shape="circle" icon={<EditOutlined />} key="edit" />,
			<Popconfirm
				key="delete"
				title="Are you sure to delete this address?"
				onConfirm={() => handleAddressDelete(index, address.id)}
			>
				<Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
			</Popconfirm>
		])
	}

	const getContactAction = (index, contact) => {
		return ([
			contact.__isEditable ?
				<Button onClick={() => {
					// console.log(

					// 	contactSubmitButtonRefs);

						contactSubmitButtonRefs.current[index].click()
				}} type="primary" shape="circle" icon={<SaveOutlined />} key="save" loading={contact.__isLoading ?? false} /> :
				<Button onClick={() => { dispatchContactForm({ type: ACTIONS.UPDATE, id: index, value: { ...contact, __isEditable: true } }) }} type="primary" shape="circle" icon={<EditOutlined />} key="edit" />,
			<Popconfirm
				key="delete"
				title="Are you sure to delete this address?"
				onConfirm={() => handleContactDelete(index, contact.id)}
			>
				<Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
			</Popconfirm>
		])
		// return ([
		// 	contact.__isEditable ?
		// 		<Tooltip title="Save">
		// 			<Button onClick={() => contactSubmitButtonRefs.current[index].click()} type="primary" shape="circle" icon={<SaveOutlined />} key="save" loading={contact.__isLoading ?? false} />

		// 		</Tooltip> :
		// 		<Tooltip title="Edit">
		// 			<Button onClick={() => { dispatchContactForm({ type: ACTIONS.UPDATE, id: index, value: { ...contact, __isEditable: true } }) }} type="primary" shape="circle" icon={<EditOutlined />} key="edit" />
		// 		</Tooltip>,
		// 	// ...contact.id !== basicDetailsForm.primary_contact_id ?
		// 	// 	[
		// 	// 		// <Tooltip title="Give Contact Authority">

		// 	// 		// 	<Button onClick={() => { handleToggleAuthority(index, contact.id, !contact.is_authority) }} type="primary" shape="circle" icon={contact.is_authority ? <UserDeleteOutlined /> : <UserAddOutlined />} key="primary" />
		// 	// 		// </Tooltip>,

		// 	// 		// <Tooltip title="Make Contact Primary">
		// 	// 		// 	<Button onClick={() => { handleMarkContactPrimary(index, contact.id) }} type="primary" shape="circle" icon={< StarOutlined />} key="authority" />
		// 	// 		// </Tooltip>,
		// 	// 	] : [],
		// 	<Popconfirm
		// 		key="delete"
		// 		title="Are you sure to delete this contact?"
		// 		onConfirm={() => handleContactDelete(index, contact.id)}
		// 	>
		// 		<Button type="primary" shape="circle" icon={<DeleteOutlined />} danger />
		// 	</Popconfirm>
		// ])
	}

	return (
		<Page className='vendor-detail' title={title} header={<Button type="primary" onClick={() => { navigate(-1) }}>Back</Button>}>
			{basicDetailsForm && !isLoading &&
				<>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Vendor Details' key="1" extra={



							<Button onClick={(e) => { setBasicDetailsForm({ ...basicDetailsForm, __isEditable: true }); e.stopPropagation(); }} icon={<EditOutlined />} disabled={basicDetailsForm.__isEditable}>Edit</Button>













						}>


							{/* <VendorDetails id={Number(vendorId)} initialValues={basicDetailsForm} 
							onValuesChange={handleVendorAddressChange}
							onFinish={(data) => handleVendorDetailsSubmit(data)} disabled={!basicDetailsForm.__isEditable} loading={basicDetailsForm.__isLoading}
							
							
							>
							<Button type="primary" htmlType="submit">Save</Button>	
							</VendorDetails> */}

							<VendorDetails id={Number(vendorId)} initialValues={basicDetailsForm} onFinish={(data) => handleVendorDetailsSubmit(data)} disabled={!basicDetailsForm.__isEditable} loading={basicDetailsForm.__isLoading}>
								<Button type="primary" htmlType="submit">Save</Button>
							</VendorDetails>

						</Panel>
					</Collapse>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Addresses' key="1" >
							{Object.entries(addressForms).map(([id, address]) => (
								<SmallSection key={id} actions={getAddressActions(id, address)} >
									<VendorAddress index={id} initialValues={address} onFinish={(data) => handleVendorAddressSubmit(id, data)} disabled={!address.__isEditable || address.__isLoading}>
										<Form.Item style={{ display: 'none' }}>
											<Button htmlType="submit" ref={el => addressSubmitButtonRefs.current[id] = el}>Save</Button>
										</Form.Item>
									</VendorAddress>
								</SmallSection>
							))}

							{Object.keys(addressForms).length==0 ? 
							
							<Button onClick={() => dispatchAddressForm({ type: ACTIONS.ADD, value: { __isEditable: true } })}>Add Address</Button>:
							<div></div>
						}
							
						</Panel>
					</Collapse>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Contacts' key="1">
							{Object.entries(contactForms).map(([id, contact]) => (
								<SmallSection key={id} actions={getContactAction(id, contact)} primary={contact.id === basicDetailsForm.primary_contact_id} authority={contact.id !== basicDetailsForm.primary_contact_id && !!contact.is_authority}>
									<VendorContact index={id} initialValues={contact} onFinish={(data) => handleVendorContactSubmit(id, data)} disabled={!contact.__isEditable || contact.__isLoading}>
										<Form.Item style={{ display: 'none' }}>
											<Button htmlType="submit" ref={el => contactSubmitButtonRefs.current[id] = el}>Save</Button>
										</Form.Item>
									</VendorContact>
								</SmallSection>
							))}
							<Button onClick={() => { dispatchContactForm({ type: ACTIONS.ADD, value: { __isEditable: true } }) }}>Add New Contact</Button>
						</Panel>
					</Collapse>
				</>
			}
		</Page>
	)
}

VendorUpdate.propTypes = {
	title: PropTypes.string
}

export default VendorUpdate