
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import PropTypes from 'prop-types'
import { Button } from 'antd';
import React from "react";
import { generatePath, useNavigate } from 'react-router-dom';
import { CommentForClient, CommentForVendor } from '../index';


const ProofReceived = ({ data }) => {

    const navigate = useNavigate()


    const  handleRequestTemplate = async ()=>{
        navigate(
            generatePath('/vendor-artwork/:id', { id: data.id }),
            
            { state: data }
        
        )

       

	

    }
    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'text-align': 'start' }}>Task For You</h3>
            <div className={styles.message}>
                <ul>
                    <li>Vendor has created printable final proof. Send it to customer for approval
                        <span style={{ "float": 'right' }}><Button onClick={handleRequestTemplate}>View Final Proof</Button></span>
                        <br />
                        Vendor Comment : TODO TOD
                    </li>

                </ul>
            </div>
        </div>
        <br />
        <br />

        <CommentForClient comment={'testing'} />
        <CommentForVendor comment={'testing'} />


    </div>
}

ProofReceived.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    // logs: PropTypes.arrayOf(PropTypes.object).isRequired,
    // changeState: PropTypes.func
}
export default ProofReceived;