// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT = '/customer/contact/:id/setprimary';

export const setCustomerPrimaryContact = (id) => {
    return axiosInstance.post(generatePath(ENDPOINT, {
        id
    }), {})
}