import PropTypes from 'prop-types';
import React from "react";
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import { CommentForClient, CommentForVendor } from '../index';

const SendArtworkToVendor = ({ data }) => {

    // const navigate = useNavigate()
    // const  handleRequestTemplate = async ()=>{
      
       
    //     try {
    //         const { message: msg, d } = await changeStatus(data.id,8, [])
    //         message.success(msg)
            
    //     }
    //     catch (e) {
    //         message.error(e.message)
    //         throw e
    //     }
	

    // }
    return <div>
        <div className={styles.stepBox}>
            <h3 style={{ 'textAlign': 'start' }}>Task For You</h3>
            <div className={styles.message}>
                <ul>
                    <li>Artwork has been sent to Vendor.
                        {/* <span style={{ "float": 'right' }}><Button onClick={handleRequestTemplate}>Continue</Button></span> */}
                    </li>

                </ul>
            </div>
        </div>
        <br />
        <br />

        <CommentForClient comment={data.client_comment} />
        <CommentForVendor comment={data.vendor_comment} />


    </div>
}

SendArtworkToVendor.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    //logs: PropTypes.arrayOf(PropTypes.object).isRequired
    //  changeState: PropTypes.func
}

export default SendArtworkToVendor;