

import styles from '../../../components/data/quotation/quotation-item.module.scss';
import PropTypes from 'prop-types'
import { Button } from 'antd';
import React from "react";
import { generatePath, useNavigate } from 'react-router-dom';
import { CommentForClient, CommentForVendor } from '../index';



const ProofReady = ({ data }) => {

    const navigate = useNavigate()
    const  handleRequestTemplate = async ()=>{
        navigate(
            generatePath('/send-ready-proof/:id', { id: data.id }),
            
            { state: data }
        
        )

       

	

    }
    return <div>
    <div className={styles.stepBox}>
        <h3 style={{ 'text-align': 'start' }}>Task For You</h3>
        <div className={styles.message}>
            <ul>
                <li> Proof is ready, please send it to customer.
                    <span style={{ "float": 'right' }}><Button onClick={handleRequestTemplate}>View & Send Proof</Button></span>
                </li>

            </ul>
        </div>
    </div>
    <br />
    <br />

    <CommentForClient comment={data.client_comment} />
    <CommentForVendor comment={data.vendor_comment} />


</div>
}

ProofReady.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    // logs: PropTypes.arrayOf(PropTypes.object).isRequired,
    // changeState: PropTypes.func
}

export default ProofReady ;