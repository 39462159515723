import { Button, Card, Form, Input, message, Modal } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { createContact } from '../../../api/contact';
import { getCustomerDetails } from '../../../api/customer';
import { Genders, Languages, Roles } from '../../../library/constants/dynamic.js';
import { CustomerContact } from '../../form/index.js';
import styles from './selectable-contacts.module.scss';
import { CheckOutlined ,  CloseOutlined} from '@ant-design/icons';
// import { customAlphabet } from 'nanoid/non-secure';
// const nanoid = customAlphabet('1234567890abcdef', 6)

const renderItem = (item, selected, handleItemClick) => (
	<Card key={item.id} onClick={() => handleItemClick(item)} className={`${styles.card} ${selected ? styles['card--selected'] : ''}`} title={`${item.first_name} ${item.last_name}`}>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Phone No.</div>
			<div className={styles.itemValue}>{`+${item.phone_country_code} ${item.phone_number}`}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Mobile No.</div>
			<div className={styles.itemValue}>{`+${item.mobile_country_code} ${item.mobile_number}`}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Email</div>
			<div className={styles.itemValue}>{item.email}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Language</div>
			<div className={styles.itemValue}>{Languages.find(({ id }) => id === item.language_id)?.name}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Role</div>
			<div className={styles.itemValue}>{Roles.find(({ id }) => id === item.role_id)?.name}</div>
		</div>
		<div className={styles.item}>
			<div className={styles.itemLabel}>Gender</div>
			<div className={styles.itemValue}>{Genders.find(({ id }) => id === item.gender_id)?.name}</div>
		</div>
	</Card>
)

const AddContactModal = ({ children, show, handleClose, handleSave }) => {
	const [contactFormIsValid, SetContactFormIsValid] = useState(false)
	const contactFormData = useRef({})

	const handleContactFormChange = (name, { forms }) => {
		contactFormData.current = forms[name].getFieldsValue()
	}

	return (
		<Modal
			title="Add New Contact"
			open={show}

			
		
			
			onCancel={handleClose}
			width='50%'
			forceRender
			destroyOnClose
			footer={<>
				<Button  type="primary" icon={<CheckOutlined />} onClick={() => { contactFormIsValid && handleSave(contactFormData.current) }} disabled={!contactFormIsValid}>Save</Button>
				<Button type="cancel" icon={<CloseOutlined />} onClick={handleClose}>Cancel</Button>
			</>}>
			<Form.Provider 	 onFormChange={handleContactFormChange} >
				<CustomerContact 
				// initialValues={{
				// 	first_name: 'Mohammad',
				// 	last_name: 'Qasim',
				// 	email: `mohd.qasim+${nanoid()}@leanport.info`,
				// 	gender_id: 1,
				// 	role_id: 'developer',
				// 	language_id: 1,
				// }}
				
				index="1" getValidationStatus={(valid) => { SetContactFormIsValid(valid) }} />
				{children}
			</Form.Provider>
		</Modal>
	)
}

AddContactModal.propTypes = {
	children: PropTypes.node,
	show: PropTypes.bool.isRequired,
	handleClose: PropTypes.func.isRequired,
	customerId: PropTypes.number.isRequired,
	handleSave: PropTypes.func.isRequired,
}

const selectableContacts = ({ children, customerId, show, handleClose, exclude, onSelectionChanged, onContactsUpdate }) => {
	const [query, setQuery] = useState('')
	const [selectedId, setSelectedId] = useState()
	const [addNewVisible, SetAddNewVisible] = useState(false)
	const [contacts, setContacts] = useState([])

	useEffect(() => {
		if (onContactsUpdate) {
			onContactsUpdate(contacts)
		}
	}, [contacts])

	useEffect(() => {
		getContacts()
	}, [customerId])

	const getContacts = () => {
		return getCustomerDetails(customerId)
			.then(({ contacts }) => {
				setContacts(contacts)
			})
	}

	const filteredList = useMemo(() => (
		contacts.filter(({ id }) => !exclude.includes(id)).filter((contact) => (query.length === 0 || Object.values(contact).some((v) => {
			return (typeof v === 'string') && v.toLowerCase().includes(query.toLowerCase())
		})))
	), [contacts, exclude, query])

	const handleItemClick = (contact) => {
		setSelectedId(contact.id)
		if (onSelectionChanged) {
			onSelectionChanged(contact)
		}
	}

	const handleAddNewContact = async (data) => {
		const contact = { ...data, ['customer_id']: customerId }
		const { message: msg, status, data: newContact } = await createContact(contact)
		if (!status) {
			message.error(msg)
		}
		else {
			message.success(msg)
			SetAddNewVisible(false)
			getContacts()
				.then(() => {
					handleItemClick(newContact)
				})
		}
	}

	return (
		<Modal title="Add Contact" open={show} onCancel={handleClose ?? false} destroyOnClose footer={<Button onClick={() => SetAddNewVisible(true)}>Add New Contact</Button>} width={1000} >
			<div className={styles.wrapper}>
				<Input.Search placeholder="Search..." onChange={(e) => setQuery(e.target.value)} />
				<div className={styles.cards}>
				
					{filteredList.map((item) => renderItem(item, (item.id === selectedId), handleItemClick))}
				
				</div>
				{children}
			</div>
			{addNewVisible && <AddContactModal  customerId={customerId} show={addNewVisible} handleClose={() => { SetAddNewVisible(false) }} handleSave={handleAddNewContact} />}
		</Modal >
	)
}

selectableContacts.propTypes = {
	children: PropTypes.node,
	show: PropTypes.bool,
	customerId: PropTypes.number.isRequired,
	exclude: PropTypes.arrayOf(PropTypes.number),
	onSelectionChanged: PropTypes.func,
	handleClose: PropTypes.func,
	onContactsUpdate: PropTypes.func,
}

export default selectableContacts