import { PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Input } from 'antd';
import PropTypes from 'prop-types';
import { React } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { getAlContacts } from '../../api/contact';
import styles from '../../components/form/contact/contact.module.scss';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { ContactColumns } from '../../library/constants/tableColumns/contactColumns';

const search = {
  'width': '50%'
}
function Contacts(props) {
  const navigate = useNavigate()
  /* eslint-disable no-unused-vars */
  const [loading, error, contacts, hasNoMore, page, sort, filters, {
    nextPage,
    setSort,
    setFilters
  }] = useFetch({
    limit: 20,
    functionToFetchData: getAlContacts
  })
  /* eslint-enable no-unused-vars */

  const handleRowClick = (rowIndex, record) => {
    navigate(generatePath('/customers/contacts/:id', {
      id: record.id
    }))
  }

  return (
    <Page className='contacts-list' title={props.title}>
      <div className={styles.searchHeader}>
        <Button type="primary" onClick={() => { navigate('/customers/contacts/create') }} icon={<PlusOutlined />}>Add new Contact</Button>
        <div style={search} >


          <Input.Search placeholder="Search" prefix={<SearchOutlined />}

            onChange={(e) => setFilters({ 'search': e.target.value })


            }
          />
        </div>
        {/* <Button onClick={() => setFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button> */}
      </div>



      <Table
        columns={ContactColumns}
        data={contacts}
        isLoading={loading}
        onChange={(sort) => setSort(sort)}
        onRowClick={handleRowClick}
        hasMore={!hasNoMore}
        loadMore={nextPage}
      />
    </Page>
  )
}

Contacts.propTypes = {
  title: PropTypes.string
}

export default Contacts
