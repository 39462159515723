import React, { useRef, useState } from "react";
// import {Row,Col} from 'antd'
import styles from '../../../components/data/quotation/quotation-item.module.scss';
import PropTypes from 'prop-types'
import { Button, Form, Input, Modal, message } from 'antd';
import { useActionData, useParams } from 'react-router-dom';
import { uploadFileFromObject } from "../../../api/common";
import { confirmOrderDelivery } from "../../../api/order";
import { ActionError } from '../../../library/classes/exceptions';
import { Message, MessageTypes, MultifileUpload } from "../../../ui";
import style2 from '.././stepBody.module.scss';
const { TextArea } = Input







const ProductDispatch = ({ data }) => {
    const { quotationToken } = useParams()


    const actionData = useActionData()

    const [form] = Form.useForm();
    const fileUploaderRef = useRef([])
    const [open, setOpen] = useState(false);



    const handleFilesUpload = async (id) => {
        if (fileUploaderRef.current.length === 0) {
            alert('Please select atleast one file')
            return
        }

        const promises = fileUploaderRef.current.map(file => uploadFileFromObject(quotationToken, file))
        try {
            const fileIds = await Promise.all(promises)
            const jsonData = { image_ids: fileIds, comment: form.getFieldValue()['comment'] }
            const response = await confirmOrderDelivery(id, jsonData)
            const { message: msg } = response
            console.log(response);
            message.success(msg);
            setOpen(false);


        }
        catch (error) {
            throw new Response('Unable to upload files', { status: 500 })
        }

    }

    const handleOk = (id) => {
        console.log('uploading file');
        handleFilesUpload(id)
    };

    const handleCancel = () => {
        console.log('Clicked cancel button');
        setOpen(false);
    };

    return (
        <div>

            <div className={styles.stepBox}>
                <h3 style={{ 'text-align': 'start' }}>Task For You</h3>
                <div className={styles.message}>
                    <ul>
                        <li>The customer has shared the artwork. Please upload the file and send to vendor for comfirmation
                            <span style={{ "float": 'right' }}><Button onClick={() => { }}>Notify Customer</Button></span>
                        </li>

                    </ul>
                </div>
            </div>

            <div className={styles.stepBox}>

                <div>
                    <Form
                        form={form}
                        // onFinish={onFinish}
                        // onFinishFailed={onFinishFailed}
                        autoComplete="off"
                    >



                        <table style={{ 'width': '100%' }}>
                            <tbody>

                                <tr>
                                    <td style={{ 'padding': '16px' }}>Quantity</td>
                                    <td>Delivery Address</td>
                                    <td>Ship Quantity</td>
                                    <td>Expected Dispatch Date</td>
                                    <td>Tracking URL</td>
                                    <td>Expected Delivery Date</td>

                                    <td></td>



                                </tr>


                                {data.item_delivery_address && data.item_delivery_address.map((value, index) => {



                                    return (



                                        <tr className={styles.ship} key={index}>

                                            <td style={{ 'padding': '16px' }}>{value.quantity}
                                            </td>

                                            <td>{value.street_number},{value.street},{value.city}</td>
                                            <td>{value.ship_quantity}</td>






                                            <td>{value.dispatch_date}</td>

                                            <td>{value.tracking_url}</td>





                                            <td>{value.expected_date}</td>

                                            <td>
                                                <Button onClick={() => {
                                                    value.status == 4 ?
                                                        message.warning('Proof Already Sent') :
                                                        setOpen(true)


                                                    // handleConfirmDelivery(value.id, {})

                                                }} type="primary"> {value.status == 4 ? 'Proof Sent' : 'Confirm'}</Button>


                                            </td>
                                            <Modal
                                                title="Upload Proof of Delivery"
                                                open={open}
                                                onOk={() => {
                                                    handleOk(value.id)
                                                }}
                                                okText={'Continue'}
                                                onCancel={handleCancel}


                                            >

                                                <div className={styles.uploadBox}>


                                                    <p>The uploaded printscreen should not contain track and trace code, country of origin and sender information</p>
                                                    <div className="main">

                                                        <div className={`box--white ${style2.wrapper}`}>

                                                            <div className={style2.content}>
                                                                {!!actionData && actionData instanceof ActionError && <Message type={MessageTypes.ERROR} message={actionData.message} timeOut={3} />}
                                                                {actionData === true && <Message type={MessageTypes.SUCCESS} message="File Uploaded Successfully" timeOut={3} />}
                                                                <MultifileUpload ref={fileUploaderRef} />

                                                            </div>
                                                            <br />
                                                            {/* <div className="actions align--right">
                                                                 <Button className="button--primary" onClick={()=>{
                                                                    handleFilesUpload(value.id)
                                                                }}>Upload</Button> 
                                                            </div> */}
                                                            {/* {!!artworks && (
                                          <div className={style2.uploadedArtworks}>
                                              <h2>Upload History</h2>
                                              <div className={style2.artworksHistory}>
                                                  <ArtworksPreview key={artworks.id} id={artworks.id} documents={artworks.documents} date={artworks.createdAt} />
                                              </div>
                                          </div>
                                      )} */}
                                                        </div>
                                                    </div>
                                                    <br />

                                                    <Form.Item name='comment'>
                                                        <TextArea label="comment" />
                                                    </Form.Item>

                                                </div>

                                            </Modal>



                                        </tr>
















                                    )
                                }



                                )

                                }
                            </tbody>


                        </table>


                    </Form>



                </div>


            </div>

            <br />






        </div>




    )
}


ProductDispatch.propTypes = {
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    // logs: PropTypes.arrayOf(PropTypes.object).isRequired,
    // changeState: PropTypes.func
}
export default ProductDispatch;


