import { GlobalOutlined, MailOutlined, MobileOutlined } from '@ant-design/icons';
import { List } from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { Server } from '../../library/constants/server';
import './kanaban-view.scss';

const renderKanabanCard = ({ item, onClick }) => {
  const getSourceIcon = () => {
    switch (item.source_id) {
      case 'email':
        return <MailOutlined />
      case 'website':
        return <GlobalOutlined color="#6e589d" />
      case 'phone':
        return <MobileOutlined />
    }
  }

  return (
    <List.Item className='kanaban-card' onClick={() => onClick(item)}>
      <div className="kanaban-card--header">
        <span className="project-number">{String(item.id).padStart(6, '0')}</span>
        <span className="project-source">{getSourceIcon()}</span>
        <span className="project-country">
          <img src={`${Server.BaseUrl}/${item.customer.country.flag_url}`} alt={item.customer.country.name} title={item.customer.country.name} />
        </span>
      </div>
      <div className="kanaban-card--body">
        <span className="customer-logo">
          {/* <img src={`https://www.google.com/s2/favicons?sz=64&domain_url=${item.customer.website}`} alt="" /> */}
        </span>
        <span className="customer-name">{item.customer.company}</span>
      </div>
      <div className="kanaban-card--footer">
        <span className="account-manager">Account Manager: John Doe (dummy)</span>
        <span className="account-manager">€ 5.678 (dummy)</span>
      </div>
    </List.Item>
  );
};

const KanabanColumn = ({ list, title, onItemClick }) => {
  return (
    <div className='kanaban-column'>
      <h3 className='kanaban-colum-inside'>{title}</h3>
      <List
        className="kanaban-column--list"
        //loading={initLoading}
        itemLayout="horizontal"
        //loadMore={loadMore}
        dataSource={list}
        renderItem={(item) => renderKanabanCard({ item, onClick: onItemClick })} />
    </div>
  );
};
KanabanColumn.propTypes = {
  title: PropTypes.string.isRequired,
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

const KanabanView = ({ data, columns, onItemClick }) => {
  return (
    <div className='kanaban-wrapper'>
      {columns.map(c => <KanabanColumn key={c.key} title={c.title} list={data.filter(({ status }) => status === c.key) ?? []} onItemClick={onItemClick} />)}
    </div>
  );
};
KanabanView.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: PropTypes.arrayOf(PropTypes.object).isRequired,
  onItemClick: PropTypes.func.isRequired,
};

export default KanabanView