// Each file here is tied to the routes defined in the routes folder.
// All API calls originating from that page can be put here

/* eslint-disable no-undef */
import { generatePath } from 'react-router-dom';
import { axiosInstance } from '../common';

// Define all api endpoint urls at the top
const ENDPOINT_GENERAL = '/vendor';
const ENDPOINT_SINGLE = '/vendor/:id';
// const ENDPOINT_SINGLE_ACTION = '/project/:id/:action';

export const getAllVendors = (payload) => {
    console.log(axiosInstance.get(ENDPOINT_GENERAL, { params: payload }));
    return axiosInstance.get(ENDPOINT_GENERAL, { params: payload })
}

export const createVendor = (payload) => {
    return axiosInstance.post(ENDPOINT_GENERAL, payload)
}

export const getVendorDetails = (id) => {
    return axiosInstance.get(generatePath(ENDPOINT_SINGLE, {
        id
    })).then(({ data }) => data)
}

export const updateVendorDetails = (id,payload) => {
    return axiosInstance.patch(generatePath(ENDPOINT_SINGLE, {
        id
        
    }) , payload)
}
