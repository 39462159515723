import {
	Input,
	Select,
} from 'antd';
import PropTypes from 'prop-types';
import { React } from 'react';
import { ValidationForm as Form } from '../';
import Genders from '../../../library/dynamics/genders';
import Roles from '../../../library/dynamics/roles';
import { getCountryById } from '../../../library/helpers';
import { CompanySelect, LanguageSelect, PhoneInput } from '../../inputs';
import styles from './contact.module.scss';
const Contact = ({ children, onSubmit, ...otherProps }) => {
	const [form] = Form.useForm()
	

	const validationRules = {
		first_name: [
			{
				required: true,
			}
		],
		last_name: [
			{
				required: true,
			}
		],
		customer_id: [
			{
				required: true
			}
		],
		email: [
			{
				required: true
			},
			{
				type: 'email'
			}
		],
		role_id: [
			{
				required: true
			}
		],
		gender_id: [
			{
				required: true
			}
		],
		language_id: [
			{
				required: true
			}
		],
	}

	return (
		<Form onFinish={onSubmit} form={form} name="contact" layout="vertical" validationRules={validationRules} className={styles.FormWrapper} {...otherProps}>
			<Form.Item name="first_name" label="First Name" >
				<Input />
			</Form.Item>
			<Form.Item name="last_name" label="Last Name" >
				<Input />
			</Form.Item>
			<Form.Item name='customer_id' label="Company" >
				<CompanySelect onChange={(val,company) => {
						console.log(val);
						console.log(company);

						console.log(getCountryById(val).phone_number_code);

					form.setFieldsValue({
						language_id: val,
						phone_country_code:getCountryById(val).phone_number_code,
						// currency_id: country.currency_id,
						// contact_country_code: country.phone_number_code,
						//  entity_id: country?.entity?.id,
					})
				}}/>
			</Form.Item>
			<Form.Item name="email" label="Email" >
				<Input type="email" />
			</Form.Item>
			<Form.Item name='role_id' label="Department" >
				<Select options={Roles} fieldNames={{ value: 'id', label: 'name' }} />
			</Form.Item>
			<Form.Item label="Phone No.">
				<PhoneInput valuePropName={{ code: 'phone_country_code', number: 'phone_number' }} />
			</Form.Item>
			<Form.Item label="Mobile No.">
				<PhoneInput valuePropName={{ code: 'mobile_country_code', number: 'mobile_number' }} />
			</Form.Item>
			<Form.Item name='gender_id' label="Gender" >
				<Select options={Genders} fieldNames={{ value: 'id', label: 'name' }} />
			</Form.Item>
			<Form.Item name='language_id' label="Language" >
				<LanguageSelect />
			</Form.Item>
			{children}
		</Form>
	)
}

Contact.propTypes = {
	children: PropTypes.node,
	// initialValues: PropTypes.object,
	onSubmit: PropTypes.func,
}

export default Contact