import React from 'react';
import PropTypes from 'prop-types';

const Image = ({ src, alt, ...otherProps }) => {
    if (!src) {
        return <img src="/images/placeholder.svg" alt={alt} {...otherProps} />
    }

    return <img src={src} alt={alt} onError={(e) => {
        e.target.src = "/images/placeholder.svg"
    }} {...otherProps} />
}

Image.propTypes = {
    src: PropTypes.string.isRequired,
    alt: PropTypes.string
}

export default Image