/* eslint-disable no-unused-vars */
import { FilterOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons';
import { Button, Drawer, Input } from 'antd';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { getAlCustomers , searchCustomers} from '../../api/customer';
import CustomerFilterForm from '../../components/form/customer-filter/customer-filter.jsx';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { useFetch } from '../../hooks';
import { CustomerColumns } from '../../library/constants/tableColumns/customerColumns';

function Customers(props) {

  //const [isLoaded, setIsLoaded] = useState(false)

  const search = {
    'width': '50%'
  }
  const navigate = useNavigate()

  const [value, setValue] = useState('');
  const [filtersVisible, setFiltersVisible] = useState(false)
  const [loading, error, list, hasNoMore, page, sort, filters, {
    nextPage,
    setSort,
    setFilters
  },searchQuery] = useFetch({
    // page: 1,
    limit: 20,
   // filters: {},
    functionToFetchData: getAlCustomers,
   //  functionToSearchData : searchCustomers
  })

  const [Fdata, query] = useState()

  // useEffect(()=>{
  //   searchCustomers()
  // })
  
  const loadData = (sort) => {
    setSort(sort)
  }

  const handleRowClick = (rowIndex, record) => {
    navigate(generatePath('/customers/:id', {
      id: record.id
    }))
  }

  return (
    <Page className='customers-list' title={props.title} header={
      <>
        <Button type="primary" onClick={() => { navigate('/customers/create') }} icon={<PlusOutlined />}>Add new Company</Button>
        <div style={search} >


          <Input.Search placeholder="Search" prefix={<SearchOutlined />} 
          
          onChange={(e) => setFilters({'search':e.target.value})
            
          
          }
          />
        </div>
        <Button onClick={() => setFiltersVisible(v => !v)} icon={<FilterOutlined />}>Filters</Button>
      </>
    }>
      <Drawer open={filtersVisible} onClose={() => setFiltersVisible(false)} >
        <CustomerFilterForm data={filters} onFinish={(data) => setFilters(data)} />
      </Drawer>
      <Table
        columns={CustomerColumns}
        data={ list}
        /* perPage={Number(meta?.limit)} currentPage={Number(meta?.page)} total={Number(meta?.totalCount)} */
        isLoading={loading}
        onChange={loadData}
        onRowClick={handleRowClick}
        hasMore={!hasNoMore}
        loadMore={nextPage}
      />
    </Page>
  )
}

Customers.propTypes = {
  title: PropTypes.string
}

export default Customers
