import { CheckOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons';
import { Button, Collapse, Form, message } from 'antd';
import { customAlphabet } from 'nanoid/non-secure';
import PropTypes from 'prop-types';
import React, { useReducer, useRef } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { createVendor } from '../../api/vendor/vendor';
import { SmallSection } from '../../components/design';
import { VendorAddress, VendorContact, VendorDetails } from '../../components/form';
import { Page } from '../../components/page';
import { getCountryById } from '../../library/helpers';
const { Panel } = Collapse
const nanoid = customAlphabet('1234567890abcdef', 6)
const ACTIONS = {
	ADD: 'add',
	REMOVE: 'remove',
	UPDATE: 'update',
}

const reducer = (state, action) => {
	switch (action.type) {
		case ACTIONS.ADD:
			return { ...state, [nanoid()]: false }

		case ACTIONS.REMOVE: {
			const tempState = { ...state }
			delete tempState[action.id]
			return { ...tempState }
		}

		case ACTIONS.UPDATE:
			return { ...state, [action.id]: action.value }

		default:
			alert('Invalid operation')
			return state
	}
}

const VendorCreate = ({ title }) => {

	const formData = useRef({
		vendorDetails: {},
		address: {},
		contacts: {}
	})

	const navigate = useNavigate()


	const [contactForms, dispatchContactForm] = useReducer(reducer, {})


	const handleFormSubmit = async () => {
		//console.log(...formData.current.vendorDetails);
		//Check if form validated
		// if (!isFormValid())
		// 	return
		console.log(Object.keys(formData.current.address) == 0);


		const details =


		{
			...formData.current.vendorDetails,
			addresses: Object.keys(formData.current.address).length == 0 ? [] : formData.current.address,
			contacts: Object.values(formData.current.contacts)
		}

		console.log(details);

		try {
			const { message: msg } = await createVendor(details)
			message.success(msg)
			navigate(generatePath('/vendors'))
		}
		catch (e) {
			console.log(e.errors[0].message);
			message.error(e.errors[0].message)
			throw e
		}
	}
	const handleFormsChange = async (name, { forms }) => {
		if (name === 'vendorDetails') {
			formData.current[name] = forms[name].getFieldsValue(true)
			return
		}
		else if (name === 'address') {
			formData.current[name] = forms[name].getFieldsValue(true)
			return
		}


		const [type, id] = name.split('-')
		if (!id) {
			return
		}
		switch (type) {
			case 'address':
				//formData.current.addresses = mutateArrayItem(formData.current.addresses, id, forms[name].getFieldsValue(true))
				formData.current.address[id] = forms[name].getFieldsValue(true)
				break
			case 'contact':
				//formData.current.contacts = mutateArrayItem(formData.current.contacts, id, forms[name].getFieldsValue(true))
				formData.current.contacts[id] = forms[name].getFieldsValue(true)
				break
		}
	}


	// const handleCustomerChange = async (customer) => {
	// 	if (!customer?.primary_contact_id || !customer?.contacts) {
	// 		setCustomerContact(null)
	// 		return
	// 	}

	// 	const primaryContact = customer?.contacts.find(({ id }) => id === customer.primary_contact_id)
	// 	if (!primaryContact) {
	// 		setCustomerContact(null)
	// 		return
	// 	}

	// 	setCustomerContact(primaryContact)
	// 	vendorCreate.current.data.primary_contact_id = primaryContact.id
	// }

	const handleVendorAddressChange = async (_, values) => {

		console.log(values);

	}

	const handleVendorDetailsChange = async (_, values) => {
		console.log(values);

	}

	return (
		<Page className='vendor-create' title={title} header={<Button type="primary" onClick={() => { navigate(-1) }}>Back</Button>}>
			<div style={{
				display: 'flex',
				flexDirection: 'column',
				alignTtems: 'stretch',
			}} >
				<Form.Provider onFormChange={handleFormsChange}>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Vendor Information' key="1">
							<VendorDetails
								getValidationStatus={(isValid) => { console.log(isValid) }}

								onValuesChange={handleVendorDetailsChange}
							/>
						</Panel>
					</Collapse>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Addresses' key="1">

							<VendorAddress
								getValidationStatus={(isValid) => { console.log(isValid) }}

								onValuesChange={handleVendorAddressChange}
							/>
						</Panel>
					</Collapse>
					<Collapse collapsible="icon" defaultActiveKey={['1']} expandIconPosition="end">
						<Panel header='Contacts' key="1">
							{Object.keys(contactForms).map((id) => (
								<SmallSection key={id} Vendor

								>
									<VendorContact index={id} initialValues={{
										language_id: formData.current['VendorDetails']?.language_id,
										phone_country_code: getCountryById(formData.current['VendorDetails']?.country_id)?.phone_number_code,
										mobile_country_code: getCountryById(formData.current['VendorDetails']?.country_id)?.phone_number_code,
									}} getValidationStatus={(isValid) => { dispatchContactForm({ type: ACTIONS.UPDATE, id, value: isValid }) }}>
										<Form.Item>
											<Button className='action action--delete' onClick={() => dispatchContactForm({ type: ACTIONS.REMOVE, id })}><DeleteOutlined /></Button>
										</Form.Item>
									</VendorContact>
								</SmallSection>
							))}
							<Button onClick={() => { dispatchContactForm({ type: ACTIONS.ADD }) }}>Add New Contact</Button>
						</Panel>
					</Collapse>
					<div className="actions" style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
						<Button onClick={handleFormSubmit}
							//   disabled={!isFormValid()}
							type="primary" icon={<CheckOutlined />}>Create</Button>
						<Button onClick={() => {
							if (confirm("Unsaved changes will be lost") == true) {
								navigate(-1)
							}
						}} type="danger" icon={<CloseOutlined />} >Cancel</Button>
					</div>
				</Form.Provider>
			</div>
		</Page>
	)
}

VendorCreate.propTypes = {
	title: PropTypes.string
}

export default VendorCreate