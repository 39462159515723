import PropTypes from 'prop-types';
import React from 'react';
import { Page } from '../../components/page';
import { Table } from '../../components/table';
import { UserColumns } from '../../library/constants/tableColumns';

function Users(props) {
  const { title } = props;
  return (
    <Page className='users' title={title}>
      <Table
        columns={UserColumns}
        // data={list}
        /* perPage={Number(meta?.limit)} currentPage={Number(meta?.page)} total={Number(meta?.totalCount)} */
        /* isLoading={loading}
        onChange={loadData}
        onRowClick={handleRowClick}
        hasMore={!hasNoMore}
        loadMore={nextPage} */
      />
    </Page>
  )
}

Users.propTypes = {
  title: PropTypes.string,
}

export default Users
